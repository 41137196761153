import React from 'react';
import { Route, Switch, Redirect } from "react-router-dom";

import documentComponent from '../hoc/documentComponent/documentComponent';
import HomePage from "../pages/HomePage";
import UsersPage from "../pages/UsersPage";
import UserCreatePage from "../pages/UserCreatePage";
import UserEditPage from "../pages/UserEditPage";
import SubjectsPage from "../pages/SubjectsPage";
import SubjectPage from "../pages/SubjectPage";
import SubjectCreatePage from "../pages/SubjectCreatePage";
import SubjectEditPage from "../pages/SubjectEditPage";
import ActiveDocuments from "../pages/ActiveDocumentsPage";
import ActiveDocument from "../pages/ActiveDocumentPage";
import ActiveDocumentUploadPage from "../pages/ActiveDocumentUploadPage";
import PassiveDocuments from "../pages/PassiveDocumentsPage";
import PassiveDocument from "../pages/PassiveDocumentPage";
import UploadSignedDocumentPage from "../pages/UploadSignedDocumentPage";
import SubjectDownloadPage from "../pages/SubjectDownloadPage";
import AccountantsPage from "../pages/AccountantsPage";
import AccountantCreatePage from "../pages/AccountantCreatePage";
import AccountantEditPage from "../pages/AccountantEditPage";
import AccountantPage from "../pages/AccountantPage";
import AcceptAccountantPage from "../pages/AcceptAccountantPage";
import FaqPage from "../pages/FaqPage";
import LogsPage from "../pages/LogsPage";
import HelpPage from '../pages/HelpPage';
import ProfilePage from '../pages/ProfilePage';
import LogoutPage from '../pages/LogoutPage';
import ChangePasswordPage from '../pages/ChangePasswordPage';
import FollowSubjectPage from '../pages/FollowSubjectPage';
import SettingsPage from '../pages/SettingsPage';

import Role from "../shared/enums/Role";

const ActiveDocumentsPage = documentComponent(ActiveDocuments);
const ActiveDocumentPage = documentComponent(ActiveDocument);
const PassiveDocumentsPage = documentComponent(PassiveDocuments);
const PassiveDocumentPage = documentComponent(PassiveDocument);


const resolveRoute = (props) => {
    const role = props.role;
    let routes = null;
    const faq = <Route exact path="/faq" component={FaqPage} />;
    const help = <Route exact path="/help" component={HelpPage} />;
    const profile = <Route exact path="/profile" component={ProfilePage} />;
    const changePwd = <Route exact path="/change-password" component={ChangePasswordPage} />;
    const settings = <Route exact path="/settings" component={SettingsPage} />;
    const logout = <Route exact path="/logout" component={LogoutPage} />;
    const accountantConfirm = <Route exact path="/confirm-accountant" component={AcceptAccountantPage} />
    switch (role) {
        case Role.SUPERADMIN:
            routes = (
                <Switch>
                    <Route exact path="/" component={HomePage} />
                    <Route exact path="/users" component={UsersPage} />
                    <Route exact path="/users/create" component={UserCreatePage} />
                    <Route exact path="/users/:id/edit" component={UserEditPage} />
                    <Route exact path="/subjects" component={SubjectsPage} />
                    <Route exact path="/subjects/create" component={SubjectCreatePage} />
                    <Route exact path="/subjects/:id/edit" component={SubjectEditPage} />
                    <Route exact path="/subjects/:id" component={SubjectPage} />
                    <Route exact path="/accountants" component={AccountantsPage} />
                    <Route exact path="/accountants/create" component={AccountantCreatePage} />
                    <Route exact path="/accountants/:id" component={AccountantPage} />
                    <Route exact path="/accountants/:id/edit" component={AccountantEditPage} />
                    <Route exact path="/documents/active" component={ActiveDocumentsPage} />
                    <Route exact path="/documents/active/:id" component={ActiveDocumentPage} />
                    <Route exact path="/documents/active/:id/signed" component={UploadSignedDocumentPage} />
                    <Route exact path="/documents/passive" component={PassiveDocumentsPage} />
                    <Route exact path="/documents/passive/:id" component={PassiveDocumentPage} />
                    <Route exact path="/logs" component={LogsPage} />
                    {faq}
                    {profile}
                    {changePwd}
                    {logout}
                    {accountantConfirm}
                    <Redirect to="/" />
                </Switch>
            );
            break;
        case Role.ADMIN:
            routes = (
                <Switch>
                    <Route exact path="/" component={HomePage} />
                    <Route exact path="/users" component={UsersPage} />
                    <Route exact path="/users/create" component={UserCreatePage} />
                    <Route exact path="/users/:id/edit" component={UserEditPage} />
                    <Route exact path="/subjects" component={SubjectsPage} />
                    <Route exact path="/subjects/create" component={SubjectCreatePage} />
                    <Route exact path="/subjects/:id/edit" component={SubjectEditPage} />
                    <Route exact path="/subjects/:id" component={SubjectPage} />
                    <Route exact path="/accountants" component={AccountantsPage} />
                    <Route exact path="/accountants/create" component={AccountantCreatePage} />
                    <Route exact path="/accountants/:id" component={AccountantPage} />
                    <Route exact path="/accountants/:id/edit" component={AccountantEditPage} />
                    <Route exact path="/documents/active" component={ActiveDocumentsPage} />
                    <Route exact path="/documents/active/:id" component={ActiveDocumentPage} />
                    <Route exact path="/documents/active/:id/signed" component={UploadSignedDocumentPage} />
                    <Route exact path="/documents/passive" component={PassiveDocumentsPage} />
                    <Route exact path="/documents/passive/:id" component={PassiveDocumentPage} />
                    <Route exact path="/logs" component={LogsPage} />
                    {faq}
                    {profile}
                    {changePwd}
                    {logout}
                    {accountantConfirm}
                    <Redirect to="/" />
                </Switch>
            );
            break;
        case Role.USER:
            routes = (<Switch>
                <Route exact path="/" component={HomePage} />
                <Route exact path="/documents/active" component={ActiveDocumentsPage} />
                <Route exact path="/documents/active/upload" component={ActiveDocumentUploadPage} />
                <Route exact path="/documents/active/:id" component={ActiveDocumentPage} />
                <Route exact path="/documents/passive" component={PassiveDocumentsPage} />
                <Route exact path="/documents/passive/:id" component={PassiveDocumentPage} />
                {accountantConfirm}
                {faq}
                {help}
                {profile}
                {changePwd}
                {settings}
                {logout}
                <Redirect to="/" />
            </Switch>);
            break;
        case Role.COMMERCIALISTA:
            routes = (<Switch>
                <Route exact path="/" component={HomePage} />
                <Route exact path="/subjects" component={SubjectsPage} />
                <Route exact path="/subjects/following-request" component={FollowSubjectPage} />
                <Route exact path="/subjects/:id" component={SubjectPage} />
                <Route exact path="/subjects/:id/download" component={SubjectDownloadPage} />
                <Route exact path="/documents/active" component={ActiveDocumentsPage} />
                <Route exact path="/documents/active/upload" component={ActiveDocumentUploadPage} />
                <Route exact path="/documents/active/:id" component={ActiveDocumentPage} />
                <Route exact path="/documents/passive" component={PassiveDocumentsPage} />
                <Route exact path="/documents/passive/:id" component={PassiveDocumentPage} />
                {faq}
                {help}
                {profile}
                {changePwd}
                {settings}
                {logout}
                {accountantConfirm}
                <Redirect to="/" />
            </Switch>);
            break;
        default:
            break;
    }
    return routes;
};

export default resolveRoute;