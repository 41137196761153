import React, { Component } from 'react';
import {
    Dimmer,
    Page,
    Container,
    Header,
    Grid
} from "tabler-react";

import queryString from 'query-string'

import api from '../api'
import ApiErrorCode from '../shared/enums/ApiErrorCode';

class AcceptAccountantPage extends Component {
    state = {
        loading: true,
        error: false,
        errorText: "Non è stato possibile confermare il commercialista."
    }

    componentDidMount() {
        const values = queryString.parse(this.props.location.search)
        api.put('subjects/confirm-accountant/' + values.token, null)
            .then(response => {
                this.setState({
                    ...this.state,
                    loading: false,
                    error: false
                });
            })
            .catch(errors => {
                this.setState({
                    ...this.state,
                    loading: false,
                    error: true
                });
                if (errors.response) {
                    if (errors.response.status === 409) {
                        if (errors.response.data.code === ApiErrorCode.FOLLOW_REQUEST_ALREADY_ACCEPTED) {
                            this.setState({
                            ...this.state,
                                errorText: errors.response.data.title
                        });
                        }
                    }
                }
            });
    }

    render() {
        let content = <div>
            <Header.H2 className="display-1 text-muted mb-5">Errore</Header.H2>
            <Header.H4 className="text-muted font-weight-normal mb-7">
                {this.state.errorText}
            </Header.H4>
        </div>;
        if (!this.state.loading && !this.state.error) {
            content = (
                <div>
                    <Header.H2 className="display-1 text-muted mb-5">Commercialista confermato</Header.H2>
                    <Header.H4 className="text-muted font-weight-normal mb-7">
                        Da questo momento il tuo commercialista potrà accedere ai tuoi dati.
                    </Header.H4>
                </div>
            );
        }
        return (
            <Page className="text-center">
                <Container>
                    <Page.Content>
                        <Grid.Row>
                            <Grid.Col className="text-center">
                                <img src="/assets/images/fattura-fatta.png" alt="Fattura Fatta" style={styles.logo} />
                                <Dimmer active={this.state.loading ? true : false} loader>{content}</Dimmer>
                            </Grid.Col>
                        </Grid.Row>
                    </Page.Content>
                </Container>
            </Page>
        );
    }
}

export default AcceptAccountantPage;

const styles = {
    logo: {
        paddingTop: '4em',
        paddingBottom: '4em'
    }
}