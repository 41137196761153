import React from 'react';

import { Text, Progress } from 'tabler-react';
import * as moment from 'moment';

const resourceRemaining = (props) => {
    const date = moment(props.from, "DD/MM/YYYY hh:mm:ss");
    const today = moment();
    const diff = today.diff(date, 'days');

    let color = "info";
    let content = props.amount + " al  " + date.add(1, 'year').format("DD/MM/YYYY");
    let perc = 0;
    if (props.amount === -1) {
        color = "green";
        content = '\u221e';
        perc = 100;
    } else if (props.amount === 0) {
        color = "red";
        content = "0";
    } else if (props.amount < 10 || 365 - diff < 10) {
        color = "yellow";
    }
    if (props.total > 0) {
        perc =((props.amount / props.total) * 100).toFixed(2);
    }
    if (props.total === 0 && props.amount !== -1) {
        return (
            <div>
                -
            </div>
        );
    }
    return (
        <div>
            <div className="clearfix">
                <div className="float-left">
                    <strong>{perc}%</strong>
                </div>
                <div className="float-right">
                    <Text.Small muted>
                        { content }
                    </Text.Small>
                </div>
            </div>
            <Progress size="xs">
                <Progress.Bar color={color} width={perc} />
            </Progress>
        </div>
    );
}

export default resourceRemaining;