import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Page,
    Dimmer,
    Grid,
    Card,
    Icon,
    Text,
    Header
} from 'tabler-react';

import SiteWrapper from "../hoc/SiteWrapper/SiteWrapper";

class HelpPage extends Component {

    state = {
    }

    render() {
        if (this.props.isAuthenticated) {
            return (
                <SiteWrapper me={this.props.me} business={this.props.business}>
                    <Page.Content title="Contattaci">
                        <Grid.Row>
                            <Grid.Col offset={1} sm={4}>
                                <Card>
                                    <Card.Header>
                                        <Card.Title>Chiamaci</Card.Title>
                                    </Card.Header>
                                    <Card.Body className="text-center">
                                        <Text color="blue" style={styles.icon}><Icon name="phone"/></Text>
                                        <Header.H4>+39 091 625 9205</Header.H4>
                                    </Card.Body>
                                </Card>
                            </Grid.Col>
                            <Grid.Col offset={1} sm={4}>
                                <Card>
                                    <Card.Header>
                                        <Card.Title>Scrivici</Card.Title>
                                    </Card.Header>
                                    <Card.Body className="text-center">
                                        <Text color="blue" style={styles.icon}><Icon name="mail" /></Text>
                                        <Header.H4>support@fatturafatta.it</Header.H4>
                                    </Card.Body>
                                </Card>
                            </Grid.Col>
                        </Grid.Row>
                    </Page.Content>
                </SiteWrapper>
            );
        }
        return (<SiteWrapper me={this.props.me} business={this.props.business}>
            <Dimmer loader></Dimmer>
        </SiteWrapper>);
    };
}

const mapStateToProps = state => {
    return {
        status: state.documentStatus.status,
        me: state.auth.me,
        business: state.auth.business,
        isAuthenticated: state.auth.token !== null
    };
};

const styles = {
    icon: {
        fontSize: 50,
    }
}
export default connect(mapStateToProps, null)(HelpPage);