import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    documents: null,
    current: null,
    meta: null,
    links: null,
    error: false,
    loading: true,
    loadingSingle: true,
    empty: true,
    perPage: null,
    page: null,
    filters: null,
    include: null,
    order: null
};

const loadingStart = (state, action) => {
    return updateObject(state, { error: false, loading: true, empty: true });
};

const setDocuments = (state, action) => {
    return updateObject(state, {
        documents: action.documents.data,
        meta: action.documents.meta,
        links: action.documents.links,
        error: false,
        loading: false,
        empty: false
    });
};

const setDocument = (state, action) => {
    return updateObject(state, {
        document: action.document,
        error: false,
        loadingSingle: false
    });
};

const fetchDocumentsFailed = (state, action) => {
    return updateObject(state, { error: true, loading: false });
};

const fetchDocumentFailed = (state, action) => {
    return updateObject(state, { error: true, loadingSingle: false });
};

const fetchDocumentsEmpty = (state, action) => {
    return updateObject(state, { error: false, loading: false, empty: true, documents: null });
};

const setDocumentsTableStatus = (state, action) => {
    return updateObject(state, {
        page: action.page,
        perPage: action.perPage,
        filters: action.filters,
        include: action.include,
        order: action.order
    });
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOADING_START: return loadingStart(state, action);
        case actionTypes.SET_ACTIVE_DOCUMENTS: return setDocuments(state, action);
        case actionTypes.FETCH_ACTIVE_DOCUMENTS_FAILED: return fetchDocumentsFailed(state, action);
        case actionTypes.FETCH_ACTIVE_DOCUMENTS_EMPTY: return fetchDocumentsEmpty(state, action);
        case actionTypes.SET_ACTIVE_DOCUMENT: return setDocument(state, action);
        case actionTypes.FETCH_ACTIVE_DOCUMENT_FAILED: return fetchDocumentFailed(state, action);
        case actionTypes.SET_ACTIVE_DOCUMENTS_TABLE_STATUS: return setDocumentsTableStatus(state, action);
        default: return state;
    }
};

export default reducer;