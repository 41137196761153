import React from 'react';

import { Button } from 'tabler-react';

const pagination = (props) => {
    let firstUrl = new URL(props.links.first);
    const firstPage = firstUrl.searchParams.get("page");
    let lastUrl = new URL(props.links.last);
    const lastPage = lastUrl.searchParams.get("page");
    let prevPage = null;
    if (props.links.prev !== null) {
        let prevUrl = new URL(props.links.prev);
        prevPage = prevUrl.searchParams.get("page");
    }
    let nextPage = null;
    if (props.links.next !== null) {
        let nextUrl = new URL(props.links.next);
        nextPage = nextUrl.searchParams.get("page");
    }
    return (
        <div style={style.container}>
            <Button.List>
                <Button outline color="primary"
                        style={style.button}
                        disabled={props.links.prev === null}
                        onClick={() => props.onPageChange(firstPage)}>
                    Inizio
                </Button>
                <Button outline color="primary"
                        style={style.button}
                        disabled={props.links.prev === null}
                        onClick={() => props.onPageChange(prevPage)}>
                    Precedente
                </Button>
                <Button outline color="primary" style={style.button}
                        disabled={props.links.next === null}
                        onClick={() => props.onPageChange(nextPage)}>
                    Successiva
                </Button>
                <Button outline color="primary" style={style.button}
                        disabled={props.links.next === null}
                        onClick={() => props.onPageChange(lastPage)}>
                    Fine
                </Button>
            </Button.List>
        </div>
    );
}

const style = {
    container: {
        float: "right"
    },
    button: {
        marginRight: 0
    }
}

export default pagination;