import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Page,
    Table,
    Grid,
    Card,
    Button,
} from 'tabler-react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import api from '../api';
import SiteWrapper from "../hoc/SiteWrapper/SiteWrapper";
import * as actions from '../store/actions/index';
import Pagination from '../components/UI/Table/Pagination';
import TableMeta from '../components/UI/Table/TableMeta';
import PageLength from '../components/UI/Table/PageLength';
import ColHeader from '../components/UI/Table/ColHeader';
import Filter from '../components/Filter/Accountant';
import Ability from '../shared/enums/Ability';
import ResourceRemaining from '../components/UI/ResourceRemaining';
import { createOrderObject } from '../shared/utility';

class AccountantsPage extends Component {
    state = {
        perPage: 15,
        page: 1,
        filters: {},
        include: ['resource'],
        toEdit: false,
        order: {
            by: 'business_name',
            direction: 'ASC',
        }
    }

    componentDidMount() {
        if (this.props.page !== null) {
            this.props.onLoadAccountants(this.props.page, this.props.perPage, this.props.filters, this.props.include, this.props.order);
        } else {
            this.props.onLoadAccountants(this.state.page, this.state.perPage, this.state.filters, this.state.include, this.state.order);
        }
    }

    handlePageChange(page) {
        this.setState({
            ...this.state,
            page: page
        })
        this.props.onLoadAccountants(page, this.props.perPage, this.props.filters, this.props.include, this.props.order);
    }

    handlePerPageChange(perPage) {
        this.setState({
            ...this.state,
            perPage: perPage
        })
        this.props.onLoadAccountants(this.props.page, perPage, this.props.filters, this.props.include, this.props.order);
    }

    handleFilterSubmit(values) {
        this.setState({
            ...this.state,
            filters: values
        })
        this.props.onLoadAccountants(1, this.props.perPage, values, this.props.include, this.props.order);
    }

    handleDeleteClick(id) {
        api.delete('accountants/' + id).then(response => {
            toast.success("commercialista cancellato!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,

            });
            this.props.onLoadAccountants(this.props.page, this.props.perPage, this.props.filters, this.props.include, this.props.order);
        })
            .catch(error => {
                toast.error("Errore cancellazione!");
            });
    }

    handleOrder(column) {
        const order = createOrderObject(this.state.order, column);
        this.setState({
            ...this.state,
            order: order
        });
        this.props.onLoadAccountants(this.props.page, this.props.perPage, this.props.filters, this.props.include, order);
    }

    render() {
        let add = null;
        let footer = null;
        let resourceHeader = null;
        let tableHeader = null;
        let rows = (
            <Table.Row>
                <Table.Col colSpan={8}>Nessun dato presente</Table.Col>
            </Table.Row>
        );
        if (this.props.me.abilities.indexOf(Ability.CREATE_ACCOUNTANT) >= 0 || this.props.me.abilities.indexOf(Ability.ALL) >= 0) {
            add = (<Link to="/accountants/create"><Button color="primary">
                Nuovo Commercialista
                        </Button></Link>);
        }
        if (this.props.loading === false && this.props.error === false && this.props.empty === false) {
            let resource = null;
            rows = this.props.accountants.map(accountant => {
                let actions = [];
                const edit = (
                    <Link key={"edit_" + accountant.id} to={"/accountants/" + accountant.id + "/edit"}>
                        <Button color="secondary" icon="edit" outline />
                    </Link>
                );
                const del = (<Button key={"del_" + accountant.id} color="danger" icon="user-minus" outline onClick={() => this.handleDeleteClick(accountant.id)} />);
                const info = (
                    <Link key={"info_" + accountant.id} to={"/accountants/" + accountant.id}>
                        <Button key={"info_" + accountant.id} color="primary" icon="info" outline />
                    </Link>
                );
                actions.push(info);
                if (this.props.me.abilities.indexOf(Ability.EDIT_ACCOUNTANT) >= 0) {
                    actions.push(edit);
                }
                if (this.props.me.abilities.indexOf(Ability.DELETE_ACCOUNTANT) >= 0) {
                    actions.push(del);
                }
                if (this.props.me.abilities.indexOf(Ability.ALL) >= 0) {
                    actions.push(edit);
                    actions.push(del);
                }

                if (accountant.relationships.resources) {
                    resource = (<Table.Col>
                        <ResourceRemaining
                            amount={accountant.relationships.resources.data.attributes.remaining}
                            total={accountant.relationships.resources.data.attributes.total}
                            from={accountant.relationships.resources.data.attributes.last_purchase_date}>
                            {accountant.relationships.resources.data.attributes.remaining}
                        </ResourceRemaining>
                    </Table.Col>);
                }

                return <Table.Row key={accountant.id}>
                    <Table.Col>{accountant.attributes.business_name}</Table.Col>
                    <Table.Col>{accountant.attributes.vat_number}</Table.Col>
                    <Table.Col>{accountant.attributes.email}</Table.Col>
                    <Table.Col>{accountant.attributes.phone}</Table.Col>
                    {resource}
                    <Table.Col>{accountant.attributes.created_at}</Table.Col>
                    <Table.Col>{accountant.attributes.updated_at}</Table.Col>
                    <Table.Col><Button.List>{actions}</Button.List></Table.Col>
                </Table.Row>;
            });
            footer = (
                <div>
                    <TableMeta
                        from={this.props.meta.from}
                        to={this.props.meta.to}
                        total={this.props.meta.total}
                    />
                    <Pagination links={this.props.links} onPageChange={this.handlePageChange.bind(this)} />
                </div>);
        }
        if (this.state.include.length > 0) {
            resourceHeader = (<Table.ColHeader>Doucmenti rimasti</Table.ColHeader>);
        }
        tableHeader = (
            <Table.Row>
                <Table.ColHeader>Ragione sociale</Table.ColHeader>
                <Table.ColHeader>Partita iva</Table.ColHeader>
                <Table.ColHeader>Email</Table.ColHeader>
                <Table.ColHeader>Telefono</Table.ColHeader>
                {resourceHeader}
                <Table.ColHeader>Data creazione</Table.ColHeader>
                <Table.ColHeader>Data modifica</Table.ColHeader>
                <Table.ColHeader>Azioni</Table.ColHeader>
            </Table.Row>
        );
        if (this.props.order) {
            tableHeader = (
                <Table.Row>
                    <ColHeader name="business_name" onOrder={this.handleOrder.bind(this)} current={this.props.order}>Ragione sociale</ColHeader>
                    <ColHeader name="vat_number" onOrder={this.handleOrder.bind(this)} current={this.props.order}>Partita iva</ColHeader>
                    <ColHeader name="email" onOrder={this.handleOrder.bind(this)} current={this.props.order}>Email</ColHeader>
                    <ColHeader name="phone" onOrder={this.handleOrder.bind(this)} current={this.props.order}>Telefono</ColHeader>
                    {resourceHeader}
                    <ColHeader name="created_at" onOrder={this.handleOrder.bind(this)} current={this.props.order}>Data creazione</ColHeader>
                    <ColHeader name="updated_at" onOrder={this.handleOrder.bind(this)} current={this.props.order}>Data modifica</ColHeader>
                    <Table.ColHeader>Azioni</Table.ColHeader>
                </Table.Row>
            );
        }
        return (
            <SiteWrapper me={this.props.me} business={this.props.business}>
                <Page.Content title="Commercialisti">
                    <Grid.Row>
                        <Grid.Col md={12} xl={12}>
                            <Filter onSubmit={this.handleFilterSubmit.bind(this)} current={this.props.filters}/>
                            <Card>
                                <Card.Header>
                                    {add}
                                    <PageLength onChange={this.handlePerPageChange.bind(this)} current={this.props.perPage}/>
                                </Card.Header>
                                <Card.Body>
                                    <Table>
                                        <Table.Header>
                                            {tableHeader}
                                        </Table.Header>
                                        <Table.Body>
                                            {rows}
                                        </Table.Body>
                                    </Table>
                                </Card.Body>
                                <Card.Footer>
                                    {footer}
                                </Card.Footer>
                            </Card>
                        </Grid.Col>
                    </Grid.Row>
                </Page.Content>
            </SiteWrapper>
        );
    };
}

const mapStateToProps = state => {
    return {
        accountants: state.accountants.accountants,
        meta: state.accountants.meta,
        links: state.accountants.links,
        error: state.accountants.error,
        loading: state.accountants.loading,
        empty: state.accountants.empty,
        me: state.auth.me,
        business: state.auth.business,
        page: state.accountants.page,
        perPage: state.accountants.perPage,
        filters: state.accountants.filters,
        include: state.accountants.include,
        order: state.accountants.order,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onLoadAccountants: (page, perPage, filters, include, order) => dispatch(actions.loadAccountants(page, perPage, filters, include, order)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountantsPage);