import React from 'react';

import { StampCard } from 'tabler-react';
import * as moment from 'moment';

const resourceRemainingBox = (props) => {
    let footer = null;
    let diff = 0;
    if (props.from) {
        const date = moment(props.from, "DD/MM/YYYY hh:mm:ss");
        const today = moment();
        diff = today.diff(date, 'days');
        footer = " al  " + date.add(1, 'year').format("DD/MM/YYYY");
    }

    let color = "info";
    let amount = props.amount;
    if (props.amount === -1) {
        color = "green";
        amount = '\u221e';
    } else if (props.amount === 0) {
        color = "red";
    } else if (props.amount < 10 || 365 - diff < 10) {
        color = "yellow";
    }
    return (
        <StampCard
            color={color}
            icon="file"
            header={
                <div>
                    {amount} <small>Documenti disponibili</small>
                </div>
            }
            footer={footer}
        />
    );
}

export default resourceRemainingBox;