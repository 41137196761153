import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Page,
    Dimmer,
    Card,
    Grid,
    StampCard,
    List,
    Text,
    Icon,
    Form,
    Button,
    Table
} from 'tabler-react';
import { toast } from 'react-toastify';

import SiteWrapper from "../hoc/SiteWrapper/SiteWrapper";
import ResourceRemaining from '../components/UI/ResourceRemainingBox';
import api from '../api';
import {checkVatNumber} from '../shared/utility';
import * as actions from '../store/actions/index';
import Pagination from '../components/UI/Table/Pagination';
import TableMeta from '../components/UI/Table/TableMeta';
import PageLength from '../components/UI/Table/PageLength';
import ColHeader from '../components/UI/Table/ColHeader';
import { createOrderObject } from '../shared/utility';


class AccountantPage extends Component {
    state = {
        id: null,
        loading: true,
        accountant: null,
        resource: null,
        subjects: null,
        submitting: true,
        newVatNumber: "",
        requests: {
            perPage: 15,
            page: 1,
            filters: {},
            include: ["subject"],
            order: {
                by: 'request_date',
                direction: 'DESC',
            },
        }
    }
    componentDidMount() {
        this.setState({
            ...this.state,
            id: this.props.match.params.id
        });

        api.get('accountants/' + this.props.match.params.id + "?include=subjects,resource").then(response => {
            this.setState({
                ...this.state,
                loading:false,
                accountant: response.data.data.attributes,
                resource: response.data.data.relationships.resources,
                subjects: response.data.data.relationships.subjects ? response.data.data.relationships.subjects.data : []
            });
        }).catch(error => {
            console.log(error);
        });
        if (this.props.requests.page !== null) {
            this.props.onLoadRequests(this.props.match.params.id, this.props.requests.page, this.props.requests.perPage, this.props.requests.filters, this.props.requests.include, this.props.requests.order);
        } else {
            this.props.onLoadRequests(this.props.match.params.id, this.state.requests.page, this.state.requests.perPage, this.state.requests.filters, this.state.requests.include, this.state.requests.order);
        }
    }

    handleDeleteClick(id, index) {
        api.delete('accountants/' + this.state.id + "/subjects/" + id).then(response => {
            let dataSubjects = this.state.subjects;
            dataSubjects.splice(index, 1);
            this.setState({
                ...this.state,
                subjects: dataSubjects
            });
            toast.success("Soggetto associato rimosso");
        }).catch(error => {
            toast.error("Errore rimozione associazione");
        });
    }

    handleLinkClick() {
        this.setState({
            ...this.state,
            submitting: true
        });
        api.post('accountants/' + this.state.id + "/subjects/" + this.state.newVatNumber, {"a": 1}).then(response => {
            let dataSubjects = this.state.subjects;
            dataSubjects.push(response.data.data);
            this.setState({
                ...this.state,
                subjects: dataSubjects
            });
            toast.success("Soggetto associato");
        }).catch(error => {
            let message = "Errore associazione";
            if (error.response) {
                if (error.response.data.title) {
                    message = error.response.data.title;
                }
            }
            toast.error(message);
        });
    }

    handleVatNumberChange(evt) {
        this.setState({
            ...this.state,
            newVatNumber: evt.target.value,
            submitting: !checkVatNumber(evt.target.value),
        });
    }

    handlePageChange(page) {
        this.setState({
            ...this.state,
            page: page
        })
        this.props.onLoadRequests(this.props.match.params.id, page, this.props.requests.perPage, this.props.requests.filters, this.props.requests.include, this.props.requests.order);
    }

    handlePerPageChange(perPage) {
        this.setState({
            ...this.state,
            perPage: perPage
        })
        this.props.onLoadRequests(this.props.match.params.id, this.props.requests.page, perPage, this.props.requests.filters, this.props.requests.include, this.props.requests.order);
    }

    handleOrder(column) {
        const order = createOrderObject(this.state.order, column);
        this.setState({
            ...this.state,
            order: order
        });
        this.props.onLoadRequests(this.props.match.params.id, this.props.requests.page, this.props.requests.perPage, this.props.requests.filters, this.props.requests.include, order);
    }

    render() {
        let title = null;
        let dataBody = null;
        let subjectCountBody = null;
        let resourceBody = null;
        let subjectBody = null;
        if (this.state.accountant) {
            title = this.state.accountant.business_name;
            dataBody = (<List unstyled>
                    <List.Item><strong>Partita iva:</strong> {this.state.accountant.vat_number}</List.Item>
                    <List.Item><strong>Indirizzo:</strong> {this.state.accountant.address}</List.Item>
                    <List.Item><strong>Telefono:</strong> {this.state.accountant.phone}</List.Item>
                    <List.Item><strong>Cellulare:</strong> {this.state.accountant.mobile_phone}</List.Item>
                    <List.Item><strong>Email:</strong> {this.state.accountant.email}</List.Item>
                    <List.Item><strong>PEC:</strong> {this.state.accountant.pec}</List.Item>
                </List>
            );
            subjectCountBody = (
                <StampCard
                    color="blue"
                    icon="users"
                    header={
                        <div>
                            {this.state.subjects ? this.state.subjects.length : 0} <small>Soggetti</small>
                        </div>
                    }
                />
            );
            resourceBody = (
                <ResourceRemaining
                    amount={this.state.resource.data.attributes.remaining}
                    total={this.state.resource.data.attributes.total}
                    from={this.state.resource.data.attributes.last_purchase_date}>
                ></ResourceRemaining>
            );
            subjectBody = (
                <ul className="list-unstyled list-separated">
                    <li className="list-separated-item">
                        Nessun soggetto associato
                    </li>
                </ul>
            );
            if (this.state.subjects.length > 0) {
                subjectBody = this.state.subjects.map((subject, index) => {
                    return (
                        <ul key={subject.id} className="list-unstyled list-separated">
                            <li className="list-separated-item">
                                <Grid.Row className="align-items-center">
                                    <Grid.Col>
                                        <Text className="text-inherit">{subject.attributes.business_name}</Text>
                                        <Text.Small muted className="d-block item-except h-1x">
                                            {subject.attributes.vat_number}
                                        </Text.Small>
                                    </Grid.Col>
                                    <Grid.Col auto>
                                        <Icon link={true} name="trash" onClick={() => this.handleDeleteClick(subject.id, index)}/>
                                    </Grid.Col>
                                </Grid.Row>
                            </li>
                        </ul>
                    );
                });
            }
        }
        let requestHeader = (
            <Table.Row>
                <Table.ColHeader name="subjects.business_name">Soggetto</Table.ColHeader>
                <Table.ColHeader name="request_date">Data richiesta</Table.ColHeader>
                <Table.ColHeader name="request_date">Data accettazione</Table.ColHeader>
            </Table.Row>
        );
        let requestBody = (
            <Table.Row>
                <Table.Col colSpan={3}>Nessun dato presente</Table.Col>
            </Table.Row>
        );
        let requestFooter = null;
        if (this.props.requests.loading === false && this.props.requests.error === false && this.props.requests.empty === false) {
            if (this.props.requests.order) {
                requestHeader = (
                    <Table.Row>
                        <ColHeader name="subjects.business_name" onOrder={this.handleOrder.bind(this)} current={this.props.requests.order}>Soggetto</ColHeader>
                        <ColHeader name="request_date" onOrder={this.handleOrder.bind(this)} current={this.props.requests.order}>Data richiesta</ColHeader>
                        <ColHeader name="accept_date" onOrder={this.handleOrder.bind(this)} current={this.props.requests.order}>Data accettazione</ColHeader>
                    </Table.Row>
                );
            }
            requestBody = this.props.requests.data.map(request => {
                return <Table.Row key={request.id}>
                    <Table.Col>{request.relationships.subject.data.attributes.business_name}</Table.Col>
                    <Table.Col>{request.attributes.request_date}</Table.Col>
                    <Table.Col>{request.attributes.accept_date}</Table.Col>
                </Table.Row>;
            });
            requestFooter = (
                <div>
                    <TableMeta
                        from={this.props.requests.meta.from}
                        to={this.props.requests.meta.to}
                        total={this.props.requests.meta.total}
                    />
                    <Pagination links={this.props.requests.links} onPageChange={this.handlePageChange.bind(this)} />
                </div>);
        }
        return (<SiteWrapper me={this.props.me} business={this.props.business}>
            <Page.Content title={title} >
                <Grid.Row cards>
                    <Grid.Col xs={12} sm={12} md={4} lg={4}>
                        <Grid.Row>
                            <Grid.Col>
                                <Card title="Dati" statusColor="blue">
                                    <Card.Body>
                                        <Dimmer active={this.props.loading ? true : false} loader>
                                            {dataBody}
                                        </Dimmer>
                                    </Card.Body>
                                </Card>
                            </Grid.Col>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Col>
                                <Form.Group label="Aggiungi soggeto">
                                    <Form.InputGroup>
                                        <Form.Input
                                            value={this.state.newVatNumber}
                                            placeholder="Partiva iva"
                                            onChange={(e) => this.handleVatNumberChange(e)}
                                         />
                                        <Form.InputGroupAppend>
                                            <Button
                                                color="primary"
                                                onClick={() => this.handleLinkClick()}
                                                disabled={this.state.submitting ? true : false}
                                            >
                                                Associa
                                            </Button>
                                        </Form.InputGroupAppend>
                                    </Form.InputGroup>
                                </Form.Group>
                            </Grid.Col>
                        </Grid.Row>
                    </Grid.Col>
                    <Grid.Col xs={12} sm={8} md={8} lg={8}>
                        <Grid.Row>
                            <Grid.Col xs={12} sm={6} md={6} lg={6}>
                                {resourceBody}
                            </Grid.Col>
                            <Grid.Col xs={12} sm={6} md={6} lg={6}>
                                {subjectCountBody}
                            </Grid.Col>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Col xs={12} sm={12} md={12} lg={12}>
                                <Card title="Soggetti">
                                    <Card.Body>
                                        {subjectBody}
                                    </Card.Body>
                                </Card>
                            </Grid.Col>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Col xs={12} sm={12} md={12} lg={12}>
                                <Card title="Richieste di accesso">
                                    <Card.Header>
                                        <PageLength onChange={this.handlePerPageChange.bind(this)} current={this.props.requests.perPage} />
                                    </Card.Header>
                                    <Card.Body>
                                        <Table>
                                            <Table.Header>
                                                {requestHeader}
                                            </Table.Header>
                                            <Table.Body>
                                                {requestBody}
                                            </Table.Body>
                                        </Table>
                                    </Card.Body>
                                    <Card.Footer>
                                        {requestFooter}
                                    </Card.Footer>
                                </Card>
                            </Grid.Col>
                        </Grid.Row>
                    </Grid.Col>
                </Grid.Row>
            </Page.Content>
        </SiteWrapper>);
    };
}

const mapStateToProps = state => {
    return {
        me: state.auth.me,
        business: state.auth.business,
        requests: {
            meta: state.followingRequests.meta,
            links: state.followingRequests.links,
            error: state.followingRequests.error,
            loading: state.followingRequests.loading,
            empty: state.followingRequests.empty,
            page: state.followingRequests.page,
            perPage: state.followingRequests.perPage,
            filters: state.followingRequests.filters,
            include: state.followingRequests.include,
            order: state.followingRequests.order,
            data: state.followingRequests.requests,
        }
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onLoadRequests: (uuid, page, perPage, filters, include, order, params) => dispatch(actions.loadFollowRequests(uuid, page, perPage, filters, include, order, params)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountantPage);