import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Page,
    Table,
    Grid,
    Card,
    Badge,
    Button
} from 'tabler-react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import SiteWrapper from "../hoc/SiteWrapper/SiteWrapper";
import * as actions from '../store/actions/index';
import api from '../api';
import Pagination from '../components/UI/Table/Pagination';
import TableMeta from '../components/UI/Table/TableMeta';
import PageLength from '../components/UI/Table/PageLength';
import ColHeader from '../components/UI/Table/ColHeader';
import Ability from '../shared/enums/Ability';
import Role from '../shared/enums/Role';
import Filter from '../components/Filter/User';
import { createOrderObject } from '../shared/utility';

class UsersPage extends Component {

    state = {
        perPage: 15,
        page: 1,
        filters: {},
        include: [],
        order: {
            by: 'id',
            direction: 'ASC',
        },
    }

    componentDidMount() {
        if (this.props.page !== null) {
            this.props.onLoadUsers(this.props.page, this.props.perPage, this.props.filters, this.props.include, this.props.order);
        } else {
            this.props.onLoadUsers(this.state.page, this.state.perPage, this.state.filters, this.state.include, this.state.order);
        }
    }

    handlePageChange(page) {
        this.setState({
            ...this.state,
            page: page
        })
        this.props.onLoadUsers(page, this.props.perPage, this.props.filters, this.props.include, this.props.order);
    }

    handlePerPageChange(perPage) {
        this.setState({
            ...this.state,
            perPage: perPage
        })
        this.props.onLoadUsers(this.props.page, perPage, this.props.filters, this.props.include, this.props.order);
    }

    handleFilterSubmit(values) {
        this.setState({
            ...this.state,
            filters: values
        })
        this.props.onLoadUsers(1, this.props.perPage, values, this.props.include, this.props.order);
    }

    handleDeleteClick(id) {
        api.delete('users/' + id).then(response => {
            toast.success("Utente cancellato!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,

            });
            this.props.onLoadUsers();
        })
        .catch(error => {
            toast.success("Errore cancellazione!");
        });
    }

    handleOrder(column) {
        const order = createOrderObject(this.props.order, column);
        this.setState({
            ...this.state,
            order: order
        });
        this.props.onLoadUsers(this.props.page, this.props.perPage, this.props.filters, this.props.include, order);
    }


    render() {
        let add = null;
        let footer = null;
        let tableHeader = null;
        let rows = (
            <Table.Row>
                <Table.Col colSpan={4}>Nessun dato presente</Table.Col>
            </Table.Row>
        );
        if (this.props.me.abilities.indexOf(Ability.CREATE_USER) >= 0 || this.props.me.abilities.indexOf(Ability.ALL) >= 0) {
            add = (<Link to="/users/create"><Button color="primary">
                    Nuovo utente
                </Button></Link>);
        }
        if (this.props.loading === false && this.props.error === false && this.props.empty === false) {
            rows = this.props.users.map(user => {
                let roles = [];
                user.attributes.roles.forEach(function (role, index) {
                    roles.push(<Badge key={index} color="primary">{role}</Badge>);
                });
                let actions = [];
                this.props.me.abilities.forEach(function (ability) {
                    /*const view = (
                        <Link key={"view_" + user.id} to={"/users/" + user.id}>
                            <Button color="primary" icon="eye" outline />
                        </Link>
                    );*/
                    const edit = (
                        <Link key={"edit_" + user.id} to={"/users/" + user.id + "/edit"}>
                            <Button color="secondary" icon="edit" outline />
                        </Link>
                    );
                    const del = (<Button key={"del_" + user.id} color="danger" icon="user-minus" outline onClick={() => this.handleDeleteClick(user.id)} />);
                    /*if (ability === Ability.VIEW_USER) {
                        actions.push(view);
                    }*/
                    if (ability === Ability.EDIT_USER) {
                        actions.push(edit);
                    }
                    if (ability === Ability.DELETE_USER) {
                        actions.push(del);
                    }
                    if (ability === "*") {
                        //actions.push(view);
                        actions.push(edit);
                        actions.push(del);
                    }
                }.bind(this));
                if (this.props.me.roles[0] === Role.SUPERUSER) {
                    return <Table.Row key={user.id}>
                        <Table.Col>{user.id}</Table.Col>
                        <Table.Col>{user.attributes.name}</Table.Col>
                        <Table.Col>{user.attributes.email}</Table.Col>
                        <Table.Col>{roles}</Table.Col>
                        <Table.Col>{user.attributes.created_at}</Table.Col>
                        <Table.Col>{user.attributes.updated_at}</Table.Col>
                        <Table.Col><Button.List>{actions}</Button.List></Table.Col>
                    </Table.Row>;
                }
                let status = <Table.Col><Badge color="info" className="mr-1">Attivo</Badge></Table.Col>;
                if (user.attributes.disabled === 1) {
                    status = <Table.Col><Badge color="danger" className="mr-1">Disattivo</Badge></Table.Col >;
                }
                return <Table.Row key={user.id}>
                        <Table.Col>{user.id}</Table.Col>
                        <Table.Col>{user.attributes.name}</Table.Col>
                        <Table.Col>{user.attributes.email}</Table.Col>
                        <Table.Col>{roles}</Table.Col>
                        {status}
                        <Table.Col>{user.attributes.created_at}</Table.Col>
                        <Table.Col>{user.attributes.updated_at}</Table.Col>
                        <Table.Col><Button.List>{actions}</Button.List></Table.Col>
                    </Table.Row>;
            });
            footer = (
                <div>
                    <TableMeta
                        from={this.props.meta.from}
                        to={this.props.meta.to}
                        total={this.props.meta.total}
                        />
                    <Pagination links={this.props.links} onPageChange={this.handlePageChange.bind(this)}/>
                </div>);
        }
        tableHeader = (<Table.Row>
            <Table.ColHeader>ID</Table.ColHeader>
            <Table.ColHeader>Nome</Table.ColHeader>
            <Table.ColHeader>Email</Table.ColHeader>
            <Table.ColHeader>Ruolo</Table.ColHeader>
            <Table.ColHeader>Stato</Table.ColHeader>
            <Table.ColHeader>Data creazione</Table.ColHeader>
            <Table.ColHeader>Data modifica</Table.ColHeader>
            <Table.ColHeader>Azioni</Table.ColHeader>
        </Table.Row>);
        if (this.props.me.roles[0] === Role.SUPERUSER) {
            tableHeader = <Table.Row>
                <Table.ColHeader>ID</Table.ColHeader>
                <Table.ColHeader>Nome</Table.ColHeader>
                <Table.ColHeader>Email</Table.ColHeader>
                <Table.ColHeader>Ruolo</Table.ColHeader>
                <Table.ColHeader>Data creazione</Table.ColHeader>
                <Table.ColHeader>Data modifica</Table.ColHeader>
                <Table.ColHeader>Azioni</Table.ColHeader>
            </Table.Row>
        }
        if (this.props.order) {
            tableHeader = (<Table.Row>
                <ColHeader name="id" onOrder={this.handleOrder.bind(this)} current={this.props.order}>ID</ColHeader>
                <ColHeader name="name" onOrder={this.handleOrder.bind(this)} current={this.props.order}>Nome</ColHeader>
                <ColHeader name="email" onOrder={this.handleOrder.bind(this)} current={this.props.order}>Email</ColHeader>
                <Table.ColHeader>Ruolo</Table.ColHeader>
                <ColHeader name="disabled" onOrder={this.handleOrder.bind(this)} current={this.props.order}>Stato</ColHeader>
                <ColHeader name="created_at" onOrder={this.handleOrder.bind(this)} current={this.props.order}>Data creazione</ColHeader>
                <ColHeader name="updated_at" onOrder={this.handleOrder.bind(this)} current={this.props.order}>Data modifica</ColHeader>
                <Table.ColHeader>Azioni</Table.ColHeader>
            </Table.Row>);
            if (this.props.me.roles[0] === Role.SUPERUSER) {
                tableHeader = <Table.Row>
                    <ColHeader name="id" onOrder={this.handleOrder.bind(this)} current={this.props.order}>ID</ColHeader>
                    <ColHeader name="name" onOrder={this.handleOrder.bind(this)} current={this.props.order}>Nome</ColHeader>
                    <ColHeader name="email" onOrder={this.handleOrder.bind(this)} current={this.props.order}>Email</ColHeader>
                    <Table.ColHeader>Ruolo</Table.ColHeader>
                    <ColHeader name="created_at" onOrder={this.handleOrder.bind(this)} current={this.props.order}>Data creazione</ColHeader>
                    <ColHeader name="updated_at" onOrder={this.handleOrder.bind(this)} current={this.props.order}>Data modifica</ColHeader>
                    <Table.ColHeader>Azioni</Table.ColHeader>
                </Table.Row>
            }
        }
        return (
            <SiteWrapper me={this.props.me} business={this.props.business}>
                <Page.Content title="Utenti">
                    <Grid.Row>
                        <Grid.Col md={12} xl={12}>
                            <Filter onSubmit={this.handleFilterSubmit.bind(this)} current={this.props.filters}/>
                            <Card>
                                <Card.Header>
                                    {add}
                                    <PageLength onChange={this.handlePerPageChange.bind(this)} current={this.props.perPage}/>
                                </Card.Header>
                                <Card.Body>
                                    <Table>
                                        <Table.Header>
                                            {tableHeader}
                                        </Table.Header>
                                        <Table.Body>
                                            { rows }
                                        </Table.Body>
                                    </Table>
                                </Card.Body>
                                <Card.Footer>
                                    {footer}
                                </Card.Footer>
                            </Card>
                        </Grid.Col>
                    </Grid.Row>
                </Page.Content>
            </SiteWrapper>
        );
    };
}

const mapStateToProps = state => {
    return {
        users: state.users.users,
        meta: state.users.meta,
        links: state.users.links,
        error: state.users.error,
        loading: state.users.loading,
        empty: state.users.empty,
        me: state.auth.me,
        business: state.auth.business,
        page: state.users.page,
        perPage: state.users.perPage,
        filters: state.users.filters,
        include: state.users.include,
        order: state.users.order,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onLoadUsers: (page, perPage, filters, include, order) => dispatch(actions.loadUsers(page, perPage, filters, include, order)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersPage);