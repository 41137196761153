const Status = Object.freeze({
    RECEIVED: "RECEIVED",
    SENDING: "SENDING",
    SEND: "SEND",
    SENDERR: "SENDERR",
    REJECTED: "REJECTED",
    DELIVERED: "DELIVERED",
    NOTDEL: "NOTDEL",
    ACCEPTED: "ACCEPTED",
    REFUSED: "REFUSED",
    ELAPSED: "ELAPSED",
    IMPDEL: "IMPDEL",
    RJESITO: "RJESITO",
    SENDOUTCOME: "SENDOUTCOME"
});

export default Status;