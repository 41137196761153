// @flow

import * as React from "react";
import { NavLink, withRouter, Link } from "react-router-dom";

import {
    Site,
    Grid,
    List,
    RouterContextProvider,
    Alert
} from "tabler-react";
//import Breadcrumbs from 'react-router-dynamic-breadcrumbs';
import AdBlockDetect from 'react-ad-block-detect';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import Ability from '../../shared/enums/Ability';
import Breadcrumbs from '../../components/Breadcrumbs';

import Role from "../../shared/enums/Role";
import * as moment from 'moment';

/*type Props = {|
  +children: React.Node,
|};

type subNavItem = {|
  +value: string,
    +to ?: string,
    +icon ?: string,
    +LinkComponent ?: React.ElementType,
|};

type navItem = {|
  +value: string,
    +to ?: string,
    +icon ?: string,
    +active ?: boolean,
    +LinkComponent ?: React.ElementType,
    +subItems ?: Array < subNavItem >,
|};

const notificationsObjects = [
    {
        avatarURL: "demo/faces/male/41.jpg",
        message: (
            <React.Fragment>
                <strong>Nathan</strong> pushed new commit: Fix page load performance
                issue.
      </React.Fragment>
        ),
        time: "10 minutes ago",
    },
    {
        avatarURL: "demo/faces/female/1.jpg",
        message: (
            <React.Fragment>
                <strong>Alice</strong> started new task: Tabler UI design.
      </React.Fragment>
        ),
        time: "1 hour ago",
    },
    {
        avatarURL: "demo/faces/female/18.jpg",
        message: (
            <React.Fragment>
                <strong>Rose</strong> deployed new version of NodeJS REST Api // V3
      </React.Fragment>
        ),
        time: "2 hours ago",
    },
];*/

const accountDropdownDefaultProps = {
    avatarURL: "./demo/faces/female/25.jpg",
    name: "Jane Pearson",
    description: "Administrator",
    options: [
        { icon: "user", value: "Profilo", to: "/profile" },
        { icon: "lock", value: "Cambio password", to: "/change-password" },
        { isDivider: true },
        { icon: "help-circle", value: "Cerchi aiuto?", to: "/help", LinkComponent: withRouter(NavLink) },
        { icon: "log-out", value: "Sign out", to: "/logout" },
    ],
};

const accountDropdownAccountantProps = {
    avatarURL: "./demo/faces/female/25.jpg",
    name: "Jane Pearson",
    description: "Administrator",
    options: [
        { icon: "user", value: "Profilo", to: "/profile" },
        { icon: "lock", value: "Cambio password", to: "/change-password" },
        { icon: "settings", value: "Preferenze", to: "/settings" },
        { isDivider: true },
        { icon: "help-circle", value: "Cerchi aiuto?", to: "/help", LinkComponent: withRouter(NavLink) },
        { icon: "log-out", value: "Sign out", to: "/logout" },
    ],
};


const usersMenu = { value: "Utenti", to: "/users", LinkComponent: withRouter(NavLink) };
const subjectsMenu = { value: "Soggetti", to: "/subjects", LinkComponent: withRouter(NavLink) };
const accountantsMenu = { value: "Commercialisti", to: "/accountants", LinkComponent: withRouter(NavLink) };
const logsMenu = { value: "Logs", to: "/logs", LinkComponent: withRouter(NavLink) };
const activeMenu = {
    value: "Ciclo attivo",
    to: "/documents/active",
    LinkComponent: withRouter(NavLink),
};
const passiveMenu = {
    value: "Ciclo passivo",
    to: "/documents/passive",
    LinkComponent: withRouter(NavLink),
};

class SiteWrapper extends React.Component {

    render(): React.Node {
        let navBarItems = [{ value: "Home", to: "/", LinkComponent: withRouter(NavLink) }];
        let accountDropdownProps = accountDropdownDefaultProps;
        if (typeof this.props.me != "undefined" && this.props.me != null) {
            let authorizatedNavBarItems = [];
            switch (this.props.me.roles[0]) {
                case Role.SUPERADMIN:
                case Role.ADMIN:
                    authorizatedNavBarItems = [
                        usersMenu,
                        subjectsMenu,
                        accountantsMenu,
                        {
                            value: "Documenti",
                            subItems: [
                                activeMenu,
                                passiveMenu
                            ]
                        },
                        logsMenu,
                    ];
                    break;
                case Role.USER:
                case Role.COMMERCIALISTA:
                    this.props.me.abilities.forEach(function (ability) {
                        switch (ability) {
                            case Ability.VIEW_SUBJECT:
                                authorizatedNavBarItems.push(subjectsMenu);
                                break;
                            case Ability.SEND_DOC:
                                authorizatedNavBarItems.push(activeMenu);
                                break;
                            case Ability.RECEIVE_DOC:
                                authorizatedNavBarItems.push(passiveMenu);
                                break;
                            default:
                                break;
                        }
                    });
                    accountDropdownProps = accountDropdownAccountantProps;
                    break;
                default:
                    break;
            }
            accountDropdownProps.name = this.props.me.name;
            accountDropdownProps.description = this.props.business ? this.props.business.attributes.business_name : this.props.me.roles.join(",");
            accountDropdownProps.avatarURL = "/assets/images/" + this.props.me.name.charAt(0).toLowerCase() + ".png";
            navBarItems = [...navBarItems, ...authorizatedNavBarItems];
        }
        return (
            <Site.Wrapper
                headerProps={{
                    href: "/",
                    alt: "FatturaFatta",
                    imageURL: "/assets/images/fattura-fatta.png",
                    navItems: null,
                    notificationsTray: null,
                    accountDropdown: accountDropdownProps,
                }}
                navProps={{ itemsObjects: navBarItems }}
                routerContextComponentType={withRouter(RouterContextProvider)}
                footerProps={{
                    copyright: (
                        <React.Fragment>
                            Copyright © {moment().year()}.
              <a href="https://fatturafatta.it" target=" _blank" rel="noopener noreferrer"> Fattura Fatta</a> è un marchio di 
              <a
                                href="https://direcas.com"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {" "}
                                DIRECAS s.r.l.
              </a>{" "}
                            Tutti i diritti riservati.
            </React.Fragment>
                    ),
                    nav: (
                        <React.Fragment>
                            <Grid.Col auto={true}>
                                <List className="list-inline list-inline-dots mb-0">
                                    <List.Item className="list-inline-item">
                                        <Link to="/faq">FAQ</Link>
                                    </List.Item>
                                </List>
                            </Grid.Col>
                        </React.Fragment>
                    ),
                }}
            >
                {/* <Router>
                    <Breadcrumbs mappedRoutes={routes} />
                </Router> */}
                <div className="breadcrumb"><Breadcrumbs /></div>
                <AdBlockDetect>
                    <Alert type="danger" icon="alert-triangle">
                        Rilevato AdBlocker. Per il corretto funzionamento del sito è necessario disabilitarlo.
                    </Alert>
                </AdBlockDetect>
                {this.props.children}
                <ToastContainer />
            </Site.Wrapper>
        );
    }
}

export default SiteWrapper;
