import React from 'react';

import { Card, colors } from 'tabler-react';
import C3Chart from "react-c3js";

const colorsObj = {
    data1: colors["blue-darker"],
    data2: colors["blue"],
    data3: colors["blue-light"],
    data4: colors["blue-lighter"],
    data5: colors["azure-darker"],
    data6: colors["azure"],
    data7: colors["azure-light"],
    data8: colors["azure-lighter"],
    data9: colors["indigo-darker"],
    data10: colors["indigo"],
    data11: colors["indigo-light"],
    data12: colors["indigo-lighter"],
}

const pieChart = (props) => {
    return (
        <Card>
            <Card.Header>
                <Card.Title>{props.title}</Card.Title>
            </Card.Header>
            <Card.Body>
                <C3Chart
                    style={{ height: "15rem" }}
                    data={{
                        columns: props.datas,
                        type: "pie", // default type of chart
                        colors: colorsObj,
                        names: props.names,
                    }}
                    legend={{
                        show: true, //hide legend
                    }}
                    padding={{
                        bottom: 0,
                        top: 0,
                    }}
                />
            </Card.Body>
        </Card>
    );
}
export default pieChart;