import React, { Component } from "react";
import Select from "react-select";

export default class SelectWrapperComponent extends Component {

    handleChange = value => {
        // this is going to call setFieldValue and manually update values.topcis
        this.props.onChange(this.props.selectName, value);
    };

    handleBlur = () => {
        // this is going to call setFieldTouched and manually update touched.topcis
        this.props.onBlur(this.props.selectName, true);
    };

    render() {
        const props = this.props;
        return (
            <React.Fragment>
                <Select
                    options={props.options}
                    isMulti={props.isMulti}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    closeMenuOnSelect={props.closeMenuOnSelect}
                    isClearable={props.isClearable}
                    value={props.selectedValue}
                />
            </React.Fragment>
        );
    }
}