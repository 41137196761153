import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    logs: null,
    meta: null,
    links: null,
    error: false,
    loading: true,
    empty: true,
    perPage: null,
    page: null,
    filters: null,
    include: null,
    order: null
};

const loadingStart = (state) => {
    return updateObject(state, { error: false, loading: true });
};

const setLogs = (state, action) => {
    return updateObject(state, {
        logs: action.logs.data,
        meta: action.logs.meta,
        links: action.logs.links,
        error: false,
        loading: false,
        empty: false
    });
};

const fetchLogsFailed = (state) => {
    return updateObject(state, { error: true, loading: false });
};

const fetchLogsEmpty = (state) => {
    return updateObject(state, { error: false, loading: false, empty: true, logs: null });
};

const setDocumentsTableStatus = (state, action) => {
    return updateObject(state, {
        page: action.page,
        perPage: action.perPage,
        filters: action.filters,
        include: action.include,
        order: action.order
    });
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOADING_START: return loadingStart(state);
        case actionTypes.SET_LOGS: return setLogs(state, action);
        case actionTypes.FETCH_LOGS_FAILED: return fetchLogsFailed(state);
        case actionTypes.FETCH_LOGS_EMPTY: return fetchLogsEmpty(state);
        case actionTypes.SET_LOGS_TABLE_STATUS: return setDocumentsTableStatus(state, action);
        default: return state;
    }
};

export default reducer;