import React from 'react';

import { Icon } from 'tabler-react';

const colHeader = (props) => {
    let icon = null;
    let thStyle = style.header;
    if (props.current.by === props.name) {
        icon = props.current.direction === 'ASC' ? <Icon name="arrow-down"></Icon> : <Icon name="arrow-up"></Icon>
        thStyle = style.headerSelected;
    }
    return (
        <th style={thStyle} onClick={() => props.onOrder(props.name)}>{props.children} {icon}</th>
    );
}

const style = {
    header: {
        cursor: "pointer"
    },
    headerSelected: {
        cursor: "pointer",
        color: '#467fcf',
    }
}

export default colHeader;