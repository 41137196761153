const Type = Object.freeze({
    NS: "Notifica di scarto",
    RC: "Ricevuta consegna",
    MC: "Mancata consegna",
    MT: "Metadati",
    SE: "Scarto esito committente",
    NE: "Esito committente",
    DT: "Decorrenza termini",
    AT: "Attestazione avenuta trasmissione",
});

export default Type;