import * as actionTypes from './actionTypes';
import api from '../../api';
import { createQueryString } from '../../shared/utility';

export const loadingStart = () => {
    return {
        type: actionTypes.LOADING_START_FOLLOWING_REQUESTS
    };
};

export const setRequests = (requests) => {
    return {
        type: actionTypes.SET_FOLLOWING_REQUESTS,
        requests: requests
    };
};

export const fetchRequestsFailed = () => {
    return {
        type: actionTypes.FETCH_FOLLOWING_REQUESTS_FAILED
    };
};

export const setRequestsEmpty = () => {
    return {
        type: actionTypes.FETCH_FOLLOWING_REQUESTS_EMPTY
    };
};

export const tokenExpired = () => {
    return {
        type: actionTypes.TOKEN_EXPIRED
    }
}

export const loadFollowRequests = (uuid, page, perPage, filters, include, order, params) => {
    return dispatch => {
        dispatch(loadingStart());
        const queryString = createQueryString(page, perPage, filters, include, order, params);
        api.get('accountants/' + uuid + '/follow-requests' + queryString)
            .then(response => {
                switch (response.status) {
                    case 204:
                        dispatch(setRequestsEmpty());
                        break;
                    default:
                        dispatch(setRequests(response.data));
                        dispatch(setTableStatus(page, perPage, filters, include, order));
                        break;
                }
            })
            .catch(error => {
                if (process.env.NODE_ENV === 'development') {
                    console.log(error);
                }
                if (error.response) {
                    if (error.response.status === 401) {
                        if (error.response.data.code === "107") {
                            dispatch(tokenExpired());
                        }
                    }
                }
                dispatch(fetchRequestsFailed());
            });
    };
};

const setTableStatus = (page, perPage, filters, include, order) => {
    return {
        type: actionTypes.SET_FOLLOWING_REQUESTS_TABLE_STATUS,
        page: page,
        perPage: perPage,
        filters: filters,
        include: include,
        order: order
    };
}