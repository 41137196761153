import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Page,
    Dimmer,
    Card,
    Button,
    Form,
    Grid,
    Avatar,
    Media
} from 'tabler-react';
import { Formik } from "formik";
import { toast } from 'react-toastify';

import SiteWrapper from "../hoc/SiteWrapper/SiteWrapper";
import api from '../api';
import * as actions from '../store/actions/index';
import Role from "../shared/enums/Role";
class ProfilePage extends Component {

    constructor() {
        super();
        this.state = {
            initialValues: {
                me: {
                    name: "",
                    email: "",
                },
                business: {
                    business_name: "",
                    vat_number: "",
                    address: "",
                    email: "",
                    pec: "",
                    phone: "",
                    mobile_phone: "",
                    id: 0
                }
            },
            accountant: null
        }
    }
    componentWillMount() {
        if (this.props.me) {
            let business = null;
            if (this.props.business) {
                business = this.props.business.attributes;
                business['id'] = this.props.userableId;
            }
            this.setState({
                ...this.state,
                initialValues: {
                    me: {
                        name: this.props.me.name,
                        email: this.props.me.email
                    },
                    business: business,
                }
            });
            if (this.props.me.roles[0] === Role.USER) {
                api.get('me/accountant').then(response => {
                    if (response.status === 200) {
                        this.setState({
                            ...this.state,
                            accountant: response.data.data
                        });
                    }
                })
            }
        }
    }

    render() {
        let accountantCard = null;
        if (this.state.accountant) {
            accountantCard = (
                <Card title="Il tuo commercialista">
                    <Card.Body>
                        <Media>
                            <Media.BodySocial
                                name={this.state.accountant.attributes.business_name}
                                workTitle={this.state.accountant.attributes.vat_number}
                                mail={this.state.accountant.attributes.mail}
                                phone={this.state.accountant.attributes.phone}
                            />
                        </Media>
                    </Card.Body>
                </Card>
            );
        }
        if (this.props.isAuthenticated) {
            let profileContent = (<Formik
                enableReinitialize
                initialValues={this.state.initialValues.me}
                validate={values => {
                    let errors = {};
                    if (!values.email) {
                        errors.email = "Required";
                    } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
                    ) {
                        errors.email = "Invalid email address";
                    }
                    if (!values.name) {
                        errors.name = "Required";
                    }
                        return errors;
                    }}
                onSubmit={(
                    values,
                    { setSubmitting, setErrors /* setValues and other goodies */ }
                ) => {
                    setTimeout(() => {
                        let data = { "data": {} };
                        data.data.type = 'users';
                        data.data.attributes = {};
                        data.data.attributes.name = values.name;
                        data.data.attributes.email = values.email;
                        data.data.attributes.password = values.password;
                        data.data.attributes.password_confirmation = values.password_confirmation;
                        data.data.attributes.role = this.props.me.roles[0];
                        data.data.attributes.abilities = values.abilities;
                        api.put('me/' + this.props.me.id, data)
                            .then(response => {
                                toast.success("Utente modificato");
                                this.props.onUpdateUser();
                                setSubmitting(false);
                            })
                            .catch(errors => {
                                console.log(errors.response);
                                toast.error("Errore modifica utente");
                                if (errors.response.status === 422) {
                                    errors.response.data.source.forEach(function (error) {
                                        toast.error(error.message);
                                    });
                                }
                                setSubmitting(false);
                            });
                    }, 400);
                }}
                render={({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                }) => (
                        <Form onSubmit={handleSubmit}>
                            <Grid.Row>
                                <Grid.Col auto>
                                    <Avatar size="xl" imageURL={"/assets/images/" + this.props.me.name.charAt(0).toLowerCase() + ".png"} />
                                </Grid.Col>
                                <Grid.Col>
                                    <Form.Input
                                        name='name'
                                        label='Nome'
                                        placeholder='Nome'
                                        value={values.name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={(errors.name && touched.name) ? "invalid" : null}
                                        feedback={(errors.name && touched.name) ? errors.name : null}
                                    />
                                </Grid.Col>
                            </Grid.Row>
                            <Form.Group>
                                <Form.Input
                                    name='email'
                                    label='Email'
                                    placeholder='Email'
                                    type="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    invalid={(errors.email && touched.email) ? "invalid" : null}
                                    feedback={(errors.email && touched.email) ? errors.email : null}
                                />
                            </Form.Group>
                            <Form.Footer>
                                <Button type='submit' color="primary" block disabled={isSubmitting}>Salva</Button>
                            </Form.Footer>
                        </Form>
                    )}
            />);

            let contentBusiness
            if (this.state.initialValues.business) {
                contentBusiness = (<Formik
                enableReinitialize
                initialValues={this.state.initialValues.business}
                validate={values => {
                    // same as above, but feel free to move this into a class method now.
                    let errors = {};
                    if (!values.business_name) {
                        errors.business_name = "Campo obbligatorio";
                    }
                    if (!values.vat_number) {
                        errors.vat_number = "Campo obbligatorio";
                    } else if (values.vat_number.length !== 11) {
                        errors.vat_number = "Campo non valido";
                    }
                    if ((values.email !== "" && values.email !== null) &&
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
                    ) {
                        errors.email = "Campo non valido";
                    }
                    if ((values.pec !== "" && values.pec !== null) &&
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.pec)
                    ) {
                        errors.email = "Campo non valido";
                    }
                    return errors;
                }}
                onSubmit={(
                    values,
                    { setSubmitting, setErrors /* setValues and other goodies */ }
                ) => {
                    setTimeout(() => {
                        let data = { "data": {} };
                        const type = this.props.me.roles[0] === Role.COMMERCIALISTA ? 'accountants' : 'subjects';
                        data.data.type = type;
                        data.data.attributes = {};
                        data.data.attributes.business_name = values.business_name;
                        data.data.attributes.vat_number = values.vat_number;
                        if (values.email !== null && values.email !== "") {
                            data.data.attributes.email = values.email;
                        }
                        if (values.pec !== null && values.pec !== "") {
                            data.data.attributes.pec = values.pec;
                        }
                        data.data.attributes.user = values.user;
                        data.data.attributes.phone = values.phone;
                        data.data.attributes.mobile_phone = values.mobile_phone;
                        data.data.attributes.address = values.address;
                        data.data.attributes.resource = 0;
                        api.put('me/' + type + '/' + values.id, data)
                            .then(response => {
                                toast.success("Dati aziendali aggiornati");
                                this.props.onUpdateUser();
                                setSubmitting(false);
                            })
                            .catch(errors => {
                                console.log(errors.response);
                                toast.error("Errore aggiornamento dati aziendali");
                                if (errors.response.status === 422) {
                                    errors.response.data.source.forEach(function (error) {
                                        toast.warning(error.message);
                                    });
                                }
                                setSubmitting(false);
                            });
                    }, 400);
                }}
                render={({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                }) => (
                        <Grid.Col lg={8}>
                            <Card>
                                <Card.Header>
                                    <Card.Title>Dati aziendali</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Form onSubmit={handleSubmit}>
                                        <Grid>
                                            <Grid.Row>
                                                <Grid.Col>
                                                    <Form.Input
                                                        name='business_name'
                                                        label='Ragione sociale'
                                                        placeholder='Ragione sociale'
                                                        value={values.business_name}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        invalid={(errors.business_name && touched.business_name) ? "invalid" : null}
                                                        feedback={(errors.business_name && touched.business_name) ? errors.business_name : null}
                                                    />
                                                </Grid.Col>
                                            </Grid.Row>
                                        </Grid>
                                        <Grid>
                                            <Grid.Row>
                                                <Grid.Col xs={12} sm={12} md={6} lg={6}>
                                                    <Form.Input
                                                        name='vat_number'
                                                        label='Partita iva'
                                                        placeholder='Partita iva'
                                                        value={values.vat_number}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        invalid={(errors.vat_number && touched.vat_number) ? "invalid" : null}
                                                        feedback={(errors.vat_number && touched.vat_number) ? errors.vat_number : null}
                                                    />
                                                </Grid.Col>
                                                <Grid.Col xs={12} sm={12} md={6} lg={6}>
                                                    <Form.Input
                                                        name='address'
                                                        label='Indirizzo'
                                                        placeholder='Indirizzo'
                                                        value={values.address !== null ? values.address : ""}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        invalid={(errors.address && touched.address) ? "invalid" : null}
                                                        feedback={(errors.address && touched.address) ? errors.address : null}
                                                    />
                                                </Grid.Col>
                                            </Grid.Row>
                                        </Grid>
                                        <Grid>
                                            <Grid.Row>
                                                <Grid.Col xs={12} sm={12} md={6} lg={6}>
                                                    <Form.Input
                                                        name='email'
                                                        label='Email'
                                                        placeholder='Inserisci email'
                                                        value={values.email !== null ? values.email : ""}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        invalid={(errors.email && touched.email) ? "invalid" : null}
                                                        feedback={(errors.email && touched.email) ? errors.email : null}
                                                    />
                                                </Grid.Col>
                                                <Grid.Col xs={12} sm={12} md={6} lg={6}>
                                                    <Form.Input
                                                        name='pec'
                                                        label='PEC'
                                                        placeholder='pec'
                                                        value={values.pec !== null ? values.pec : ""}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        invalid={(errors.pec && touched.pec) ? "invalid" : null}
                                                        feedback={(errors.pec && touched.pec) ? errors.pec : null}
                                                    />
                                                </Grid.Col>
                                            </Grid.Row>
                                        </Grid>
                                        <Grid>
                                            <Grid.Row>
                                                <Grid.Col xs={12} sm={12} md={6} lg={6}>
                                                    <Form.Input
                                                        name='phone'
                                                        label='Telefono'
                                                        placeholder='Telefono'
                                                        value={values.phone !== null ? values.phone : ""}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        invalid={(errors.phone && touched.phone) ? "invalid" : null}
                                                        feedback={(errors.phone && touched.phone) ? errors.phone : null}
                                                    />
                                                </Grid.Col>
                                                <Grid.Col xs={12} sm={12} md={6} lg={6}>
                                                    <Form.Input
                                                        name='mobile_phone'
                                                        label='Cellulare'
                                                        placeholder='cellulare'
                                                        value={values.mobile_phone !== null ? values.mobile_phone : ""}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        invalid={(errors.mobile_phone && touched.mobile_phone) ? "invalid" : null}
                                                        feedback={(errors.mobile_phone && touched.mobile_phone) ? errors.mobile_phone : null}
                                                    />
                                                </Grid.Col>
                                            </Grid.Row>
                                        </Grid>
                                        <Button type='submit' color="primary" disabled={isSubmitting}>Salva</Button>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Grid.Col>
                    )}
                />);
            }

            return (
                <SiteWrapper me={this.props.me} business={this.props.business}>
                    <Page.Content>
                        <Grid.Row>
                            <Grid.Col lg={4}>
                                <Card>
                                    <Card.Header>
                                        <Card.Title>Profilo</Card.Title>
                                    </Card.Header>
                                    <Card.Body>
                                        {profileContent}
                                    </Card.Body>
                                </Card>
                                {accountantCard}
                            </Grid.Col>
                            {contentBusiness}
                        </ Grid.Row>
                    </Page.Content>
                </SiteWrapper>
            );
        }
        return (<SiteWrapper me={this.props.me} business={this.props.business}>
            <Dimmer loader></Dimmer>
        </SiteWrapper>);
    };
}

const mapStateToProps = state => {
    return {
        status: state.documentStatus.status,
        me: state.auth.me,
        business: state.auth.business,
        userableId: state.auth.userableId,
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onUpdateUser: () => dispatch(actions.updateMe()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);