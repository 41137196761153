import * as actionTypes from './actionTypes';
import api from '../../api';
import { createQueryString } from '../../shared/utility';

export const loadingStart = () => {
    return {
        type: actionTypes.LOADING_START
    };
};

export const setSubjects = (subjects) => {
    return {
        type: actionTypes.SET_SUBJECTS,
        subjects: subjects
    };
};

export const fetchSubjectsFailed = () => {
    return {
        type: actionTypes.FETCH_SUBJECTS_FAILED
    };
};

export const setSubjectsEmpty = () => {
    return {
        type: actionTypes.FETCH_SUBJECTS_EMPTY
    };
};

export const tokenExpired = () => {
    return {
        type: actionTypes.TOKEN_EXPIRED
    }
}

export const loadSubjects = (page, perPage, filters, include, order, params) => {
    return dispatch => {
        dispatch(loadingStart());
        const queryString = createQueryString(page, perPage, filters, include, order, params);
        api.get('subjects' + queryString)
            .then(response => {
                switch (response.status) {
                    case 204:
                        dispatch(setSubjectsEmpty());
                        break;
                    default:
                        dispatch(setSubjects(response.data));
                        dispatch(setTableStatus(page, perPage, filters, include, order));
                        break;
                }
            })
            .catch(error => {
                if (process.env.NODE_ENV === 'development') {
                    console.log(error.response);
                }
                if (error.response) {
                    if (error.response.status === 401) {
                        if (error.response.data.code === "107") {
                            dispatch(tokenExpired());
                        }
                    }
                }
                dispatch(fetchSubjectsFailed());
            });
    };
};

export const setCurrentSubjectVatNumber = (vatNumber) => {
    return {
        type: actionTypes.SET_SUBJECT_VAT_NUMBER,
        vatNumber: vatNumber
    };
}

const setTableStatus = (page, perPage, filters, include, order) => {
    return {
        type: actionTypes.SET_SUBJECT_TABLE_STATUS,
        page: page,
        perPage: perPage,
        filters: filters,
        include: include,
        order: order
    };
}