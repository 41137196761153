import React, { Component } from 'react';
import {
    Page,
    Form,
    Card,
    Button
} from 'tabler-react';
import 'react-toastify/dist/ReactToastify.min.css';
import { Formik } from "formik";
import { toast } from 'react-toastify';
import { connect } from 'react-redux';

import SiteWrapper from "../hoc/SiteWrapper/SiteWrapper";
import FormGroupFeedback from '../components/UI/FormGroupFeedback';
import api from '../api';
import { parsingApiError } from '../shared/utility';

class UserCreatePage extends Component {

    state = {
        roles: [],
        abilities: [],
        initialValues: {
            name: "",
            email: "",
            password_confirmation: "",
            password: "",
            role: "",
            abilities: [],
            isCloseButton: false
        }
    };

    componentDidMount() {
        api.get('users/roles').then(response => {
            this.setState({
                ...this.state,
                roles: response.data.data
            });
        }).catch(error => {

        });
        api.get('users/abilities').then(response => {
            this.setState({
                ...this.state,
                abilities: response.data.data
            });
        }).catch(error => {

        });
    }

    render() {
        let content = (<Formik
            initialValues = {this.state.initialValues}
            validate = {values => {
                // same as above, but feel free to move this into a class method now.
                let errors = {};
                if (!values.email) {
                    errors.email = "Required";
                } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
                ) {
                    errors.email = "Invalid email address";
                }
                if (!values.name) {
                    errors.name = "Required";
                }
                if (!values.password) {
                    errors.password = "Required";
                }
                if (!values.password_confirmation) {
                    errors.password_confirmation = "Required";
                } else if (values.password !== values.password_confirmation) {
                    errors.password = "Le password non coincidono";
                }
                if (!values.role) {
                    errors.role = "Required";
                }
                return errors;
            }}
            onSubmit={(
                values,
                { setSubmitting, setErrors /* setValues and other goodies */ }
            ) => {
                setTimeout(() => {
                    let data = {"data": {}};
                    data.data.type = 'users';
                    data.data.attributes = {};
                    data.data.attributes.name = values.name;
                    data.data.attributes.email = values.email;
                    data.data.attributes.password = values.password;
                    data.data.attributes.password_confirmation = values.password_confirmation;
                    data.data.attributes.role = values.role;
                    data.data.attributes.abilities = values.abilities;
                    api.post('users', data)
                        .then(response => {
                            if (values.isCloseButton) {
                                this.props.history.push('/users');
                            } else {
                                toast.success("Utente creato");
                                setSubmitting(false);
                            }
                        })
                        .catch(errors => {
                            toast.error("Errore creazione utente");
                            if (errors.response) {
                                if (errors.response.status === 422) {
                                    setErrors(parsingApiError(errors.response.data.source));
                                }
                            }
                            setSubmitting(false);
                        });
                }, 400);
            }}
            render={({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue
            }) => (
                <Form onSubmit={handleSubmit}>
                    <Form.Input
                        name='name'
                        label='Nome'
                        placeholder='Inserisci nome'
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        invalid={(errors.name && touched.name) ? "invalid" : null}
                        feedback={(errors.name && touched.name) ? errors.name : null}
                    />
                    <Form.Input
                        name='email'
                        label='Email'
                        placeholder='Inserisci email'
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        invalid={(errors.email && touched.email) ? "invalid" : null}
                        feedback={(errors.email && touched.email) ? errors.email : null}
                    />
                    <Form.Input
                        name='password'
                        label='Password'
                        placeholder='Inserisci password'
                        type="password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        invalid={(errors.password && touched.password) ? "invalid" : null}
                        feedback={(errors.password && touched.password) ? errors.password : null}
                    />
                    <Form.Input
                        name='password_confirmation'
                        label='Conferma password'
                        placeholder='Inserisci di nuovo la password'
                        type="password"
                        value={values.password_confirmation}
                        onChange={handleChange}
                        onBlur={handleBlur}
                            invalid={(errors.password_confirmation && (touched.password_confirmation || touched.password)) ? "invalid" : null}
                            feedback={(errors.password_confirmation && (touched.password_confirmation || touched.password)) ? errors.password_confirmation : null}
                    />
                    <FormGroupFeedback label="Ruolo"
                            invalid={(errors.role && touched.role) ? "invalid" : null}
                            feedback={(errors.role && touched.role) ? errors.role : null}
                    >
                        <Form.SelectGroup>
                            {
                                this.state.roles.map((role, i) => {
                                    return <Form.SelectGroupItem
                                        key={role.id}
                                        label={role.attributes.title}
                                        name="role"
                                        value={role.attributes.name}
                                        onChange={handleChange}
                                    />
                                })
                            }
                        </Form.SelectGroup>
                    </FormGroupFeedback>
                    <Form.Group label="Permessi">
                        <Form.SelectGroup
                            canSelectMultiple
                            pills
                        >
                            {
                                this.state.abilities.map((ability, i) => {
                                    return <Form.SelectGroupItem
                                        key={ability.id}
                                        label={ability.attributes.title}
                                        name="abilities"
                                        value={ability.attributes.name}
                                        onChange={(event) => {
                                            const value = ability.attributes.name;
                                            if (values.abilities.indexOf(value) >= 0) {
                                                values.abilities.splice(values.abilities.indexOf(value), 1);
                                            }
                                            if (event.target.checked) {
                                                values.abilities.push(value);
                                            }
                                        }}
                                    />
                                })
                            }
                        </Form.SelectGroup>
                    </Form.Group>
                    <Button.List>
                        <Button type='button' color="primary" disabled={isSubmitting} onClick={(e) => {
                            setFieldValue('isCloseButton', false)
                                setTimeout(() => handleSubmit(e.persist()), 0)
                        }}>Crea e continua</Button>
                        <Button type='button' color="cyan" disabled={isSubmitting} onClick={(e) => {
                            setFieldValue('isCloseButton', true)
                            setTimeout(() => handleSubmit(e.persist()), 0)
                        }}>Crea e chiudi</Button>
                    </Button.List>
                </Form>
            )}
        />);

        return (<SiteWrapper me={this.props.me} business={this.props.business}>
            < Page.Content title = "Nuovo utente" >
                <Card>
                    <Card.Body>
                        { content }
                    </Card.Body>
                </Card>
            </ Page.Content>
        </SiteWrapper>);
    };
}

const mapStateToProps = state => {
    return {
        me: state.auth.me,
        business: state.auth.business
    };
};

export default connect(mapStateToProps)(UserCreatePage);