import axios from 'axios';

axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.REACT_APP_CORS_URL;
axios.defaults.headers.common['Accept'] = process.env.REACT_APP_API_ACCEPT + "+json";

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL + '/api/'
});

let isRefreshing = false;
let refreshSubscribers = [];

instance.interceptors.request.use(function (config) {
    config.headers.Authorization = "Bearer " + localStorage.getItem('token');
    //console.log(config);
    if (config.method === "post" || config.method === "put") {
        config.headers['Content-Type'] = process.env.REACT_APP_API_CONTENT_TYPE + "+json";
    }
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

function refreshToken() {
    instance.get('auth/refresh')
    .then(response => {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('expire_on', response.data.expire_on);
        //dispatch(authSuccess(response.data.token));
        isRefreshing = false;
        onRrefreshed(response.data.token);
    })
    .catch(error => {
        localStorage.removeItem('token');
        localStorage.removeItem('expirationDate');
    });
}

function onRrefreshed(token) {
    refreshSubscribers.map(cb => cb(token));
}

function subscribeTokenRefresh(cb) {
    refreshSubscribers.push(cb);
}

instance.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    const { config, response: { status, data } } = error;
    const originalRequest = config;

    if (status === 401 && data.code === "101") {
        if (!isRefreshing) {
            isRefreshing = true;
            refreshToken();
        }

        const retryOrigReq = new Promise((resolve, reject) => {
            subscribeTokenRefresh(token => {
                // replace the expired token and retry
                originalRequest.headers['Authorization'] = 'Bearer ' + token;
                resolve(instance(originalRequest));
            });
        });
        return retryOrigReq;
    } else {
        return Promise.reject(error);
    }
});

export default instance;