import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    downloads: null,
    meta: null,
    links: null,
    error: false,
    loading: true,
    empty: true
};

const loadingStart = (state) => {
    return updateObject(state, { error: false, loading: true });
};

const setDownloads = (state, action) => {
    return updateObject(state, {
        downloads: action.downloads.data,
        meta: action.downloads.meta,
        links: action.downloads.links,
        error: false,
        loading: false,
        empty: false
    });
};

const fetchDownloadsFailed = (state) => {
    return updateObject(state, { error: true, loading: false });
};

const fetchDownloadsEmpty = (state) => {
    return updateObject(state, { error: false, loading: false, empty: true, downloads: null });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOADING_START_ACCOUNTANT_DOWNLOADS: return loadingStart(state);
        case actionTypes.SET_ACCOUNTANT_DOWNLOADS: return setDownloads(state, action);
        case actionTypes.FETCH_ACCOUNTANT_DOWNLOADS_FAILED: return fetchDownloadsFailed(state);
        case actionTypes.FETCH_ACCOUNTANT_DOWNLOADS_EMPTY: return fetchDownloadsEmpty(state);
        default: return state;
    }
};

export default reducer;