import React, { Component } from 'react';
import {
    Page
} from 'tabler-react';
import 'react-toastify/dist/ReactToastify.min.css';
import { connect } from 'react-redux';

import SiteWrapper from "../hoc/SiteWrapper/SiteWrapper";
import MassiveDownload  from '../components/Form/MassiveDownload';
import Export from '../components/Form/Export';

class SubjectDownloadPage extends Component {

    state = {
        subjectId: null,
    };

    componentDidMount() {
        this.setState({
            ...this.state,
            subjectId: this.props.match.params.id
        });
    }

    render() {
        return (<SiteWrapper me={this.props.me} business={this.props.business}>
            < Page.Content title="Download documenti" >
                <MassiveDownload subjectId={this.state.subjectId} vatNumber={this.props.vatNumber}></MassiveDownload>
                <Export subjectId={this.state.subjectId} vatNumber={this.props.vatNumber}></Export>
            </ Page.Content>
        </SiteWrapper>);
    };
}

const mapStateToProps = state => {
    return {
        me: state.auth.me,
        business: state.auth.business,
        vatNumber: state.subjects.vatNumber
    };
};

export default connect(mapStateToProps)(SubjectDownloadPage);