import * as actionTypes from './actionTypes';
import api from '../../api';
import { createQueryString } from '../../shared/utility';

export const loadingStart = () => {
    return {
        type: actionTypes.LOADING_START
    };
};

export const setUsers = (users) => {
    return {
        type: actionTypes.SET_USERS,
        users: users
    };
};

export const setUsersEmpty = () => {
    return {
        type: actionTypes.FETCH_USERS_EMPTY
    };
};

export const fetchUsersFailed = () => {
    return {
        type: actionTypes.FETCH_USERS_FAILED
    };
};

export const tokenExpired = () => {
    return {
        type: actionTypes.TOKEN_EXPIRED
    }
}

export const loadUsers = (page, perPage, filters, include = [], order = {}, params = null) => {
    return dispatch => {
        dispatch(loadingStart());
        const queryString = createQueryString(page, perPage, filters, include, order, params);
        api.get('users' + queryString)
            .then(response => {
                switch (response.status) {
                    case 204:
                        dispatch(setUsersEmpty());
                        break;
                    default:
                        dispatch(setUsers(response.data));
                        dispatch(setTableStatus(page, perPage, filters, include, order));
                        break;
                }
            })
            .catch(error => {
                if (process.env.NODE_ENV === 'development') {
                    console.log(error.response);
                }
                if (error.response) {
                    if (error.response.status === 401) {
                        if (error.response.data.code === "107") {
                            dispatch(tokenExpired());
                        }
                    }
                }
                dispatch(fetchUsersFailed());
            });
    };
};

const setTableStatus = (page, perPage, filters, include, order) => {
    return {
        type: actionTypes.SET_USERS_TABLE_STATUS,
        page: page,
        perPage: perPage,
        filters: filters,
        include: include,
        order: order
    };
}