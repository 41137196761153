import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Page,
    Table,
    Grid,
    Card,
    Button,
} from 'tabler-react';
import Modal from 'react-modal';

import SiteWrapper from "../hoc/SiteWrapper/SiteWrapper";
import * as actions from '../store/actions/index';
import Pagination from '../components/UI/Table/Pagination';
import TableMeta from '../components/UI/Table/TableMeta';
import PageLength from '../components/UI/Table/PageLength';
import ColHeader from '../components/UI/Table/ColHeader';
import Filter from '../components/Filter/Log';
import LogType from '../components/UI/LogType';
import { createOrderObject } from '../shared/utility';

Modal.setAppElement('#root')

class LogsPage extends Component {
    constructor() {
        super();
        this.state = {
            perPage: 15,
            page: 1,
            filters: {},
            include: [],
            order: {
                by: 'id',
                direction: 'DESC',
            },
            modalIsOpen: false,
            properties: null,
        }
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount() {
        if (this.props.page !== null) {
            this.props.onLoadLogs(this.props.page, this.props.perPage, this.props.filters, this.props.include, this.props.order);
        } else {
            this.props.onLoadLogs(this.state.page, this.state.perPage, this.state.filters, this.state.include, this.state.order);
        }
    }

    openModal() {
        this.setState({ modalIsOpen: true });
    }

    closeModal() {
        this.setState({ modalIsOpen: false });
    }

    handlePageChange(page) {
        this.setState({
            ...this.state,
            page: page
        })
        this.props.onLoadLogs(page, this.state.perPage, this.state.filters, this.state.include, this.state.order);
    }

    handlePerPageChange(perPage) {
        this.setState({
            ...this.state,
            perPage: perPage
        })
        this.props.onLoadLogs(this.state.page, perPage, this.state.filters, this.state.include, this.state.order);
    }

    handleFilterSubmit(values) {
        this.setState({
            ...this.state,
            filters: values
        })
        this.props.onLoadLogs(this.state.page, this.state.perPage, values, this.state.include, this.state.order);
    }

    handleViewClick(properties) {
        this.setState({
            ...this.state,
            properties: properties
        });
        this.openModal();
    }

    handleOrder(column) {
        const order = createOrderObject(this.state.order, column);
        this.setState({
            ...this.state,
            order: order
        });
        this.props.onLoadLogs(this.state.page, this.state.perPage, this.state.filters, this.state.include, order);
    }

    render() {
        let footer = null;
        let tableHeader = null;
        let rows = (
            <Table.Row>
                <Table.Col colSpan={7}>Nessun dato presente</Table.Col>
            </Table.Row>
        );

        if (this.props.loading === false && this.props.error === false && this.props.empty === false) {

            rows = this.props.logs.map(log => {
                let actions = [];
                if (log.attributes.properties) {
                    actions = (<Button key={"info_" + log.id} color="primary" icon="database" outline onClick={() => this.handleViewClick(log.attributes.properties)} />)
                }
                return <Table.Row key={log.id}>
                    <Table.Col>
                        <LogType>
                            {log.attributes.name}
                        </LogType>
                    </Table.Col>
                    <Table.Col>{log.attributes.description}</Table.Col>
                    <Table.Col>{log.attributes.subject_id}</Table.Col>
                    <Table.Col>{log.attributes.subject_type}</Table.Col>
                    <Table.Col>{log.attributes.causer}</Table.Col>
                    <Table.Col>{log.attributes.created_at}</Table.Col>
                    <Table.Col>{actions}</Table.Col>
                </Table.Row>;
            });
            footer = (
                <div>
                    <TableMeta
                        from={this.props.meta.from}
                        to={this.props.meta.to}
                        total={this.props.meta.total}
                    />
                    <Pagination links={this.props.links} onPageChange={this.handlePageChange.bind(this)} />
                </div>);
        }

        tableHeader = <Table.Row>
            <Table.ColHeader></Table.ColHeader>
            <Table.ColHeader>Log</Table.ColHeader>
            <Table.ColHeader>Id Oggetto</Table.ColHeader>
            <Table.ColHeader>Tipo oggetto</Table.ColHeader>
            <Table.ColHeader>Utente</Table.ColHeader>
            <Table.ColHeader>Data</Table.ColHeader>
            <Table.ColHeader>Azioni</Table.ColHeader>
        </Table.Row>;
        if (this.props.order) {
            tableHeader = <Table.Row>
                <Table.ColHeader></Table.ColHeader>
                <ColHeader name="log_name" onOrder={this.handleOrder.bind(this)} current={this.props.order}>Log</ColHeader>
                <ColHeader name="subject_id" onOrder={this.handleOrder.bind(this)} current={this.props.order}>Id Oggetto</ColHeader>
                <ColHeader name="subject_type" onOrder={this.handleOrder.bind(this)} current={this.props.order}>Tipo oggetto</ColHeader>
                <Table.ColHeader>Utente</Table.ColHeader>
                <ColHeader name="created_at" onOrder={this.handleOrder.bind(this)} current={this.props.order}>Data</ColHeader>
                <Table.ColHeader>Azioni</Table.ColHeader>
            </Table.Row>;
        }
        return (
            <SiteWrapper me={this.props.me} business={this.props.business}>
                <Page.Content title="Logs">
                    <Grid.Row>
                        <Grid.Col md={12} xl={12}>
                            <Filter onSubmit={this.handleFilterSubmit.bind(this)} current={this.props.filters}/>
                            <Card>
                                <Card.Header>
                                    <PageLength onChange={this.handlePerPageChange.bind(this)} current={this.props.perPage}/>
                                </Card.Header>
                                <Card.Body>
                                    <Table>
                                        <Table.Header>
                                            {tableHeader}
                                        </Table.Header>
                                        <Table.Body>
                                            {rows}
                                        </Table.Body>
                                    </Table>
                                </Card.Body>
                                <Card.Footer>
                                    {footer}
                                </Card.Footer>
                            </Card>
                        </Grid.Col>
                    </Grid.Row>
                </Page.Content>
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <h2 ref={subtitle => this.subtitle = subtitle}>Dati</h2>
                    <pre>
                        {JSON.stringify(this.state.properties, null, 4)}
                    </pre>
                    <Button onClick={this.closeModal}>Chiudi</Button>
                </Modal>
            </SiteWrapper>
        );
    };
}

const mapStateToProps = state => {
    return {
        logs: state.logs.logs,
        meta: state.logs.meta,
        links: state.logs.links,
        error: state.logs.error,
        loading: state.logs.loading,
        empty: state.logs.empty,
        me: state.auth.me,
        business: state.auth.business,
        page: state.logs.page,
        perPage: state.logs.perPage,
        filters: state.logs.filters,
        include: state.logs.include,
        order: state.logs.order,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onLoadLogs: (page, perPage, filters, include, order) => dispatch(actions.loadLogs(page, perPage, filters, include, order)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LogsPage);

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};