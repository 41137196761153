import React from 'react';

import { Icon, Text } from 'tabler-react';

import Name from '../../shared/enums/LogName';

const logType = (props) => {
    let icon = null;
    let color = null;
    switch (props.children) {
        case (Name.LOGIN):
            icon = <Icon prefix="fa" name="sign-in" />;
            color = "purple";
            break;
        case (Name.DEFAULT):
            icon = <Icon prefix="fa" name="info"/>;
            color = "blue";
            break;
        case (Name.EXCEPTION):
            icon = <Icon  prefix="fa" name="exclamation-triangle" />;
            color = "red";
            break;
        case (Name.SYSTEM):
            icon = <Icon prefix="fa" name="desktop"/>
            color = "indigo";
            break;
        default:
            icon = <Icon prefix="fa" name="ghost"/>;
            color = "yellow";
    }
    return (
        <Text color={color}>
            {icon}
        </Text>
    );
}

export default logType;