import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Page,
    Form,
    Alert,
    Grid,
    Button,
    Card,
    Table,
} from 'tabler-react';
import { toast } from 'react-toastify';
import { Formik } from "formik";

import SiteWrapper from "../hoc/SiteWrapper/SiteWrapper";
import api from '../api'
import { parsingApiError } from '../shared/utility';
import { checkVatNumber } from '../shared/utility';
import ApiErrorCode from '../shared/enums/ApiErrorCode';
import * as actions from '../store/actions/index';

class FollowSubjectPage extends Component {
    constructor() {
        super();
        this.state = {
            initialValues: {
                vat_number: "",
            },
            perPage: 15,
            page: 1,
            filters: {
                accept_date: "is_null"
            },
            include: ["subject"],
            order: {
                by: 'request_date',
                direction: 'DESC',
            },
            params: "&all"
        }
    }

    componentDidMount() {
        if (this.props.userableId) {
            if (this.props.page !== null) {
                this.props.onLoadRequests(this.props.userableId, this.props.page, this.props.perPage, this.props.filters, this.props.include, this.props.order, this.state.params);
            } else {
                this.props.onLoadRequests(this.props.userableId, this.state.page, this.state.perPage, this.state.filters, this.state.include, this.state.order, this.state.params);
            }
        }
    }


    render() {
        let content = (<Formik
            initialValues={this.state.initialValues}
            validate={values => {
                let errors = {};
                if (!values.vat_number) {
                    errors.vat_number = "Campo obbligatorio";
                } else {
                    if (!checkVatNumber(values.vat_number)) {
                        errors.vat_number = "Parita iva non valida";
                    }
                }
                return errors;
            }}
            onSubmit={(
                values,
                { setSubmitting, setErrors /* setValues and other goodies */ }
            ) => {
                setTimeout(() => {
                    api.post('accountants/subjects/' + values.vat_number + '/follow-request', null)
                        .then(response => {
                            toast.success("Richiesta inviata");
                            setSubmitting(false);
                        })
                        .catch(errors => {
                            toast.error("Errore invio richiesta");
                            if (errors.response) {
                                if (errors.response.status === 400) {
                                    if (errors.response.data.code === ApiErrorCode.SUBJECT_NOT_FOUND) {
                                        setErrors({
                                            vat_number: errors.response.data.detail
                                        });
                                    }
                                }
                                if (errors.response.status === 422) {
                                    setErrors(parsingApiError(errors.response.data.source));
                                }
                            }
                            setSubmitting(false);
                        });
                }, 400);
            }}
            render={({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
            }) => (
                    <Form onSubmit={handleSubmit}>
                        <Grid>
                            <Grid.Row>
                                <Grid.Col>
                                    <Alert type="primary" icon="info">
                                        Inserisci la partita iva del soggetto che vuoi seguire. La richiesta dovrà essere accettata dal soggetto, riceverai una mail di conferma ad accettazione avvenuta.
                                    </Alert>
                                </Grid.Col>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Col>
                                    <Form.Input
                                        name='vat_number'
                                        label='Partita iva'
                                        placeholder='Partita iva'
                                        type="text"
                                        value={values.vat_number}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={(errors.vat_number && touched.vat_number) ? "invalid" : null}
                                        feedback={(errors.vat_number && touched.vat_number) ? errors.vat_number : null}
                                    />
                                </Grid.Col>
                            </Grid.Row>
                        </Grid>
                        <Button type='submit' color="primary" disabled={isSubmitting}>Richiedi</Button>
                    </Form>
                )}
        />);
        let header = (
            <Table.Row>
                <Table.ColHeader name="subjects.business_name">Soggetto</Table.ColHeader>
                <Table.ColHeader name="request_date">Data richiesta</Table.ColHeader>
            </Table.Row>
        );
        let rows = (
            <Table.Row>
                <Table.Col colSpan={2}>Nessun dato presente</Table.Col>
            </Table.Row>
        );
        if (this.props.loading === false && this.props.error === false && this.props.empty === false) {
            rows = this.props.requests.map(request => {
                return <Table.Row key={request.id}>
                    <Table.Col>{request.relationships.subject.data.attributes.business_name}</Table.Col>
                    <Table.Col>{request.attributes.request_date}</Table.Col>
                </Table.Row>;
            });
        }
        return (
            <SiteWrapper me={this.props.me} business={this.props.business}>
                <Page.Content title="Richiedi accesso a nuovo soggetto">
                    <Card>
                        <Card.Body>
                            {content}
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Header>
                            <Card.Title>Richieste pendenti <strong>{this.props.meta ? "(" + this.props.meta.total + ")" : ""}</strong></Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Table>
                                <Table.Header>
                                    {header}
                                </Table.Header>
                                <Table.Body>
                                    {rows}
                                </Table.Body>
                            </Table>
                        </Card.Body>
                    </Card>
                </Page.Content>
            </SiteWrapper>
        );
    };
}

const mapStateToProps = state => {
    return {
        me: state.auth.me,
        business: state.auth.business,
        userableId: state.auth.userableId,
        meta: state.followingRequests.meta,
        links: state.followingRequests.links,
        error: state.followingRequests.error,
        loading: state.followingRequests.loading,
        empty: state.followingRequests.empty,
        page: state.followingRequests.page,
        perPage: state.followingRequests.perPage,
        filters: state.followingRequests.filters,
        include: state.followingRequests.include,
        order: state.followingRequests.order,
        requests: state.followingRequests.requests,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onLoadRequests: (uuid, page, perPage, filters, include, order, params) => dispatch(actions.loadFollowRequests(uuid, page, perPage, filters, include, order, params)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FollowSubjectPage);