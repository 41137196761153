import React, { Component } from 'react';
import {
    Form,
    Card,
    Button,
    Grid
} from 'tabler-react';
import 'react-toastify/dist/ReactToastify.min.css';
import { Formik } from "formik";
import { toast } from 'react-toastify';
import * as moment from 'moment';

import api from '../../api';

class MassiveDownload extends Component {

    state = {
        initialValues: {
            from: null,
            to: null,
            type: null,
        }
    };

    render() {
        let fromDate = null;
        let toDate = null;
        let content = (<Formik
            initialValues={this.state.initialValues}
            validate={values => {
                let errors = {};
                if (!values.from) {
                    errors.from = "Campo obbligatorio";
                } else {
                    fromDate = moment.utc(values.from, "DD/MM/YYYY");
                    if (!fromDate.isValid()) {
                        errors.from = "Campo non valido";
                    }
                }
                if (!values.to) {
                    errors.to = "Campo obbligatorio";
                }
                else {
                    toDate = moment.utc(values.to, "DD/MM/YYYY");
                    if (!toDate.isValid()) {
                        errors.to = "Campo non valido";
                    }
                }
                if (fromDate && toDate) {
                    if (toDate.diff(fromDate) < 0) {
                        errors.from = "Il campo Da deve essere predecente al campo A";
                    }
                    const yesterday = moment().subtract(1, 'days');
                    if (yesterday.diff(toDate) < 0) {
                        errors.to = "Il campo A non può essere maggiore o uguale alla data odierna";
                    }
                    if (toDate.diff(fromDate, "days") > 60) {
                        errors.to = "Intervallo non consentito, è possibile selezionare un massimo di 60 giorni.";
                    }
                }
                if (!values.type) {
                    errors.type = "Campo obbligatorio";
                }
                return errors;
            }}
            onSubmit={(
                values,
                { setSubmitting, setErrors /* setValues and other goodies */ }
            ) => {
                setTimeout(() => {
                    let toastId = toast.info("Download in corso");
                    api.get('documents/' + values.type + '/subject/' + this.props.subjectId + '/download?filter[from]=' + fromDate.unix() + '&filter[to]=' + toDate.unix(), {
                        responseType: 'blob',
                        headers: {
                            'Accept': process.env.REACT_APP_API_ACCEPT + '+zip'
                        }
                    }).then(response => {
                        toast.dismiss(toastId);
                        if (response.status === 204) {
                            toast.info("Nessun file da scaricare");
                            setSubmitting(false);
                            return;
                        }
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', this.props.vatNumber + "_" + fromDate.format("YYYYMMDD") + "_" + toDate.format("YYYYMMDD") + "_" + values.type + ".zip");
                        document.body.appendChild(link);
                        link.click();
                        setSubmitting(false);
                    }).catch(errors => {
                        toast.dismiss(toastId);
                        toast.error("Errore download");
                        setSubmitting(false);
                    });
                }, 400);
            }}
            render={({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
            }) => (
                <Card title="Download archivio zip" isCollapsible>
                    <Card.Body>
                        <Form onSubmit={handleSubmit}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Col xs={12} sm={12} md={6} lg={4}>
                                        <Form.Group label="Da">
                                            <Form.MaskedInput
                                                name="from"
                                                placeholder="gg/mm/aaaa"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                invalid={(errors.from && touched.from) ? "invalid" : null}
                                                feedback={(errors.from && touched.from) ? errors.from : null}
                                                mask={[
                                                    /\d/,
                                                    /\d/,
                                                    "/",
                                                    /\d/,
                                                    /\d/,
                                                    "/",
                                                    /\d/,
                                                    /\d/,
                                                    /\d/,
                                                    /\d/,
                                                ]}
                                            />
                                        </Form.Group>
                                    </Grid.Col>
                                    <Grid.Col xs={12} sm={12} md={6} lg={4}>
                                        <Form.Group label="A">
                                            <Form.MaskedInput
                                                name="to"
                                                placeholder="gg/mm/aaaa"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                invalid={(errors.to && touched.to) ? "invalid" : null}
                                                feedback={(errors.to && touched.to) ? errors.to : null}
                                                mask={[
                                                    /\d/,
                                                    /\d/,
                                                    "/",
                                                    /\d/,
                                                    /\d/,
                                                    "/",
                                                    /\d/,
                                                    /\d/,
                                                    /\d/,
                                                    /\d/,
                                                ]}
                                            />
                                        </Form.Group>
                                    </Grid.Col>
                                    <Grid.Col xs={12} sm={12} md={6} lg={4}>
                                        <Form.Group label="Tipo">
                                            <Form.Select
                                                name='type'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                invalid={(errors.type && touched.type) ? "invalid" : null}
                                                feedback={(errors.type && touched.type) ? errors.type : null}
                                            >
                                                <option>Seleziona tipo</option>
                                                <option value="active">Attive</option>
                                                <option value="passive">Passive</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Grid.Col>
                                </Grid.Row>
                            </Grid>
                            <Button type='submit' color="primary" disabled={isSubmitting}>Scarica</Button>
                        </Form>
                    </Card.Body>
                </Card>
                )}
        />);

        return (<Card title={this.props.title}>
                    <Card.Body>
                        {content}
                    </Card.Body>
                </Card>);
    };
}

export default MassiveDownload;