import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Page,
    Dimmer,
    Card,
    Grid,
    List,
    Table,
    Button,
    Tag
} from 'tabler-react';
import 'react-toastify/dist/ReactToastify.min.css';
import { toast } from 'react-toastify';

import SiteWrapper from "../hoc/SiteWrapper/SiteWrapper";
import * as actions from '../store/actions/index';
import DocumentStatus from '../components/UI/DocumentStatus';
import NotificationType from '../shared/enums/NotificationType';
import DocumentType from '../shared/enums/DocumentType';
import api from '../api';
import Role from "../shared/enums/Role";

class ActiveDocumentPage extends Component {
    state = {
        logs: {
            data: null,
            loading: true,
            error: false
        }
    }
    componentDidMount() {
        this.props.setDocumentType('active');

        this.props.onLoadDocument(this.props.match.params.id);

        api.get('documents/active/' + this.props.match.params.id + '/logs')
            .then(response => {
                this.setState(
                    {    logs: {
                            data: response.status === 204 ? [] : response.data.data,
                            loading: false,
                            error: false
                        }
                    }
                )
            })
            .catch(error => {
                this.setState(
                    {
                        logs: {
                            data: null,
                            loading: false,
                            error: true
                        }
                    }
                )
            });
    }

    handleViewClick(id, filename) {
        let toastId = toast.info("Download in corso");
        api.get('documents/active/' + this.props.match.params.id + '/notifications/' + id + '/download', {
            responseType: 'blob',
            headers: {
                'Accept': process.env.REACT_APP_API_ACCEPT + '+pdf'
            }
        }).then(response => {
            //Create a Blob from the PDF Stream
            const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
            //IE & Edge
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                const fileName = filename + ".pdf";
                window.navigator.msSaveOrOpenBlob(file, fileName);
            } else {
                //Build a URL from the file
                const fileURL = URL.createObjectURL(file);
                //Open the URL on new Window
                window.open(fileURL);
            }
            toast.dismiss(toastId);
        }).catch(error => {
            toast.error("Errore nello scaricamento del file");
            toast.dismiss(toastId);
        });
    }

    handleDownloadClick(id, fileName, type) {
        let toastId = toast.info("Download in corso");
        api.get('documents/active/' + this.props.match.params.id + '/' + type + '/' + id + '/download',{
            responseType: 'blob'
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            toast.dismiss(toastId);
        }).catch(error => {
            toast.error("Errore nello scaricamento del file");
            toast.dismiss(toastId);
        });
    }

    render() {
        const title = "Dettaglio documento " + this.props.match.params.id;
        let logsHeader;
        let dataBody = null;
        let cessionarioCard = null;
        let logsCard = null;
        let notificationsBody = (<Table.Row>
            <Table.Col colSpan={7}>Nessun dato presente</Table.Col>
        </Table.Row>);
        let logsBody = (<Table.Row>
            <Table.Col colSpan={4}>Nessun dato presente</Table.Col>
        </Table.Row>);
        let attachmentsBody = (<Table.Row>
            <Table.Col colSpan={5}>Nessun allegato presente</Table.Col>
        </Table.Row>);
        if (this.props.loading === false && this.props.error === false) {
            dataBody = (<List unstyled>
                <List.Item><strong>Identificatico:</strong> {this.props.document.data.id}</List.Item>
                <List.Item><strong>Numero:</strong> {this.props.document.data.attributes.number}</List.Item>
                <List.Item><strong>Anno:</strong> {this.props.document.data.attributes.year}</List.Item>
                <List.Item><strong>Data:</strong> {this.props.document.data.attributes.date}</List.Item>
                <List.Item><strong>Stato:</strong>&nbsp;
                    <DocumentStatus description={this.props.status[this.props.document.data.attributes.status].short_description}>
                        {this.props.document.data.attributes.status}
                    </DocumentStatus>
                </List.Item>
                <List.Item><strong>Nome file:</strong> {this.props.document.data.attributes.file_name}</List.Item>
                <List.Item><strong>Formato:</strong> {this.props.document.data.attributes.format}</List.Item>
                <List.Item><strong>Tipo:</strong> {DocumentType[this.props.document.data.attributes.type]}</List.Item>
                <List.Item><strong>Destinatario:</strong> {this.props.document.data.attributes.recipient}</List.Item>
                <List.Item><strong>Totale (tasse escluse):</strong> {this.props.document.data.attributes.amount.toFixed(2)}</List.Item>
                <List.Item><strong>Totale:</strong> {this.props.document.data.attributes.amount_with_tax.toFixed(2)}</List.Item>
                <List.Item><strong>Identificativo SDI:</strong> {this.props.document.data.attributes.id_sdi}</List.Item>
                <List.Item><strong>Data ricezione SDI:</strong> {this.props.document.data.attributes.reception_date_sdi}</List.Item>
                <List.Item><strong>Numero:</strong> {this.props.document.data.attributes.number}</List.Item>
                <List.Item><strong>Data creazione:</strong> {this.props.document.data.attributes.create_at}</List.Item>
                <List.Item><strong>Data ultimo aggiornamento:</strong> {this.props.document.data.attributes.updated_at}</List.Item>
            </List>);
            const subject = this.props.document.data.relationships.subject;
            let subjectDates = (<p><List.Item><strong>Data creazione:</strong> {subject.data.attributes.created_at}</List.Item>
            <List.Item><strong>Data ultimo aggiornamento:</strong> {subject.data.attributes.updated_at}</List.Item></p>);
            if (this.props.me.roles[0] === Role.COMMERCIALISTA) {
                subjectDates = null;
            }
            if (this.props.me.roles[0] !== Role.USER) {
                cessionarioCard = (
                    <Card title="Cessionario" statusColor="teal">
                        <Card.Body>
                            <List unstyled>
                                <List.Item><strong>Ragione sociale:</strong> {subject.data.attributes.business_name}</List.Item>
                                <List.Item><strong>Partita IVA:</strong> {subject.data.attributes.vat_number}</List.Item>
                                <List.Item><strong>Indirizzo:</strong> {subject.data.attributes.address}</List.Item>
                                <List.Item><strong>Email:</strong> {subject.data.attributes.email}</List.Item>
                                <List.Item><strong>PEC:</strong> {subject.data.attributes.pec}</List.Item>
                                <List.Item><strong>Telefono:</strong> {subject.data.attributes.phone}</List.Item>
                                <List.Item><strong>Cellulare:</strong> {subject.data.attributes.mobile_phone}</List.Item>
                                {subjectDates}
                            </List>
                        </Card.Body>
                    </Card>
                );
            }
            if (this.props.document.data.relationships.notifications) {
                notificationsBody = this.props.document.data.relationships.notifications.data.map(notification => {
                    return <Table.Row key={notification.id}>
                        <Table.Col>{notification.attributes.message_id}</Table.Col>
                        <Table.Col><Tag>{NotificationType[notification.attributes.type]}</Tag></Table.Col>
                        <Table.Col>{notification.attributes.file_name}</Table.Col>
                        <Table.Col>{notification.attributes.description}</Table.Col>
                        <Table.Col>{notification.attributes.note}</Table.Col>
                        <Table.Col>{notification.attributes.created_at}</Table.Col>
                        <Table.Col>
                            <Button.List>
                                <Button color="primary" icon="eye" outline onClick={() => this.handleViewClick(notification.id, notification.attributes.file_name)} />
                                <Button color="primary" icon="download" outline onClick={() => this.handleDownloadClick(notification.id, notification.attributes.file_name, 'notifications')} />
                            </Button.List>
                        </Table.Col>
                    </Table.Row>;
                });
            }
            if (this.props.document.data.relationships.attachments) {
                attachmentsBody = this.props.document.data.relationships.attachments.data.map(attachment => {
                    return <Table.Row key={attachment.id}>
                        <Table.Col>{attachment.attributes.file_name}</Table.Col>
                        <Table.Col>{attachment.attributes.description}</Table.Col>
                        <Table.Col>{attachment.attributes.format}</Table.Col>
                        <Table.Col>{attachment.attributes.compression}</Table.Col>
                        <Table.Col>{attachment.attributes.created_at}</Table.Col>
                        <Table.Col>
                            <Button.List>
                                <Button color="primary" icon="download" outline onClick={() => this.handleDownloadClick(attachment.id, attachment.attributes.file_name, 'attachments')} />
                            </Button.List>
                        </Table.Col>
                    </Table.Row>;
                });
            }
        }

        if (this.props.me.roles[0] !== Role.USER && this.props.me.roles[0] !== Role.COMMERCIALISTA) {
            if (this.state.logs.loading === false && this.state.logs.error === false) {
                logsBody = this.state.logs.data.map(log => {
                    if (this.props.me.roles[0] === Role.COMMERCIALISTA) {
                        return <Table.Row key={log.id}>
                            <Table.Col>{log.attributes.description}</Table.Col>
                            <Table.Col>{log.attributes.causer}</Table.Col>
                            <Table.Col>{log.attributes.created_at}</Table.Col>
                        </Table.Row>;
                    }
                    return <Table.Row key={log.id}>
                        <Table.Col>{log.attributes.description}</Table.Col>
                        <Table.Col>{log.attributes.causer}</Table.Col>
                        <Table.Col>{JSON.stringify(log.attributes.properties)}</Table.Col>
                        <Table.Col>{log.attributes.created_at}</Table.Col>
                    </Table.Row>;
                });
            }
            logsHeader = <Table.Row>
                <Table.ColHeader>Descrizione</Table.ColHeader>
                <Table.ColHeader>Autore</Table.ColHeader>
                <Table.ColHeader>Dati</Table.ColHeader>
                <Table.ColHeader>Data</Table.ColHeader>
            </Table.Row>;
            if (this.props.me.roles[0] === Role.COMMERCIALISTA) {
                logsHeader = <Table.Row>
                    <Table.ColHeader>Descrizione</Table.ColHeader>
                    <Table.ColHeader>Autore</Table.ColHeader>
                    <Table.ColHeader>Data</Table.ColHeader>
                </Table.Row>
            }
            logsCard = (
                <Card title="Logs" statusColor="purple">
                    <Card.Body>
                        <Dimmer active={this.state.logs.loading ? true : false} loader>
                            <Table>
                                <Table.Header>
                                    {logsHeader}
                                </Table.Header>
                                <Table.Body>
                                    {logsBody}
                                </Table.Body>
                            </Table>
                        </Dimmer>
                    </Card.Body>
                </Card>
            );
        }
        return (<SiteWrapper me={this.props.me} business={this.props.business}>
            <Page.Content title={title} >
                <Grid.Row cards>
                    <Grid.Col xs={12} sm={12} md={3} lg={4}>
                        <Card statusColor="blue">
                            <Card.Header>
                                <Card.Title>Dati</Card.Title>
                                <Card.Options>
                                    <Button color="info" outline icon="eye" onClick={() => this.props.onViewClick(this.props.document.data.id, this.props.document.data.attributes.file_name)}>
                                    </Button>
                                    &nbsp;
                                    <Button color="primary" outline icon="download" onClick={() => this.props.onDownloadClick(this.props.document.data.id, this.props.document.data.attributes.file_name)}>
                                    </Button>
                                </Card.Options>
                            </Card.Header>
                            <Card.Body>
                                <Dimmer active={this.props.loading ? true : false} loader>
                                    {dataBody}
                                </Dimmer>
                            </Card.Body>
                        </Card>
                        {cessionarioCard}
                    </Grid.Col>
                    <Grid.Col xs={12} sm={12} md={9} lg={8}>
                        <Card title="Notifiche" statusColor="green">
                            <Card.Body>
                                <Dimmer active={this.props.loading ? true : false} loader>
                                    <Table>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.ColHeader>Id</Table.ColHeader>
                                                <Table.ColHeader>Tipo</Table.ColHeader>
                                                <Table.ColHeader>Nome file</Table.ColHeader>
                                                <Table.ColHeader>Descrizione</Table.ColHeader>
                                                <Table.ColHeader>Note</Table.ColHeader>
                                                <Table.ColHeader>Data creazione</Table.ColHeader>
                                                <Table.ColHeader>Data modifica</Table.ColHeader>
                                                <Table.ColHeader>Azioni</Table.ColHeader>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {notificationsBody}
                                        </Table.Body>
                                    </Table>
                                </Dimmer>
                            </Card.Body>
                        </Card>
                        <Card title="Allegati" statusColor="red">
                            <Card.Body>
                                <Dimmer active={this.props.loading ? true : false} loader>
                                    <Table>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.ColHeader>Nome file</Table.ColHeader>
                                                <Table.ColHeader>Descrizione</Table.ColHeader>
                                                <Table.ColHeader>Formato</Table.ColHeader>
                                                <Table.ColHeader>Compressione</Table.ColHeader>
                                                <Table.ColHeader>Data creazione</Table.ColHeader>
                                                <Table.ColHeader>Azioni</Table.ColHeader>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {attachmentsBody}
                                        </Table.Body>
                                    </Table>
                                </Dimmer>
                            </Card.Body>
                        </Card>
                        {logsCard}
                    </Grid.Col>
                </Grid.Row>
            </Page.Content>
        </SiteWrapper>);
    };
}

const mapStateToProps = state => {
    return {
        document: state.activeDocuments.document,
        error: state.activeDocuments.error,
        loading: state.activeDocuments.loadingSingle,
        me: state.auth.me,
        business: state.auth.business,
        status: state.documentStatus.status,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onLoadDocument: (id) => dispatch(actions.loadActiveDocument(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActiveDocumentPage);