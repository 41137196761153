import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Page,
    Dimmer,
    Form,
    Card,
    Grid,
    Button
} from 'tabler-react';
import { toast } from 'react-toastify';
import { Formik } from "formik";

import SiteWrapper from "../hoc/SiteWrapper/SiteWrapper";
import api from '../api'
import { parsingApiError } from '../shared/utility';

class ChangePasswordPage extends Component {

    constructor() {
        super();
        this.state = {
            initialValues: {
                old_password: "",
                new_password: "",
                new_password_confirmation: "",
            }
        }
    }


    render() {
        if (this.props.isAuthenticated) {
            let content = (<Formik
                initialValues={this.state.initialValues}
                validate={values => {
                    let errors = {};
                    if (!values.old_password) {
                        errors.old_password = "Campo obbligatorio";
                    }
                    if (!values.new_password) {
                        errors.new_password = "Campo obbligatorio";
                    }
                    if (!values.new_password_confirmation) {
                        errors.new_password_confirmation = "Campo obbligatorio";
                    }
                    if (values.new_password_confirmation !== values.new_password) {
                        errors.new_password = "Le password non coincidono";
                    }
                    if (values.new_password === values.old_password) {
                        errors.new_password = "Le nuova password deve essere diversa dalla precedente";
                    }
                    return errors;
                }}
                onSubmit={(
                    values,
                    { setSubmitting, setErrors /* setValues and other goodies */ }
                ) => {
                    setTimeout(() => {
                        const data = {
                            "old_password": values.old_password,
                            "new_password": values.new_password,
                            "new_password_confirmation": values.new_password_confirmation,
                        };
                        api.put('change-password', data)
                            .then(response => {
                                toast.success("Password aggiornata");
                                setSubmitting(false);
                            })
                            .catch(errors => {
                                toast.error("Errore aggiornamento");
                                if (errors.response.status === 409) {
                                    setErrors({
                                        old_password: "Password errata"
                                    });
                                }
                                if (errors.response.status === 422) {
                                    setErrors(parsingApiError(errors.response.data.source));
                                }
                                setSubmitting(false);
                            });
                    }, 400);
                }}
                render={({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                }) => (
                        <Form onSubmit={handleSubmit}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Col>
                                        <Form.Input
                                            name='old_password'
                                            label='Vecchia password'
                                            placeholder='Vecchia password'
                                            type="password"
                                            value={values.old_password}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            invalid={(errors.old_password && touched.old_password) ? "invalid" : null}
                                            feedback={(errors.old_password && touched.old_password) ? errors.old_password : null}
                                        />
                                    </Grid.Col>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Col>
                                        <Form.Input
                                            name='new_password'
                                            label='Nuova password'
                                            placeholder='Nuova password'
                                            type="password"
                                            value={values.new_password}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            invalid={(errors.new_password && touched.new_password) ? "invalid" : null}
                                            feedback={(errors.new_password && touched.new_password) ? errors.new_password : null}
                                        />
                                    </Grid.Col>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Col>
                                        <Form.Input
                                            name='new_password_confirmation'
                                            label='Conferma nuova password'
                                            placeholder='Conferma nuova password'
                                            type="password"
                                            value={values.new_password_confirmation}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            invalid={(errors.new_password_confirmation && touched.new_password_confirmation) ? "invalid" : null}
                                            feedback={(errors.new_password_confirmation && touched.new_password_confirmation) ? errors.new_password_confirmation : null}
                                        />
                                    </Grid.Col>
                                </Grid.Row>
                            </Grid>
                            <Button type='submit' color="primary" disabled={isSubmitting}>Modifica</Button>
                        </Form>
                    )}
            />);
            return (
                <SiteWrapper me={this.props.me} business={this.props.business}>
                    <Page.Content title="Modifica password">
                        <Form className="card">
                            <Card.Body>
                                {content}
                            </Card.Body>
                        </Form>
                    </Page.Content>
                </SiteWrapper>
            );
        }
        return (<SiteWrapper me={this.props.me} business={this.props.business}>
            <Dimmer loader></Dimmer>
        </SiteWrapper>);
    };
}

const mapStateToProps = state => {
    return {
        status: state.documentStatus.status,
        me: state.auth.me,
        business: state.auth.business,
        isAuthenticated: state.auth.token !== null
    };
};
export default connect(mapStateToProps, null)(ChangePasswordPage);