import axios from 'axios';

import * as actionTypes from './actionTypes';
import api from '../../api';

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};

export const authSuccess = (token, expiration, user) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token: token,
        expire_on: expiration,
        user: user
    };
};

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    };
};

export const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('expirationDate');
    return {
        type: actionTypes.AUTH_LOGOUT
    };
};

export const checkAuthTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime * 1000);
    };
};

export const auth = (email, password) => {
    return dispatch => {
        dispatch(authStart());
        const authData = {
            email: email,
            password: password
        };
        let url = process.env.REACT_APP_API_URL + '/api/login';
        let config = {
            headers: {
                "Content-Type": process.env.REACT_APP_API_ACCEPT + "+json",
            }
        }
        axios.post(url, authData, config)
            .then(response => {
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('expire_on', response.data.expire_on);
                dispatch(me(response.data.token, response.data.expire_on));
                dispatch(loadDocumentStatus(response.data.token));
            })
            .catch(errors => {
                dispatch(authFail(errors));
            });
    };
};

const me = (token, expiration) => {
    return dispatch => {
        api.get("me")
            .then(response => {
                dispatch(authSuccess(token, expiration, response.data.data));
            })
            .catch(errors => {
                if (errors.response) {
                    if (errors.response.status === 401) {
                        if (errors.response.data.code === "108") {
                            dispatch(authFail(errors));
                        }
                    }
                }
            });
    };
};

export const updateMe = () => {
    return dispatch => {
        api.get("me")
            .then(response => {
                dispatch(setMe(response.data.data));
            })
            .catch(errors => {
                console.log(errors);
                //dispatch(authFail(errors));
            });
    };
};

export const loadDocumentStatus = () => {
    return dispatch => {
        api.get('documents/status?all')
            .then(response => {
                dispatch(setDocumentStatus(response.data));
            })
            .catch(error => {
            });
    };
}

export const setAuthRedirectPath = (path) => {
    return {
        type: actionTypes.SET_AUTH_REDIRECT_PATH,
        path: path
    };
};

export const authCheckState = () => {
    return dispatch => {
        dispatch(authStart());
        const token = localStorage.getItem('token');
        const expire_on = localStorage.getItem('expire_on');
        if (!token) {
            dispatch(logout());
        } else {
            dispatch(me(token, expire_on));
            dispatch(loadDocumentStatus());
        }
    };
};


export const setDocumentStatus = (status) => {
    return {
        type: actionTypes.SET_DOCUMENT_STATUS,
        status: status
    };
};

export const setMe = (me) => {
    return {
        type: actionTypes.SET_ME,
        me: me
    };
};

export const resetPasswordStart = () => {
    return {
        type: actionTypes.RESET_PASSWORD_START
    };
};

export const resetPassword = (email) => {
    return dispatch => {
        const authData = {
            email: email
        };
        api.post('reset-password', authData)
        .then(response => {
            dispatch(resetPasswordSuccess());
        })
        .catch(error => {
            dispatch(resetPasswordFailed());
        });
    };
}

export const resetPasswordSuccess = () => {
    return {
        type: actionTypes.RESET_PASSWORD_SUCCESS
    };
};

export const resetPasswordFailed = () => {
    return {
        type: actionTypes.RESET_PASSWORD_FAILED
    };
};