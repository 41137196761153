import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Page,
    Table,
    Grid,
    Card,
    Button,
    Badge
} from 'tabler-react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import api from '../api';
import SiteWrapper from "../hoc/SiteWrapper/SiteWrapper";
import * as actions from '../store/actions/index';
import Pagination from '../components/UI/Table/Pagination';
import TableMeta from '../components/UI/Table/TableMeta';
import PageLength from '../components/UI/Table/PageLength';
import ColHeader from '../components/UI/Table/ColHeader';
import Filter from '../components/Filter/Subject';
import Ability from '../shared/enums/Ability';
import ResourceRemaining from '../components/UI/ResourceRemaining';
import { createOrderObject } from '../shared/utility';
import Role from "../shared/enums/Role";

class SubjectsPage extends Component {
    state = {
        perPage: 15,
        page: 1,
        filters: {},
        include: this.props.me.roles[0] === Role.COMMERCIALISTA ? [] : ['resource', 'accountant'],
        order: {
            by: 'business_name',
            direction: 'ASC',
        }
    }

    componentDidMount() {
        if (this.props.page !== null) {
            this.props.onLoadSubjects(this.props.page, this.props.perPage, this.props.filters, this.props.include, this.props.order);
        } else {
            this.props.onLoadSubjects(this.state.page, this.state.perPage, this.state.filters, this.state.include, this.state.order);
        }
    }

    handlePageChange(page) {
        this.setState({
            ...this.state,
            page: page
        })
        this.props.onLoadSubjects(page, this.props.perPage, this.props.filters, this.props.include, this.props.order);
    }

    handlePerPageChange(perPage) {
        this.setState({
            ...this.state,
            perPage: perPage
        })
        this.props.onLoadSubjects(this.props.page, perPage, this.props.filters, this.props.include, this.props.order);
    }

    handleFilterSubmit(values) {
        this.setState({
            ...this.state,
            filters: values
        })
        this.props.onLoadSubjects(1, this.props.perPage, values, this.props.include, this.props.order);
    }

    handleDeleteClick(id) {
        api.delete('subjects/' + id).then(response => {
            toast.success("Soggetto cancellato!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,

            });
            this.props.onLoadSubjects(this.props.page, this.props.perPage, this.props.filters, this.props.include, this.props.order);
        })
        .catch(error => {
            toast.success("Errore cancellazione!");
        });
    }

    handleStatusChangeClick(id) {
        api.put('subjects/' + id + '/changeStatus', {}).then(response => {
            toast.success("Operazione eseguita!");
            this.props.onLoadSubjects(this.props.page, this.props.perPage, this.props.filters, this.props.include, this.props.order);
        })
            .catch(error => {
                toast.error("Errore!");
            });
    }

    handleDownloadClick(index) {
        const subject = this.props.subjects[index];
        this.props.onClickSubject(subject.attributes.vat_number);
    }

    handleOrder(column) {
        const order = createOrderObject(this.state.order, column);
        this.setState({
            ...this.state,
            order: order
        });
        this.props.onLoadSubjects(this.state.page, this.state.perPage, this.state.filters, this.state.include, order);
    }

    render() {
        let add = null;
        let footer = null;
        let tableHeader = null;
        let rows = (
            <Table.Row>
                <Table.Col colSpan={8}>Nessun dato presente</Table.Col>
            </Table.Row>
        );
        if (this.props.me.abilities.indexOf(Ability.CREATE_SUBJECT) >= 0 || this.props.me.abilities.indexOf(Ability.ALL) >= 0) {
            add = (<Link to="/subjects/create"><Button color="primary">
                Nuovo Soggetto
                </Button></Link>);
        }
        if (this.props.me.abilities.indexOf(Ability.FOLLOWING_REQUEST) >= 0) {
            add = (<Link to="/subjects/following-request"><Button color="primary">
                Richiesta nuovo accesso
                </Button></Link>);
        }
        if (this.props.loading === false && this.props.error === false && this.props.empty === false) {
            let resource = null;
            rows = this.props.subjects.map((subject, index) => {
                let accountant = (<Table.Col>-</Table.Col>);
                let actions = [];
                const edit = (
                    <Link key={"edit_" + subject.id} to={"/subjects/" + subject.id + "/edit"}>
                        <Button color="secondary" icon="edit" outline/>
                    </Link>
                );
                const del = (<Button key={"del_" + subject.id} color="danger" icon="user-minus" outline onClick={() => this.handleDeleteClick(subject.id)} />);
                const info = (
                    <Link key={"info_" + subject.id} to={"/subjects/" + subject.id}>
                        <Button color="primary" icon="info" outline />
                    </Link>
                );
                let disable = (<Button key={"disable_" + subject.id} color="info" icon="user-x" outline onClick={() => this.handleStatusChangeClick(subject.id)} />);
                if (subject.attributes.disabled === 1) {
                    disable = (<Button key={"disable_" + subject.id} color="info" icon="user-check" outline onClick={() => this.handleStatusChangeClick(subject.id)} />);
                }
                if (this.props.me.abilities.indexOf(Ability.VIEW_SUBJECT) >= 0) {
                    actions.push(info);
                }
                if (this.props.me.abilities.indexOf(Ability.EDIT_SUBJECT) >= 0) {
                    actions.push(edit);
                }
                if (this.props.me.abilities.indexOf(Ability.DELETE_SUBJECT) >= 0) {
                    actions.push(del);
                }
                if (this.props.me.abilities.indexOf(Ability.DISABLE_SUBJECT) >= 0) {
                    actions.push(disable);
                }
                if (this.props.me.abilities.indexOf(Ability.ALL) >= 0) {
                    actions.push(info);
                    actions.push(edit);
                    actions.push(disable);
                    actions.push(del);
                }

                if (this.props.me.roles[0] === Role.COMMERCIALISTA) {
                    actions.push(
                        <Link key={"download_" + subject.id} to={"/subjects/" + subject.id + "/download"}>
                            <Button color="primary" icon="download" outline onClick={() => this.handleDownloadClick(index)} />
                        </Link>
                    );
                }

                if (subject.relationships.resources) {
                    resource = (<Table.Col>
                        <ResourceRemaining
                            amount={subject.relationships.resources.data.attributes.remaining}
                            total={subject.relationships.resources.data.attributes.total}
                            from={subject.relationships.resources.data.attributes.last_purchase_date}>
                            {subject.relationships.resources.data.attributes.remaining}
                        </ResourceRemaining>
                    </Table.Col>);
                }
                if (subject.relationships.accountant) {
                    accountant = (<Table.Col>
                        <Link to={"/accountants/" + subject.relationships.accountant.data.id}>
                                {subject.relationships.accountant.data.attributes.business_name }
                            </Link>
                        </Table.Col>
                    );
                }

                if (this.props.me.roles[0] === Role.COMMERCIALISTA) {
                    return <Table.Row key={subject.id}>
                        <Table.Col>{subject.attributes.business_name}</Table.Col>
                        <Table.Col>{subject.attributes.vat_number}</Table.Col>
                        <Table.Col>{subject.attributes.email}</Table.Col>
                        <Table.Col>{subject.attributes.phone}</Table.Col>
                        <Table.Col><Button.List>{actions}</Button.List></Table.Col>
                    </Table.Row>;
                }
                let status = <Badge color="info" className="mr-1">Attivo</Badge>;
                if (subject.attributes.disabled === 1) {
                    status = <Badge color="danger" className="mr-1">Disattivo</Badge>
                }
                return <Table.Row key={subject.id}>
                    <Table.Col>{subject.attributes.business_name}</Table.Col>
                    <Table.Col>{subject.attributes.vat_number}</Table.Col>
                    <Table.Col>{subject.attributes.email}</Table.Col>
                    <Table.Col>{subject.attributes.phone}</Table.Col>
                    {resource}
                    {accountant}
                    <Table.Col>{status}</Table.Col>
                    <Table.Col>{subject.attributes.created_at}</Table.Col>
                    <Table.Col>{subject.attributes.updated_at}</Table.Col>
                    <Table.Col><Button.List>{actions}</Button.List></Table.Col>
                </Table.Row>;
            });
            footer = (
                <div>
                    <TableMeta
                        from={this.props.meta.from}
                        to={this.props.meta.to}
                        total={this.props.meta.total}
                    />
                    <Pagination links={this.props.links} onPageChange={this.handlePageChange.bind(this)}/>
                </div>);
        }

        tableHeader = <Table.Row>
            <Table.ColHeader>Ragione sociale</Table.ColHeader>
            <Table.ColHeader>Partita iva</Table.ColHeader>
            <Table.ColHeader>Email</Table.ColHeader>
            <Table.ColHeader>Telefono</Table.ColHeader>
            <Table.ColHeader>Doucmenti rimasti</Table.ColHeader>
            <Table.ColHeader>Commercialista</Table.ColHeader>
            <Table.ColHeader>Stato</Table.ColHeader>
            <Table.ColHeader>Data creazione</Table.ColHeader>
            <Table.ColHeader>Data modifica</Table.ColHeader>
            <Table.ColHeader>Azioni</Table.ColHeader>
        </Table.Row>;
        if (this.props.me.roles[0] === Role.COMMERCIALISTA) {
            tableHeader = <Table.Row>
                <Table.ColHeader>Ragione sociale</Table.ColHeader>
                <Table.ColHeader>Partita iva</Table.ColHeader>
                <Table.ColHeader>Email</Table.ColHeader>
                <Table.ColHeader>Telefono</Table.ColHeader>
                <Table.ColHeader>Azioni</Table.ColHeader>
            </Table.Row>
        }
        if (this.props.order) {
            tableHeader = <Table.Row>
                <ColHeader name="business_name" onOrder={this.handleOrder.bind(this)} current={this.props.order}>Ragione sociale</ColHeader>
                <ColHeader name="vat_number" onOrder={this.handleOrder.bind(this)} current={this.props.order}>Partita iva</ColHeader>
                <ColHeader name="email" onOrder={this.handleOrder.bind(this)} current={this.props.order}>Email</ColHeader>
                <ColHeader name="phone" onOrder={this.handleOrder.bind(this)} current={this.props.order}>Telefono</ColHeader>
                <th>Doucmenti rimasti</th>
                <th>Commercialista</th>
                <ColHeader name="disabled" onOrder={this.handleOrder.bind(this)} current={this.props.order}>Stato</ColHeader>
                <ColHeader name="created_at" onOrder={this.handleOrder.bind(this)} current={this.props.order}>Data creazione</ColHeader>
                <ColHeader name="updated_at" onOrder={this.handleOrder.bind(this)} current={this.props.order}>Data modifica</ColHeader>
                <th>Azioni</th>
            </Table.Row>;
            if (this.props.me.roles[0] === Role.COMMERCIALISTA) {
                tableHeader = <Table.Row>
                    <ColHeader name="business_name" onOrder={this.handleOrder.bind(this)} current={this.props.order}>Ragione sociale</ColHeader>
                    <ColHeader name="vat_number" onOrder={this.handleOrder.bind(this)} current={this.props.order}>Partita iva</ColHeader>
                    <ColHeader name="email" onOrder={this.handleOrder.bind(this)} current={this.props.order}>Email</ColHeader>
                    <ColHeader name="phone" onOrder={this.handleOrder.bind(this)} current={this.props.order}>Telefono</ColHeader>
                    <th>Azioni</th>
                </Table.Row>
            }
        }
        return (
            <SiteWrapper me={this.props.me} business={this.props.business}>
                <Page.Content title="Soggetti">
                    <Grid.Row>
                        <Grid.Col md={12} xl={12}>
                            <Filter onSubmit={this.handleFilterSubmit.bind(this)} current={this.props.filters}/>
                            <Card>
                                <Card.Header>
                                    {add}
                                    <PageLength onChange={this.handlePerPageChange.bind(this)} current={this.props.perPage}/>
                                </Card.Header>
                                <Card.Body>
                                    <Table>
                                        <Table.Header>
                                            {tableHeader}
                                        </Table.Header>
                                        <Table.Body>
                                            {rows}
                                        </Table.Body>
                                    </Table>
                                </Card.Body>
                                <Card.Footer>
                                    {footer}
                                </Card.Footer>
                            </Card>
                        </Grid.Col>
                    </Grid.Row>
                </Page.Content>
            </SiteWrapper>
        );
    };
}

const mapStateToProps = state => {
    return {
        subjects: state.subjects.subjects,
        meta: state.subjects.meta,
        links: state.subjects.links,
        error: state.subjects.error,
        loading: state.subjects.loading,
        empty: state.subjects.empty,
        me: state.auth.me,
        business: state.auth.business,
        page: state.subjects.page,
        perPage: state.subjects.perPage,
        filters: state.subjects.filters,
        include: state.subjects.include,
        order: state.subjects.order,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onLoadSubjects: (page, perPage, filters, include, order) => dispatch(actions.loadSubjects(page, perPage, filters, include, order)),
        onClickSubject: (vatNumber) => dispatch(actions.setCurrentSubjectVatNumber(vatNumber)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubjectsPage);