const Type = Object.freeze({
    TD01: "Fattura",
    TD02: "Acconto su fattura",
    TD03: "Acconto su parcella",
    TD04: "Nota di credito",
    TD05: "Nota di debito",
    TD06: "Parcella",
    TD16: "Integrazione fattura",
    TD17: "Integrazione/autofattura",
    TD18: "Integrazione fattura",
    TD19: "Integrazione/autofattura",
    TD20: "Autofattura",
    TD21: "Autofattura",
    TD22: "Estrazione beni da Deposito IVA",
    TD23: "Estrazione beni da Deposito IVA con versamento dell\'IVA",
    TD24: "Fattura differita",
    TD25: "Fattura differita",
    TD26: "Cessione di beni",
    TD27: "Fattura per autoconsumo o per cessioni gratuite senza rivalsa"
});

export default Type;