import React, { Component } from 'react';
import {
    Page,
    Form,
    Card,
    Button,
    Grid
} from 'tabler-react';
import 'react-toastify/dist/ReactToastify.min.css';
import { Formik } from "formik";
import { toast } from 'react-toastify';
import { connect } from 'react-redux';

import SiteWrapper from "../hoc/SiteWrapper/SiteWrapper";
import api from '../api';
import * as actions from '../store/actions/index';
import { parsingApiError } from '../shared/utility';

class AccountantCreatePage extends Component {

    state = {
        users: [],
        initialValues: {
            business_name: "",
            vat_number: "",
            address: "",
            email: "",
            pec: "",
            phone: "",
            mobile_phone: "",
            resource: 0,
            users: [],
            isCloseButton: false
        }
    };

    componentDidMount() {
        this.props.onLoadUsers({ "roles.name": "commercialista", "accountants.not": true}, "all=true");
    }

    render() {
        let content = (<Formik
            initialValues={this.state.initialValues}
            validate={values => {
                // same as above, but feel free to move this into a class method now.
                let errors = {};
                if (!values.business_name) {
                    errors.business_name = "Campo obbligatorio";
                }
                if (!values.vat_number) {
                    errors.vat_number = "Campo obbligatorio";
                } else if (values.vat_number.length !== 11) {
                    errors.vat_number = "Campo non valido";
                }
                if ((values.email !== "" && values.email !== null) &&
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
                ) {
                    errors.email = "Campo non valido";
                }
                if ((values.pec !== "" && values.pec !== null) &&
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.pec)
                ) {
                    errors.email = "Campo non valido";
                }
                if (values.resource === null || values.resource === "") {
                    errors.resource = "Campo obbligatorio";
                } else if (values.resource < -1) {
                    errors.resource = "Valore non consentito";
                }
                return errors;
            }}
            onSubmit={(
                values,
                { setSubmitting, setErrors /* setValues and other goodies */ }
            ) => {
                setTimeout(() => {
                    let data = { "data": {} };
                    data.data.type = 'accountants';
                    data.data.attributes = {};
                    data.data.attributes.business_name = values.business_name;
                    data.data.attributes.vat_number = values.vat_number;
                    if (values.email !== null && values.email !== "") {
                        data.data.attributes.email = values.email;
                    }
                    if (values.pec !== null && values.pec !== "") {
                        data.data.attributes.pec = values.pec;
                    }
                    data.data.attributes.users = [];
                    values.users.forEach((element, index) => {
                        if (element !== false && typeof element !== "undefined") {
                            data.data.attributes.users.push(index);
                        }
                    });
                    data.data.attributes.phone = values.phone;
                    data.data.attributes.mobile_phone = values.mobile_phone;
                    data.data.attributes.resource = values.resource;
                    data.data.attributes.address = values.address;
                    api.post('accountants', data)
                        .then(response => {
                            if (values.isCloseButton) {
                                this.props.history.push('/accountants');
                            } else {
                                toast.success("Commercialista creato");
                                setSubmitting(false);
                            }
                        })
                        .catch(errors => {
                            toast.error("Errore creazione commercialista");
                            if (errors.response) {
                                if (errors.response.status === 422) {
                                    setErrors(parsingApiError(errors.response.data.source));
                                }
                            }
                            setSubmitting(false);
                        });
                }, 400);
            }}
            render={({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue
            }) => (
                    <Form onSubmit={handleSubmit}>
                        <Grid>
                            <Grid.Row>
                                <Grid.Col>
                                    <Form.Input
                                        name='business_name'
                                        label='Ragione sociale'
                                        placeholder='Ragione sociale'
                                        value={values.business_name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={(errors.business_name && touched.business_name) ? "invalid" : null}
                                        feedback={(errors.business_name && touched.business_name) ? errors.business_name : null}
                                    />
                                </Grid.Col>
                            </Grid.Row>
                        </Grid>
                        <Grid>
                            <Grid.Row>
                                <Grid.Col xs={12} sm={12} md={6} lg={6}>
                                    <Form.Input
                                        name='vat_number'
                                        label='Partita iva'
                                        placeholder='Partita iva'
                                        value={values.vat_number}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={(errors.vat_number && touched.vat_number) ? "invalid" : null}
                                        feedback={(errors.vat_number && touched.vat_number) ? errors.vat_number : null}
                                    />
                                </Grid.Col>
                                <Grid.Col xs={12} sm={12} md={6} lg={6}>
                                    <Form.Input
                                        name='address'
                                        label='Indirizzo'
                                        placeholder='Indirizzo'
                                        value={values.address}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={(errors.address && touched.address) ? "invalid" : null}
                                        feedback={(errors.address && touched.address) ? errors.address : null}
                                    />
                                </Grid.Col>
                            </Grid.Row>
                        </Grid>
                        <Grid>
                            <Grid.Row>
                                <Grid.Col xs={12} sm={12} md={6} lg={6}>
                                    <Form.Input
                                        name='email'
                                        label='Email'
                                        placeholder='Inserisci email'
                                        value={values.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={(errors.email && touched.email) ? "invalid" : null}
                                        feedback={(errors.email && touched.email) ? errors.email : null}
                                    />
                                </Grid.Col>
                                <Grid.Col xs={12} sm={12} md={6} lg={6}>
                                    <Form.Input
                                        name='pec'
                                        label='PEC'
                                        placeholder='pec'
                                        value={values.pec}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={(errors.pec && touched.pec) ? "invalid" : null}
                                        feedback={(errors.pec && touched.pec) ? errors.pec : null}
                                    />
                                </Grid.Col>
                            </Grid.Row>
                        </Grid>
                        <Grid>
                            <Grid.Row>
                                <Grid.Col xs={12} sm={12} md={6} lg={6}>
                                    <Form.Input
                                        name='phone'
                                        label='Telefono'
                                        placeholder='Telefono'
                                        value={values.phone}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={(errors.phone && touched.phone) ? "invalid" : null}
                                        feedback={(errors.phone && touched.phone) ? errors.phone : null}
                                    />
                                </Grid.Col>
                                <Grid.Col xs={12} sm={12} md={6} lg={6}>
                                    <Form.Input
                                        name='mobile_phone'
                                        label='Cellulare'
                                        placeholder='cellulare'
                                        value={values.mobile_phone}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={(errors.mobile_phone && touched.mobile_phone) ? "invalid" : null}
                                        feedback={(errors.mobile_phone && touched.mobile_phone) ? errors.mobile_phone : null}
                                    />
                                </Grid.Col>
                            </Grid.Row>
                        </Grid>
                        <Grid>
                            <Grid.Row>
                                <Grid.Col xs={12} sm={12} md={6} lg={6}>
                                    <Form.Input
                                        name='resource'
                                        label='Risorse'
                                        placeholder='Risorse'
                                        value={values.resource}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={(errors.resource && touched.resource) ? "invalid" : null}
                                        feedback={(errors.resource && touched.resource) ? errors.resource : null}
                                    />
                                </Grid.Col>
                                <Grid.Col xs={12} sm={12} md={6} lg={6}>
                                    <Form.Group label="User">
                                        {
                                            this.props.users !== null && this.props.users.length > 0 ?
                                                <Form.SelectGroup
                                                    canSelectMultiple
                                                >
                                                    {
                                                        this.props.users.map((user, i) => {
                                                            return <Form.SelectGroupItem
                                                                key={user.id}
                                                                label={user.attributes.name}
                                                                name={"users[" + user.id + "]"}
                                                                value={user.attributes.id}
                                                                onChange={handleChange}
                                                            />
                                                        })
                                                    }
                                                </Form.SelectGroup>
                                                : "Nessun utente disponibile"
                                        }
                                    </Form.Group>
                                </Grid.Col>
                            </Grid.Row>
                        </Grid>
                        <Button.List>
                            <Button type='button' color="primary" disabled={isSubmitting} onClick={(e) => {
                                setFieldValue('isCloseButton', false)
                                setTimeout(() => handleSubmit(e.persist()), 0)
                            }}>Crea e continua</Button>
                            <Button type='button' color="cyan" disabled={isSubmitting} onClick={(e) => {
                                setFieldValue('isCloseButton', true)
                                setTimeout(() => handleSubmit(e.persist()), 0)
                            }}>Crea e chiudi</Button>
                        </Button.List>
                    </Form>
                )}
        />);

        return (<SiteWrapper me={this.props.me} business={this.props.business}>
            < Page.Content title="Nuovo commercialista" >
                <Card>
                    <Card.Body>
                        {content}
                    </Card.Body>
                </Card>
            </ Page.Content>
        </SiteWrapper>);
    };
}

const mapStateToProps = state => {
    return {
        me: state.auth.me,
        business: state.auth.business,
        users: state.users.users,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onLoadUsers: (filters, params) => dispatch(actions.loadUsers(null, null, filters, [], {}, params)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountantCreatePage);