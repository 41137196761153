import React from 'react';

import { Formik } from "formik";
import {
    Button,
    Grid,
    Card,
    Form
} from 'tabler-react';

import LogName from '../../shared/enums/LogName';
import { isEmpty } from '../../shared/utility';

class AccountantFilter extends React.Component {
    state = {
        initialValues: {
            log_name: "",
            subject_id: "",
            subject_type: "",
        }
    }

    handleSubmit = (values, {
        props = this.props,
        setSubmitting
    }) => {
        setSubmitting(false);
        props.onSubmit(values);
        return;
    }

    handleReset = (e) => {
        this.initialValues = this.state.initialValues;
    }

    initialValues = isEmpty(this.props.current) ? this.state.initialValues : this.props.current;

    render() {
        let nameOptions = null;
        nameOptions = Object.keys(LogName).map(name => {
            return <option key={name}>{name}</option>
        })
        return (
            <Formik
                initialValues={this.initialValues}
                validate={values => {
                    let errors = {};
                    return errors;
                }}
                onSubmit={this.handleSubmit}
                onReset={this.handleReset}
                render={({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    handleReset,
                }) => (
                        <Card title="Filtri" isCollapsible isCollapsed={isEmpty(this.props.current) ? true : false}>
                            <Card.Body>
                                <Form onSubmit={handleSubmit}>
                                    <Grid.Row>
                                        <Grid.Col>
                                            <Form.Group label="Tipo">
                                                <Form.Select
                                                    name="log_name"
                                                    value={values.log_name}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                >
                                                    <option></option>
                                                    {nameOptions}
                                                </Form.Select>
                                            </Form.Group>
                                        </Grid.Col>
                                        <Grid.Col>
                                            <Form.Group label="Id oggetto">
                                                <Form.Input
                                                    name="subject_id"
                                                    placeholder="Id oggetto"
                                                    value={values.subject_id}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </Form.Group>
                                        </Grid.Col>
                                        <Grid.Col>
                                            <Form.Group label="Tipo oggetto">
                                                <Form.Input
                                                    name="subject_type"
                                                    placeholder="Tipo oggetto"
                                                    value={values.subject_type}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </Form.Group>
                                        </Grid.Col>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Col>
                                            <Button.List>
                                                <Button color="cyan" type='submit' value='Submit' disabled={isSubmitting}>Cerca</Button>
                                                <Button
                                                    color="indigo"
                                                    type="reset"
                                                    value="Reset"
                                                    disabled={!touched || isSubmitting}
                                                    onClick={handleReset}>Reset</Button>
                                            </Button.List>
                                        </Grid.Col>
                                    </Grid.Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    )}
            />
        );
    }
}

export default AccountantFilter;