import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Page,
    Table,
    Card,
} from 'tabler-react';

import SiteWrapper from "../hoc/SiteWrapper/SiteWrapper";
import DocumentStatus from '../components/UI/DocumentStatus';
import Role from "../shared/enums/Role";

class FaqPage extends Component {
    render() {
        let statusBody = null;
        statusBody = Object.keys(this.props.status).map((key, index) => {
            return <Table.Row key={index}>
                <Table.Col>
                    <DocumentStatus description="">
                        {key}
                    </DocumentStatus>
                </Table.Col>
                <Table.Col>
                    {this.props.status[key].description}
                </Table.Col>
            </Table.Row>;
        });
        let extraFaqs = null;
        if (this.props.me.roles[0] === Role.USER || this.props.me.roles[0] === Role.SUPERUSER) {
            extraFaqs = (<div>
                <Card title="Posso ricevere una notifica email quando ricevo un documento?" isCollapsible isCollapsed>
                    <Card.Body>
                        Per ricevere una mail per ogni documento ricevuto bisogna abilitare l'opzione nel menu preferenze.<br />
                        Clicca sul tuo nome in alto a desta, e nel menu a tendina selezionare la voce "Preferenze".<br />
                        Spuntare la casella "Mail ricezione documenti" e cliccare su salva.
                    </Card.Body>
                </Card>
                <Card title="Posso ricevere una notifica email quando ricevo un notifica per un documento?" isCollapsible isCollapsed>
                    <Card.Body>
                        Per ricevere una mail per ogni notifica ricevuta bisogna abilitare l'opzione nel menu preferenze.<br />
                        Clicca sul tuo nome in alto a desta, e nel menu a tendina selezionare la voce "Preferenze".<br />
                        Spuntare la casella "Mail ricezione notifiche" e cliccare su salva.
                    </Card.Body>
                </Card>
            </div>);
        }
        return (
            <SiteWrapper me={this.props.me} business={this.props.business}>
                <Page.Content title="FAQ">
                    <Card title="Stati documenti" isCollapsible isCollapsed>
                        <Card.Body>
                            <Table>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.ColHeader>Stato</Table.ColHeader>
                                        <Table.ColHeader>Descrizione</Table.ColHeader>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {statusBody}
                                </Table.Body>
                            </Table>
                        </Card.Body>
                    </Card>
                    {extraFaqs}
                </Page.Content>
            </SiteWrapper>
        );
    };
}

const mapStateToProps = state => {
    return {
        status: state.documentStatus.status,
        me: state.auth.me,
        business: state.auth.business
    };
};
export default connect(mapStateToProps, null)(FaqPage);