export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const createQueryString = (page, perPage, filters, includes = [], order = {}, params = null) => {
    let query = "?";
    if (page !== null) {
        query += "&page=" + page;
    }
    if (perPage !== null) {
        query += "&per_page=" + perPage;
    }
    for (const [key, value] of Object.entries(filters)) {
        if (value !== "" && value !== null) {
            if (value instanceof Object) {
                if (Object.values(value)[0] !== null && Object.values(value)[0] !== "") {
                    query += "&filter[" + key + "." + Object.keys(value)[0] + "]=" + Object.values(value)[0];
                }
            } else {
                query += "&filter[" + key + "]=" + value;
            }
        }
    }
    if (includes.length > 0) {
        query += "&include=" + includes.join()
    }
    if (!isEmpty(order)) {
        switch (order.direction) {
            case 'ASC':
                query += "&order_by_asc=" + order.by;
                break;
            case 'DESC':
                query += "&order_by_desc=" + order.by;
                break;
            default:
                break;
        }
    }
    if (params !== null) {
        query += params;
    }
    return query;
}

export const checkVatNumber = (value) => {
    if (value) {
        if (/^[0-9]{11}$/.test(value)) {
            return true;
        }
    }
    return false;
}

export function syntaxHighlight(json) {
    json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
    return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
        var cls = 'number';
        if (/^"/.test(match)) {
            if (/:$/.test(match)) {
                cls = 'key';
            } else {
                cls = 'string';
            }
        } else if (/true|false/.test(match)) {
            cls = 'boolean';
        } else if (/null/.test(match)) {
            cls = 'null';
        }
        return '<span class="' + cls + '">' + match + '</span>';
    });
}

export function parsingApiError(errors) {
    let out = {};
    errors.forEach(error => {
        out[error.pointer.replace('data.attributes.', '')] = jsUcfirst(error.message)
    });
    return out;
}

export function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

export function createOrderObject(current, column) {
    const order = {
        by: column,
        direction: current.by === column ? (current.direction === 'ASC' ? 'DESC' : 'ASC') : 'ASC',
    };
    return order;
}

export function jsUcfirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}