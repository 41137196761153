export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_REFRESH = 'AUTH_REFRESH';

export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';

export const SET_USERS = 'SET_USERS';
export const FETCH_USERS_FAILED = 'FETCH_USERS_FAILED';
export const FETCH_USERS_EMPTY = 'FETCH_USERS_EMPTY';
export const SET_USERS_TABLE_STATUS = 'SET_USERS_TABLE_STATUS';

export const SET_SUBJECTS = 'SET_SUBJECTS';
export const FETCH_SUBJECTS_FAILED = 'FETCH_SUBJECTS_FAILED';
export const FETCH_SUBJECTS_EMPTY = 'FETCH_SUBJECTS_EMPTY';
export const SET_SUBJECT_VAT_NUMBER = 'SET_SUBJECT_VAT_NUMBER';
export const SET_SUBJECT_TABLE_STATUS = 'SET_SUBJECT_TABLE_STATUS';

export const SET_ACTIVE_DOCUMENTS = 'SET_ACTIVE_DOCUMENTS';
export const FETCH_ACTIVE_DOCUMENTS_FAILED = 'FETCH_ACTIVE_DOCUMENTS_FAILED';
export const FETCH_ACTIVE_DOCUMENTS_EMPTY = 'FETCH_ACTIVE_DOCUMENTS_EMPTY';
export const SET_ACTIVE_DOCUMENTS_TABLE_STATUS = 'SET_ACTIVE_DOCUMENTS_TABLE_STATUS';

export const SET_ACTIVE_DOCUMENT = 'SET_ACTIVE_DOCUMENT';
export const FETCH_ACTIVE_DOCUMENT_FAILED = 'FETCH_ACTIVE_DOCUMENT_FAILED';

export const SET_PASSIVE_DOCUMENTS = 'SET_PASSIVE_DOCUMENTS';
export const FETCH_PASSIVE_DOCUMENTS_FAILED = 'FETCH_PASSIVE_DOCUMENTS_FAILED';
export const FETCH_PASSIVE_DOCUMENTS_EMPTY = 'FETCH_PASSIVE_DOCUMENTS_EMPTY';
export const SET_PASSIVE_DOCUMENTS_TABLE_STATUS = 'SET_PASSIVE_DOCUMENTS_TABLE_STATUS';

export const SET_PASSIVE_DOCUMENT = 'SET_PASSIVE_DOCUMENT';
export const FETCH_PASSIVE_DOCUMENT_FAILED = 'FETCH_PASSIVE_DOCUMENT_FAILED';

export const SET_ACCOUNTANTS = 'SET_ACCOUNTANTS';
export const FETCH_ACCOUNTANTS_FAILED = 'FETCH_ACCOUNTANTS_FAILED';
export const FETCH_ACCOUNTANTS_EMPTY = 'FETCH_ACCOUNTANTS_EMPTY';
export const SET_ACCOUNTANTS_TABLE_STATUS = 'SET_ACCOUNTANTS_TABLE_STATUS';

export const SET_LOGS = 'SET_LOGS';
export const FETCH_LOGS_FAILED = 'FETCH_LOGS_FAILED';
export const FETCH_LOGS_EMPTY = 'FETCH_LOGS_EMPTY';
export const SET_LOGS_TABLE_STATUS = 'SET_LOGS_TABLE_STATUS';

export const LOADING_START = 'LOADING_START';

export const TOKEN_EXPIRED = 'TOKEN_EXPIRED';

export const SET_DOCUMENT_STATUS = 'SET_DOCUMENT_STATUS';

export const SET_ME = 'SET_ME';

export const LOADING_START_ACCOUNTANT_DOWNLOADS = 'LOADING_START_ACCOUNTANT_DOWNLOADS';
export const SET_ACCOUNTANT_DOWNLOADS = 'SET_ACCOUNTANT_DOWNLOADS';
export const FETCH_ACCOUNTANT_DOWNLOADS_FAILED = 'FETCH_ACCOUNTANT_DOWNLOADS_FAILED';
export const FETCH_ACCOUNTANT_DOWNLOADS_EMPTY = 'FETCH_ACCOUNTANT_DOWNLOADS_EMPTY';

export const RESET_PASSWORD_START = "RESET_PASSWORD_START";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";

export const LOADING_START_FOLLOWING_REQUESTS = 'LOADING_START_FOLLOWING_REQUESTS';
export const SET_FOLLOWING_REQUESTS = 'SET_FOLLOWING_REQUESTS';
export const FETCH_FOLLOWING_REQUESTS_FAILED = 'FETCH_FOLLOWING_REQUESTS_FAILED';
export const FETCH_FOLLOWING_REQUESTS_EMPTY = 'FETCH_FOLLOWING_REQUESTS_EMPTY';
export const SET_FOLLOWING_REQUESTS_TABLE_STATUS = 'SET_FOLLOWING_REQUESTS_TABLE_STATUS';

export const LOADING_START_PREFERENCES = 'LOADING_START_PREFERENCES';
export const SET_PREFERENCES = 'SET_PREFERENCES';
export const FETCH_PREFERENCES_FAILED = 'FETCH_PREFERENCES_FAILED';
export const FETCH_PREFERENCES_EMPTY = 'FETCH_PREFERENCES_EMPTY';