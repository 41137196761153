import React from 'react';

import { Form } from 'tabler-react';

const selectSubject = (props) => {
    let options = null;
    if (props.subjects) {
        options = props.subjects.map(subject => {
            return (
                <option key={subject.id} value={subject.id}>
                    {subject.attributes.business_name}
                </option>
            );
        });
    }
    return (
        <div style={style.container}>
            Seleziona soggetto:
            <Form.Select onChange={(e) => props.onChange(e.target.value)}>
                <option>
                    --
                </option>
                {options}
            </Form.Select>
        </div>
    );
}

const style = {
    container: {
        marginLeft: "auto"
    }
}

export default selectSubject;