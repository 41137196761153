import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Page,
    Form,
    Card,
    Button,
    Text,
    Dimmer,
    Grid,
} from 'tabler-react';
import FileBase64 from 'react-file-base64';
import { toast } from 'react-toastify';

import SiteWrapper from "../hoc/SiteWrapper/SiteWrapper";
import * as actions from '../store/actions/index';
import api from '../api';


class ActiveDocumentUploadPage extends Component {

    state = {
        files: [],
        isSubmitting: true
    }

    componentDidMount() {
        this.props.onLoadDocument(this.props.match.params.id);
    }

    render() {
        const getFile = (file) => {
            console.log(file)
            if (file.type.indexOf('pkcs7') === -1) {
                toast.warning("Formato file non valido");
                return;
            }
            this.setState({
                ...this.state,
                isSubmitting: false,
                file: file
            });
        }

        const upload = () => {
            this.setState({
                ...this.state,
                isSubmitting: true,
            });
            const base64 = this.state.file.base64.split(',')[1];
            let data = { "data": {} };
            data.data.type = 'active-documents';
            data.data.attributes = {};
            data.data.attributes.base64 = base64;
            data.data.attributes.subject_vat_number = this.props.document.data.relationships.subject.data.attributes.vat_number;
            api.post('documents/active/' + this.props.match.params.id + '/signed', data)
                .then(response => {
                    this.setState({
                        ...this.state,
                        isSubmitting: false,
                    });
                    toast.success("File inviato correttamente");
                })
                .catch(errors => {
                    this.setState({
                        ...this.state,
                        isSubmitting: false,
                    });
                    toast.error("Errore invio file");
                });
            }

        let dataBody = null;
        if (this.props.loading === false && this.props.error === false) {
            dataBody = (<Grid>
                <Grid.Row>
                    <Grid.Col sm={6}>
                        <Text size="sm" muted>
                            ID
                        </Text >
                        <div>{this.props.document.data.id}</div>
                    </Grid.Col>
                    <Grid.Col sm={6}>
                        <Text size="sm" muted>
                            Nome file
                        </Text >
                        <div>{this.props.document.data.attributes.file_name}</div>
                    </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Col sm={6}>
                        <Text size="sm" muted>
                            Numero
                        </Text >
                        <div>{this.props.document.data.attributes.number}</div>
                    </Grid.Col>
                    <Grid.Col sm={6}>
                        <Text size="sm" muted>
                            Anno
                        </Text >
                        <div>{this.props.document.data.attributes.year}</div>
                    </Grid.Col>
                </Grid.Row>
            </Grid>)
        }

        return (
            <SiteWrapper me={this.props.me} business={this.props.business}>
                <Page.Content title="Caricamento documento firmato">
                    <Card>
                        <Card.Header>
                            Dati documento
                        </Card.Header>
                        <Card.Body>
                            <Dimmer active={this.props.loading ? true : false} loader>
                                {dataBody}
                            </Dimmer>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body>
                            <Form.Group label="Seleziona file firmato computer">
                                <FileBase64
                                    multiple={false}
                                    onDone={getFile} />
                            </Form.Group>
                        </Card.Body>
                        <Card.Footer>
                            <div className="d-flex">
                                <Button type='submit' color="primary" disabled={this.state.isSubmitting} onClick={upload} className="ml-auto">Carica</Button>
                            </div>
                        </Card.Footer>
                    </Card>
                </Page.Content>
            </SiteWrapper>
        );
    };
}

const mapStateToProps = state => {
    return {
        document: state.activeDocuments.document,
        error: state.activeDocuments.error,
        loading: state.activeDocuments.loadingSingle,
        me: state.auth.me,
        business: state.auth.business
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onLoadDocument: (id) => dispatch(actions.loadActiveDocument(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActiveDocumentUploadPage);