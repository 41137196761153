import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
    Page,
    Dimmer,
    Card,
    Grid,
    StampCard,
    List,
    Button,
    Table,
} from 'tabler-react';

import * as actions from '../store/actions/index';
import SiteWrapper from "../hoc/SiteWrapper/SiteWrapper";
import ResourceRemaining from '../components/UI/ResourceRemaining';
import MassiveDownload from '../components/Form/MassiveDownload';
import Pagination from '../components/UI/Table/Pagination';
import TableMeta from '../components/UI/Table/TableMeta';
import PageLength from '../components/UI/Table/PageLength';
import ColHeader from '../components/UI/Table/ColHeader';
import api from '../api';
import { createOrderObject } from '../shared/utility';
import Role from "../shared/enums/Role";

class SubjectPage extends Component {
    state = {
        id: null,
        loading: true,
        subject: null,
        resource: null,
        subjects: null,
        submitting: true,
        newVatNumber: "",
        toAccountant: false,
        accountantId: null,
        statistics: null,
        downloads: {
            include: this.props.me.roles[0] === Role.COMMERCIALISTA ? ['subject'] : ['subject', 'accountant'],
            order: {
                by: 'created_at',
                direction: 'DESC',
            },
            perPage: 15,
            page: 1,
            filters: {}
        }
    }
    componentDidMount() {
        const filters = {
            subject_id: this.props.match.params.id,
        }
        this.setState({
            ...this.state,
            id: this.props.match.params.id,
            downloads: {
                ...this.state.downloads,
                filters
            }
        });

        api.get('subjects/' + this.props.match.params.id + "?include=accountant,resource").then(response => {
            this.setState({
                ...this.state,
                loading: false,
                id: response.data.data.id,
                subject: response.data.data.attributes,
                resource: response.data.data.relationships.resources,
                accountant: response.data.data.relationships.accountant
            });
        }).catch(error => {
        });
        api.get('subjects/' + this.props.match.params.id + "/statistics").then(response => {
            this.setState({
                ...this.state,
                statistics: response.data.data.attributes
            });
        }).catch(error => {
        });

        this.props.onLoadDownloads(this.state.downloads.page, this.state.downloads.perPage, filters, this.state.downloads.include, this.state.downloads.order);
    }

    handleAccountantViewClick(id) {
        this.setState({
            ...this.state,
            toAccountant: true,
            accountantId: id,
        });
    }

    handlePageChange(page) {
        this.setState({
            ...this.state,
            downloads: {
                ...this.state.downloads,
                page: page
            }
        })
        this.props.onLoadDownloads(this.state.downloads.page, this.state.downloads.perPage, this.state.downloads.filters, this.state.downloads.include, this.state.downloads.order);
    }

    handlePerPageChange(perPage) {
        this.setState({
            ...this.state,
            downloads: {
                ...this.state.downloads,
                perPage: perPage
            }
        })
        this.props.onLoadDownloads(this.state.downloads.page, this.state.downloads.perPage, this.state.downloads.filters, this.state.downloads.include, this.state.downloads.order);
    }

    handleOrder(column) {
        const order = createOrderObject(this.state.downloads.order, column);
        this.setState({
            ...this.state,
            downloads: {
                ...this.state.downloads,
                order: order
            }
        });
        this.props.onLoadDownloads(this.state.downloads.page, this.state.downloads.perPage, this.state.downloads.filters, this.state.downloads.include, this.state.downloads.order);
    }

    render() {
        if (this.state.toAccountant === true) {
            const url = "/accountants/" + this.state.accountantId;
            return <Redirect to={url} />
        }
        let title = null;
        let dataBody = null;
        let resourceBody = null;
        let activeBody = null;
        let passiveBody = null;
        let accountant = null;
        let downloadForm = null;
        let downloadsHeader = null;
        let downloadsBody = null;
        let downloadTableFooter = null;
        if (this.state.subject) {
            title = this.state.subject.business_name;
            dataBody = (<List unstyled>
                <List.Item><strong>Partita iva:</strong> {this.state.subject.vat_number}</List.Item>
                <List.Item><strong>Indirizzo:</strong> {this.state.subject.address}</List.Item>
                <List.Item><strong>Telefono:</strong> {this.state.subject.phone}</List.Item>
                <List.Item><strong>Cellulare:</strong> {this.state.subject.mobile_phone}</List.Item>
                <List.Item><strong>Email:</strong> {this.state.subject.email}</List.Item>
                <List.Item><strong>PEC:</strong> {this.state.subject.pec}</List.Item>
            </List>
            );
            if (this.props.me.roles[0] !== Role.COMMERCIALISTA) {
                resourceBody = (
                    <Card className="p-3">
                        <ResourceRemaining
                            amount={this.state.resource.data.attributes.remaining}
                            total={this.state.resource.data.attributes.total}
                            from={this.state.resource.data.attributes.last_purchase_date}>
                            ></ResourceRemaining>
                    </Card>
                );
            }
        }
        if (this.state.statistics) {
            const activeHeader = (
                <div>
                    {this.state.statistics.active_count} <small>Ciclo attivo</small>
                </div>
            );
            const activeFooter = (
                <div>
                    {this.state.statistics.active_pa_count} a pubblica amministrazione
                    </div>
            );
            activeBody = (
                <StampCard
                    color="blue"
                    icon="log-out"
                    header={activeHeader}
                    footer={activeFooter}
                />
            );
            const passiveHeader = (
                <div>
                    {this.state.statistics.passive_count} <small>Ciclo passivo</small>
                </div>
            );
            passiveBody = (
                <StampCard
                    color="green"
                    icon="log-in"
                    header={passiveHeader}
                />
            );
        }
        downloadsBody = (
            <Table.Row>
                <Table.Col colSpan={5}>Nessun dato presente</Table.Col>
            </Table.Row>
        );

        if (this.props.me.roles[0] !== Role.COMMERCIALISTA) {
            if (this.state.accountant) {
                accountant = (
                    <Card statusColor="purple">
                        <Card.Header>
                            <Card.Title>Commercialista</Card.Title>
                            <Card.Options>
                                <Button color="secondary" size="sm" onClick={() => this.handleAccountantViewClick(this.state.accountant.data.id)}>
                                    Visualizza
                                </Button>
                            </Card.Options>
                        </Card.Header>
                        <Card.Body>
                            <List unstyled>
                                <List.Item><strong>Partita iva:</strong> {this.state.accountant.data.attributes.vat_number}</List.Item>
                                <List.Item><strong>Indirizzo:</strong> {this.state.accountant.data.attributes.address}</List.Item>
                                <List.Item><strong>Telefono:</strong> {this.state.accountant.data.attributes.phone}</List.Item>
                                <List.Item><strong>Cellulare:</strong> {this.state.accountant.data.attributes.mobile_phone}</List.Item>
                                <List.Item><strong>Email:</strong> {this.state.accountant.data.attributes.email}</List.Item>
                                <List.Item><strong>PEC:</strong> {this.state.accountant.data.attributes.pec}</List.Item>
                            </List>
                        </Card.Body>
                    </Card>
                );
            }
            if (this.props.downloads) {
                downloadsBody = this.props.downloads.map((download) => {
                    return <Table.Row key={download.id}>
                        <Table.Col>{download.relationships.accountant.data.attributes.business_name}</Table.Col>
                        <Table.Col>{download.relationships.subject.data.attributes.business_name}</Table.Col>
                        <Table.Col>{download.attributes.from}</Table.Col>
                        <Table.Col>{download.attributes.to}</Table.Col>
                        <Table.Col>{download.attributes.type}</Table.Col>
                        <Table.Col>{download.attributes.created_at}</Table.Col>
                    </Table.Row>;
                });
            }

            downloadsHeader = (
                <Table.Row>
                    <ColHeader name="accountant.business_name" onOrder={this.handleOrder.bind(this)} current={this.state.downloads.order}>Commercialista</ColHeader>
                    <ColHeader name="subject.business_name" onOrder={this.handleOrder.bind(this)} current={this.state.downloads.order}>Soggetto</ColHeader>
                    <ColHeader name="from" onOrder={this.handleOrder.bind(this)} current={this.state.downloads.order}>Da</ColHeader>
                    <ColHeader name="to" onOrder={this.handleOrder.bind(this)} current={this.state.downloads.order}>A</ColHeader>
                    <ColHeader name="type" onOrder={this.handleOrder.bind(this)} current={this.state.downloads.order}>tipo</ColHeader>
                    <ColHeader name="created_at" onOrder={this.handleOrder.bind(this)} current={this.state.downloads.order}>Data</ColHeader>
                </Table.Row>
            );
        }
        if (this.props.me.roles[0] === Role.COMMERCIALISTA) {
            downloadForm = (<Grid.Row>
                <Grid.Col>
                    <MassiveDownload title="Download documenti" subjectId={this.props.match.params.id} vatNumber={this.state.subject ? this.state.subject.vat_number : "" }></MassiveDownload>
                </Grid.Col>
            </Grid.Row>);

            if (this.props.downloads) {
                downloadsBody = this.props.downloads.map((download) => {
                    return <Table.Row key={download.id}>
                        <Table.Col>{download.relationships.subject.data.attributes.business_name}</Table.Col>
                        <Table.Col>{download.attributes.from}</Table.Col>
                        <Table.Col>{download.attributes.to}</Table.Col>
                        <Table.Col>{download.attributes.type}</Table.Col>
                        <Table.Col>{download.attributes.created_at}</Table.Col>
                    </Table.Row>;
                });
            }

            downloadsHeader = (
                <Table.Row>
                    <Table.ColHeader>Soggetto</Table.ColHeader>
                    <ColHeader name="from" onOrder={this.handleOrder.bind(this)} current={this.state.downloads.order}>Da</ColHeader>
                    <ColHeader name="to" onOrder={this.handleOrder.bind(this)} current={this.state.downloads.order}>A</ColHeader>
                    <ColHeader name="type" onOrder={this.handleOrder.bind(this)} current={this.state.downloads.order}>tipo</ColHeader>
                    <ColHeader name="created_at" onOrder={this.handleOrder.bind(this)} current={this.state.downloads.order}>Data</ColHeader>
                </Table.Row>
            );
        }
        if (this.props.downloads) {
            downloadTableFooter = (
                <div>
                    <TableMeta
                        from={this.props.meta.from}
                        to={this.props.meta.to}
                        total={this.props.meta.total}
                    />
                    <Pagination links={this.props.links} onPageChange={this.handlePageChange.bind(this)} />
                </div>);
        }
        return (<SiteWrapper me={this.props.me} business={this.props.business}>
            <Page.Content title={title} >
                <Grid.Row cards>
                    <Grid.Col xs={12} sm={12} md={4} lg={4}>
                        <Grid.Row>
                            <Grid.Col>
                                <Card title="Dati" statusColor="blue">
                                    <Card.Body>
                                        <Dimmer active={this.props.loading ? true : false} loader>
                                            {dataBody}
                                        </Dimmer>
                                    </Card.Body>
                                </Card>
                                <Dimmer active={this.props.loading ? true : false} loader>
                                    {accountant}
                                </Dimmer>
                            </Grid.Col>
                        </Grid.Row>
                    </Grid.Col>
                    <Grid.Col xs={12} sm={8} md={8} lg={8}>
                        <Grid.Row>
                            <Grid.Col xs={12} sm={12} md={12} lg={12}>
                                {resourceBody}
                            </Grid.Col>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Col xs={12} sm={6} md={6} lg={6}>
                                {activeBody}
                            </Grid.Col>
                            <Grid.Col xs={12} sm={6} md={6} lg={6}>
                                {passiveBody}
                            </Grid.Col>
                        </Grid.Row>
                        {downloadForm}
                    </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Col>
                        <Card>
                            <Card.Header>
                                <Card.Title>Downloads</Card.Title>
                                <PageLength onChange={this.handlePerPageChange.bind(this)} />
                            </Card.Header>
                            <Card.Body>
                                <Table>
                                    <Table.Header>
                                        {downloadsHeader}
                                    </Table.Header>
                                    <Table.Body>
                                        {downloadsBody}
                                    </Table.Body>
                                </Table>
                            </Card.Body>
                            <Card.Footer>
                                {downloadTableFooter}
                            </Card.Footer>
                        </Card>
                    </Grid.Col>
                </Grid.Row>
            </Page.Content>
        </SiteWrapper>);
    };
}

const mapStateToProps = state => {
    return {
        me: state.auth.me,
        userableId: state.auth.userableId,
        business: state.auth.business,
        downloads: state.accountantDownloads.downloads,
        meta: state.accountantDownloads.meta,
        links: state.accountantDownloads.links,
        error: state.accountantDownloads.error,
        loading: state.accountantDownloads.loading,
        empty: state.accountantDownloads.empty,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onLoadDownloads: (page, perPage, filters, include, order) => dispatch(actions.loadDownloads(page, perPage, filters, include, order))
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(SubjectPage);