import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

import Role from "../../shared/enums/Role";

const initialState = {
    token: null,
    expire_on: null,
    me: null,
    error: null,
    loading: false,
    userableId: null,
    business: null,
    authRedirectPath: '/'
};

const authStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const authSuccess = (state, action) => {
    let business = null;
    let userableId = null;
    if (action.user.relationships) {
        business = action.user.attributes.roles[0] === Role.COMMERCIALISTA ? action.user.relationships.accountants.data[0] : action.user.attributes.roles[0] === 'user' ? action.user.relationships.subjects.data[0] : null;
        userableId = action.user.attributes.roles[0] === Role.COMMERCIALISTA ? action.user.relationships.accountants.data[0].id : action.user.attributes.roles[0] === 'user' ? action.user.relationships.subjects.data[0].id : null;
    }
    let me = action.user.attributes;
    me['id'] = action.user.id;
    return updateObject(state, {
        token: action.token,
        expire_on: action.expire_on,
        me: me,
        userableId: userableId,
        business: business,
        error: null,
        loading: false
    });
};

const authFail = (state, action) => {
    return updateObject(state, {
        error: action.error.response.data.title,
        loading: false
    });
};

const authLogout = (state, action) => {
    return updateObject(state, { token: null, expire_on: null, loading: false });
};

const setAuthRedirectPath = (state, action) => {
    return updateObject(state, { authRedirectPath: action.path })
}

const setMe = (state, action) => {
    let business = null;
    if (action.me.relationships) {
        business = action.me.attributes.roles[0] === Role.COMMERCIALISTA ? action.me.relationships.accountants.data[0] : action.me.attributes.roles[0] === 'user' ? action.me.relationships.subjects.data[0] : null;
    }
    let me = action.me.attributes;
    me['id'] = action.me.id;
    return updateObject(state, { me: me, business: business })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START: return authStart(state, action);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.AUTH_FAIL: return authFail(state, action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
        case actionTypes.SET_AUTH_REDIRECT_PATH: return setAuthRedirectPath(state, action);
        case actionTypes.SET_ME: return setMe(state, action);
        //case actionTypes.TOKEN_EXPIRED: return authLogout(state, action);
        default:
            return state;
    }
};

export default reducer;