import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    preferences: null,
};

const loadingStart = (state) => {
    return updateObject(state, { error: false, loading: true });
};

const setPreferences = (state, action) => {
    return updateObject(state, {
        preferences: action.requests.data,
        error: false,
        loading: false,
        empty: false
    });
};

const fetchPreferencesFailed = (state) => {
    return updateObject(state, { error: true, loading: false });
};

const fetchPreferencesEmpty = (state) => {
    return updateObject(state, { error: false, loading: false, empty: true, preferences: null });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOADING_START_PREFERENCES: return loadingStart(state);
        case actionTypes.SET_PREFERENCES: return setPreferences(state, action);
        case actionTypes.FETCH_PREFERENCES_FAILED: return fetchPreferencesFailed(state);
        case actionTypes.FETCH_PREFERENCES_EMPTY: return fetchPreferencesEmpty(state);
        default: return state;
    }
};

export default reducer;