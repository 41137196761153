import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    status: []
};

const setDocumentStatus = (state, action) => {
    let data = action.status.data
    let mapping = {}
    data.forEach(status => {
        mapping[status.attributes.name] = {
            short_description: status.attributes.short_description,
            description: status.attributes.description
        };
    });
    return updateObject(state, {
        status: mapping
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_DOCUMENT_STATUS: return setDocumentStatus(state, action);
        default: return state;
    }
};

export default reducer;