import 'react-app-polyfill/ie9';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import "./index.css";
import "./c3jscustom.css";

import App from './App';
import * as serviceWorker from './serviceWorker';
import authReducer from './store/reducers/auth';
import usersReducer from './store/reducers/users';
import subjectsReducer from './store/reducers/subjects';
import accountantsReducer from './store/reducers/accountants';
import activeDocumentsReducer from './store/reducers/activeDocuments';
import passiveDocumentsReducer from './store/reducers/passiveDocuments';
import documentStatusReducer from './store/reducers/documentStatus';
import accountantDownloadsReducer from './store/reducers/accountantDownloads';
import logsReducer from './store/reducers/logs';
import resetPasswordReducer from './store/reducers/resetPassword';
import followingRequestReducer from './store/reducers/followRequest';
import preferencesReducer from './store/reducers/preference';

require('dotenv').config();

const composeEnhancers = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null || compose;

const rootReducer = combineReducers({
    auth: authReducer,
    users: usersReducer,
    subjects: subjectsReducer,
    accountants: accountantsReducer,
    activeDocuments: activeDocumentsReducer,
    passiveDocuments: passiveDocumentsReducer,
    documentStatus: documentStatusReducer,
    accountantDownloads: accountantDownloadsReducer,
    logs: logsReducer,
    resetPassword: resetPasswordReducer,
    followingRequests: followingRequestReducer,
    preferences: preferencesReducer
});

const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk)
));

const app = (
    <Provider store={store}>

        <BrowserRouter basename={process.env.PUBLIC_URL}>
            <App />
        </BrowserRouter>

    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
