import React, { Component } from 'react';

import {
    FormCard,
    FormTextInput,
    StandaloneFormPage,
} from "tabler-react";
import { Formik } from "formik";
import { toast } from 'react-toastify';
import { Redirect } from 'react-router'

import api from '../api';
import { parsingApiError } from '../shared/utility';

const strings = {
    title: "Registrazione commercialisti",
    buttonText: "Registrati",
    nameLabel: "Nome completo",
    namePlaceholder: "Inserisci nome",
    emailLabel: "Email Address",
    emailPlaceholder: "Enter email",
    passwordLabel: "Password",
    passwordPlaceholder: "Password",
    termsLabel: "Agree to the terms and policy",
};

class AccountantRegistrationPage extends Component {
    state = {
        success: false
    }
    render() {
        if (this.state.success) {
            return <Redirect to='/login' />;
        }
        return (
            <Formik
                initialValues={{
                    name: "",
                    email: "",
                    password_confirmation: "",
                    password: "",
                    vat_number: "",
                    business_name: "",
                }}
                validate={values => {
                    // same as above, but feel free to move this into a class method now.
                    let errors = {};
                    if (!values.email) {
                        errors.email = "Campo obbligatorio";
                    } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
                    ) {
                        errors.email = "Invalid email address";
                    }
                    if (!values.name) {
                        errors.name = "Campo obbligatorio";
                    }
                    if (!values.password) {
                        errors.password = "Campo obbligatorio";
                    }
                    if (!values.password_confirmation) {
                        errors.password_confirmation = "Campo obbligatorio";
                    } else if (values.password !== values.password_confirmation) {
                        errors.password = "Le password non coincidono";
                    }
                    if (!values.vat_number) {
                        errors.vat_number = "Campo obbligatorio";
                    } else if (values.vat_number.length < 11 || values.vat_number.length > 17) {
                        errors.vat_number = "Campo non valido";
                    }
                    if (!values.business_name) {
                        errors.business_name = "Campo obbligatorio";
                    }
                    return errors;
                }}
                onSubmit={(
                    values,
                    { setSubmitting, setErrors /* setValues and other goodies */ }
                ) => {
                    setSubmitting(true);
                    setTimeout(() => {
                        let data = { "data": {} };
                        data.data.type = 'accountant-registration';
                        data.data.attributes = {};
                        data.data.attributes.name = values.name;
                        data.data.attributes.email = values.email;
                        data.data.attributes.password = values.password;
                        data.data.attributes.password_confirmation = values.password_confirmation;
                        data.data.attributes.business_name = values.business_name;
                        data.data.attributes.vat_number = values.vat_number;
                        api.post('accountants/register', data)
                            .then(response => {
                                setSubmitting(false);
                                toast.success("Registrazione effettuata");
                                this.setState({
                                    ...this.state,
                                    success: true
                                });
                            })
                            .catch(errors => {
                                toast.error("Errore registrazione");
                                if (errors.response) {
                                    if (errors.response.status === 422) {
                                        setErrors(parsingApiError(errors.response.data.source));
                                    }
                                }
                                setSubmitting(false);
                            });
                    }, 400);
                }}

                render={({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                }) => (
                        <StandaloneFormPage>
                            <FormCard
                                buttonText={strings.buttonText}
                                title={strings.title}
                                disabled={isSubmitting}
                                onSubmit={handleSubmit}
                            >
                                <FormTextInput
                                    name="name"
                                    label={strings.nameLabel}
                                    placeholder={
                                        strings.namePlaceholder
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    invalid={(errors.name && errors.name) ? "invalid" : null}
                                    feedback={(errors.name && errors.name) ? errors.name : null}
                                />
                                <FormTextInput
                                    name='email'
                                    label='Email'
                                    placeholder='Inserisci email'
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    invalid={(errors.email && touched.email) ? "invalid" : null}
                                    feedback={(errors.email && touched.email) ? errors.email : null}
                                />
                                <FormTextInput
                                    name='business_name'
                                    label='Ragione sociale'
                                    placeholder='Ragione sociale'
                                    value={values.business_name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    invalid={(errors.business_name && touched.business_name) ? "invalid" : null}
                                    feedback={(errors.business_name && touched.business_name) ? errors.business_name : null}
                                />
                                <FormTextInput
                                    name='vat_number'
                                    label='Partita iva'
                                    placeholder='Partita iva'
                                    value={values.vat_number}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    invalid={(errors.vat_number && touched.vat_number) ? "invalid" : null}
                                    feedback={(errors.vat_number && touched.vat_number) ? errors.vat_number : null}
                                />
                                <FormTextInput
                                    name='password'
                                    label='Password'
                                    placeholder='Inserisci password'
                                    type="password"
                                    value={values.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    invalid={(errors.password && touched.password) ? "invalid" : null}
                                    feedback={(errors.password && touched.password) ? errors.password : null}
                                />
                                <FormTextInput
                                    name='password_confirmation'
                                    label='Conferma password'
                                    placeholder='Inserisci di nuovo la password'
                                    type="password"
                                    value={values.password_confirmation}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    invalid={(errors.password_confirmation && (touched.password_confirmation || touched.password)) ? "invalid" : null}
                                    feedback={(errors.password_confirmation && (touched.password_confirmation || touched.password)) ? errors.password_confirmation : null}
                                />

                            </FormCard>
                        </StandaloneFormPage>
                    )}
            />
        );
    }
}
export default AccountantRegistrationPage;