import React from 'react';

import { Badge } from 'tabler-react';
import Tooltip from 'react-tooltip-lite';

import Status from '../../shared/enums/DocumentStatus';

const documentStatus = (props) => {
    let color = null;
    switch (props.children) {
        case (Status.ACCEPTED):
            color = "success";
            break;
        case (Status.DELIVERED):
            color = "primary";
            break;
        case (Status.ELAPSED):
            color = "info";
            break;
        case (Status.IMPDEL):
            color = "danger";
            break;
        case (Status.NOTDEL):
            color = "warning";
            break;
        case (Status.RECEIVED):
            color = "secondary";
            break;
        case (Status.REFUSED):
            color = "danger";
            break;
        case (Status.REJECTED):
            color = "danger";
            break;
        case (Status.RJESITO):
            color = "danger";
            break;
        case (Status.SEND):
            color = "info";
            break;
        case (Status.SENDERR):
            color = "danger";
            break;
        case (Status.SENDING):
            color = "info";
            break;
        default:
            color = "secondary";
    }
    let content = <Tooltip content={props.description}>
        <Badge color={color} className="mr-1">
            {props.children}
        </Badge>
    </Tooltip>
    if (!props.description) {
        content = <Badge color={color} className="mr-1">
            {props.children}
        </Badge>
    }
    return (
        <div>
            {content}
        </div>
    );
}

export default documentStatus;