import React from 'react';

import { Form } from 'tabler-react';

const formGroupFeedback = (props) => {
    let feedback = "";
    if (props.feedback) {
        feedback = (<span className="invalid-feedback" style={style.feedback}>Campo obbligatorio</span>);
    }
    return (
        <Form.Group label={props.label}>
            {props.children}
            {feedback}
        </Form.Group>
    );
}

const style = {
    feedback: {
        display: 'block'
    }
}

export default formGroupFeedback;