import * as actionTypes from './actionTypes';
import api from '../../api';

export const resetPasswordStart = () => {
    return {
        type: actionTypes.RESET_PASSWORD_START
    };
};

export const resetPassword = (email) => {
    return dispatch => {
        const authData = {
            email: email
        };
        api.post('reset-password', authData)
        .then(response => {
            dispatch(resetPasswordSuccess());
        })
        .catch(error => {
            dispatch(resetPasswordFailed());
        });
    };
}

export const resetPasswordSuccess = () => {
    return {
        type: actionTypes.RESET_PASSWORD_SUCCESS
    };
};

export const resetPasswordFailed = () => {
    return {
        type: actionTypes.RESET_PASSWORD_FAILED
    };
};