import React from 'react';

import { Error500Page } from "tabler-react";

import api from '../api';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        // Add some default error states
        this.state = {
            error: false,
            info: null,
        };
    }

    componentDidCatch(error, info) {
        // Something happened to one of my children.
        // Add error to state
        this.setState({
            error: error,
            info: info,
        });

        const data = {
            env: process.env.REACT_APP_API_URL,
            error: error.toString(),
            info: info.componentStack,
        }
        if (process.env.NODE_ENV !== 'development') {
            api.post('panel-error', data);
        }
    }

    render() {
        if (this.state.error) {
            return (<Error500Page />
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;