import React from 'react';

import { Formik } from "formik";
import {
    Button,
    Grid,
    Card,
    Form
} from 'tabler-react';

import Status from '../../shared/enums/DocumentStatus';
import { isEmpty } from '../../shared/utility';

class PassiveDocumentUserFilter extends React.Component {
    state = {
        initialValues: {
            status: "",
            number: "",
            year: "",
            sender: "",
        }
    }

    handleSubmit = (values, {
        props = this.props,
        setSubmitting
    }) => {
        setSubmitting(false);
        props.onSubmit(values);
        return;
    }

    handleReset = (e) => {
        this.initialValues = this.state.initialValues;
    }

    initialValues = isEmpty(this.props.current) ? this.state.initialValues : this.props.current;

    render() {
        let statusOptions = null;
        statusOptions = Object.keys(Status).map(status => {
            return <option key={status}>{status}</option>
        })
        return (
            <Formik
                initialValues={this.initialValues}
                validate={values => {
                    let errors = {};
                    return errors;
                }}
                onSubmit={this.handleSubmit}
                v
                render={({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    handleReset,
                }) => (
                        <Card title="Filtri" isCollapsible isCollapsed={isEmpty(this.props.current) ? true : false}>
                            <Card.Body>
                                <Form onSubmit={handleSubmit}>
                                    <Grid.Row>
                                        <Grid.Col>
                                            <Form.Group label="Numero">
                                                <Form.Input
                                                    name="number"
                                                    placeholder="Numero"
                                                    value={values.number}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </Form.Group>
                                        </Grid.Col>
                                        <Grid.Col>
                                            <Form.Group label="Anno">
                                                <Form.Input
                                                    name="year"
                                                    placeholder="Anno"
                                                    value={values.year}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </Form.Group>
                                        </Grid.Col>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Col>
                                            <Form.Group label="Stato">
                                                <Form.Select
                                                    name="status"
                                                    value={values.status}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                >
                                                    <option></option>
                                                    {statusOptions}
                                                </Form.Select>
                                            </Form.Group>
                                        </Grid.Col>
                                        <Grid.Col>
                                            <Form.Group label="Mittente">
                                                <Form.Input
                                                    name="sender"
                                                    placeholder="Mittente"
                                                    value={values.sender}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </Form.Group>
                                        </Grid.Col>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Col>
                                            <Button.List>
                                                <Button color="cyan" type='submit' value='Submit' disabled={isSubmitting}>Cerca</Button>
                                                <Button
                                                    color="indigo"
                                                    type="reset"
                                                    value="Reset"
                                                    disabled={!touched || isSubmitting}
                                                    onClick={handleReset}>Reset</Button>
                                            </Button.List>
                                        </Grid.Col>
                                    </Grid.Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    )}
            />
        );
    }
}

export default PassiveDocumentUserFilter;