import React from 'react';

import { Formik } from "formik";
import {
    Button,
    Grid,
    Card,
    Form
} from 'tabler-react';

import { isEmpty } from '../../shared/utility';

class AccountantFilter extends React.Component {
    state = {
        initialValues: {
            business_name: "",
            email: ""
        }
    }

    handleSubmit = (values, {
        props = this.props,
        setSubmitting
    }) => {
        props.onSubmit(values);
        setSubmitting(false);
        return;
    }

    handleReset = (e) => {
        this.initialValues = this.state.initialValues;
    }

    initialValues = isEmpty(this.props.current) ? this.state.initialValues : this.props.current;

    render() {
        return (
            <Formik
                initialValues={this.initialValues}
                validate={values => {
                    let errors = {};
                    return errors;
                }}
                onSubmit={this.handleSubmit}
                onReset={this.handleReset}
                render={({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    handleReset,
                }) => (
                        <Card title="Filtri" isCollapsible isCollapsed={isEmpty(this.props.current) ? true : false}>
                            <Card.Body>
                                <Form onSubmit={handleSubmit}>
                                    <Grid.Row>
                                        <Grid.Col>
                                            <Form.Group label="Ragione sociale">
                                                <Form.Input
                                                    name="business_name"
                                                    placeholder="Ragione sociale"
                                                    value={values.business_name}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    invalid={(errors.business_name && touched.business_name) ? "invalid" : null}
                                                    feedback={(errors.business_name && touched.business_name) ? errors.business_name : null}
                                                />
                                            </Form.Group>
                                        </Grid.Col>
                                        <Grid.Col>
                                            <Form.Group label="Email">
                                                <Form.Input
                                                    name="email"
                                                    placeholder="Email"
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    invalid={(errors.email && touched.email) ? "invalid" : null}
                                                    feedback={(errors.email && touched.email) ? errors.email : null}
                                                />
                                            </Form.Group>
                                        </Grid.Col>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Col>
                                            <Button.List>
                                                <Button color="cyan" type='submit' value='Submit' disabled={isSubmitting}>Cerca</Button>
                                                <Button
                                                    color="indigo"
                                                    type="reset"
                                                    value="Reset"
                                                    disabled={!touched || isSubmitting}
                                                    onClick={handleReset}>Reset</Button>
                                            </Button.List>
                                        </Grid.Col>
                                    </Grid.Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    )}
            />
        );
    }
}

export default AccountantFilter;