import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Page,
    Dimmer,
    Card,
    Grid
} from 'tabler-react';

import SiteWrapper from "../hoc/SiteWrapper/SiteWrapper";
import * as actions from '../store/actions/index';
import PreferenceAccountant from '../components/Form/PreferenceAccountant';
import PreferenceSubject from '../components/Form/PreferenceSubject';
import Role from "../shared/enums/Role";

class SettignsPage extends Component {

    constructor() {
        super();
        this.state = {
            accountant: {
                initialValues: {
                    email: "",
                }
            },
            subject: {
                initialValues: {
                    emailPassive: false,
                    emailNotification: false,
                    attachPdf: false
                }
            }
        }
    }

    componentWillMount() {
        if (this.props.business) {
            this.props.onLoadPreference(this.props.business.type, this.props.business.id);
        }
    }

    render() {
        let content = null;
        if (this.props.me.roles[0] === Role.COMMERCIALISTA) {
            content = <PreferenceAccountant
                initialValues={this.props.preferences ? this.props.preferences.attributes : this.state.accountant.initialValues}
                business={this.props.business}/>;
        }
        if (this.props.me.roles[0] === Role.USER || this.props.me.roles[0] === Role.SUPERUSER) {
            content = <PreferenceSubject
                initialValues={this.props.preferences ? this.props.preferences.attributes : this.state.subject.initialValues}
                business={this.props.business} />;
        }
        return (
            <SiteWrapper me={this.props.me} business={this.props.business}>
                <Page.Content title="Preferenze">
                    <Grid.Row>
                        <Grid.Col>
                            <Dimmer active={this.props.loading ? true : false} loader>
                                <Card>
                                    <Card.Body>
                                        {content}
                                    </Card.Body>
                                </Card>
                            </Dimmer>
                        </Grid.Col>
                    </Grid.Row>
                </Page.Content>
            </SiteWrapper>
        );
    };
}

const mapStateToProps = state => {
    return {
        status: state.documentStatus.status,
        me: state.auth.me,
        business: state.auth.business,
        preferences: state.preferences.preferences
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onLoadPreference: (type, id) => dispatch(actions.loadPreferences(type, id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SettignsPage);