import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Page,
    Form,
    Card,
    Button,
    Table,
    Icon,
    Text,
} from 'tabler-react';
import FileBase64 from 'react-file-base64';
import Loader from 'react-loader-spinner'

import SiteWrapper from "../hoc/SiteWrapper/SiteWrapper";
import api from '../api';


class ActiveDocumentUploadPage extends Component {

    state = {
        files: [],
        isSubmitting: true
    }

    render() {
        let filesList = null;

        const getFiles = (files) => {
            this.setState({
                ...this.state,
                isSubmitting: false,
                files: files
            });
        }

        const upload = () => {
            this.setState({
                ...this.state,
                isSubmitting: true,
            });

            this.state.files.forEach((file, index) => {
                let current = this.state.files[index];
                let newFiles = this.state.files;
                let fileParts = file.base64.split(',')[1];
                current.sending = true;
                newFiles[index] = current;
                this.setState({
                    ...this.state,
                    files: newFiles,
                });
                if (file.type === "text/xml") {
                    let data = { "data": {} };
                    data.data.type = 'active-documents';
                    data.data.attributes = {};
                    data.data.attributes.base64 = fileParts;

                    api.post('documents/active/upload', data)
                    .then(response => {
                        current.sending = false;
                        current.result = {
                            status: 1
                        };
                        newFiles[index] = current;
                        this.setState({
                            ...this.state,
                            files: newFiles,
                        });
                    })
                    .catch(errors => {
                        current.sending = false;
                        current.result = {
                            message: errors.response.data ? errors.response.data.title : 'Errore nel salvataggio',
                            status: 0
                        };
                        newFiles[index] = current;
                        this.setState({
                            ...this.state,
                            files: newFiles,
                        });
                    });
                } else {
                    current.sending = false;
                    current.result = {
                        message: "Formato file non valido",
                        status: 0
                    };
                    newFiles[index] = current;
                    this.setState({
                        ...this.state,
                        files: newFiles,
                    });
                }
            });
            this.setState({
                ...this.state,
                isSubmitting: false,
            });
        }

        filesList = this.state.files.map((file, index) => {
            let statusCol = null;
            if (file.sending === true) {
                statusCol = <Loader
                    type="ThreeDots"
                    color="#2277b0"
                    height="50"
                    width="50"
                />
            }
            if (file.result) {
                if (file.result.status === 0) {
                    statusCol = <Text color="red"><Icon prefix="fa" name="exclamation-triangle" /> {file.result.message}</Text>
                }
                if (file.result.status === 1) {
                    statusCol = <Text color="green"><Icon prefix="fa" name="check-circle" /></Text>
                }
            }
            return <Table.Row key={index}>
                <Table.Col>{file.name}</Table.Col>
                <Table.Col>
                    {statusCol}
                </Table.Col>
            </Table.Row>
        });

        return (
            <SiteWrapper me={this.props.me} business={this.props.business}>
                <Page.Content title="Caricamento documenti">
                    <Card>
                        <Card.Body>
                            <Form.Group label="Seleziona uno o più file xml dal tuo computer">
                                <FileBase64
                                    multiple={true}
                                    onDone={getFiles} />
                            </Form.Group>
                            <Table>
                                <Table.Body>
                                    {filesList}
                                </Table.Body>
                            </Table>
                        </Card.Body>
                        <Card.Footer>
                            <div className="d-flex">
                                <Button type='submit' color="primary" disabled={this.state.isSubmitting} onClick={upload} className="ml-auto">Carica</Button>
                            </div>
                        </Card.Footer>
                    </Card>
                </Page.Content>
            </SiteWrapper>
        );
    };
}

const mapStateToProps = state => {
    return {
        me: state.auth.me,
        business: state.auth.business
    };
};

export default connect(mapStateToProps)(ActiveDocumentUploadPage);