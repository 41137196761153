import React, { Component } from 'react';

import {
    Form,
    Button
} from 'tabler-react';
import { toast } from 'react-toastify';
import { Formik } from "formik";

import { parsingApiError } from '../../shared/utility';
import api from '../../api'

class PreferenceAccountant extends Component {
    render() {
        let content = <Formik
            enableReinitialize
            initialValues={this.props.initialValues}
            validate={values => {
                let errors = {};
                if (values.email &&
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
                ) {
                    errors.email = "Indirizzo email errato";
                }
                return errors;
            }}
            onSubmit={(
                values,
                { setSubmitting, setErrors /* setValues and other goodies */ }
            ) => {
                setTimeout(() => {
                    let data = { "data": {} };
                    data.data.type = 'preferences';
                    data.data.attributes = {};
                    delete values.created_at;
                    delete values.updated_at;
                    data.data.attributes = values;
                    api.post("preferences/" + this.props.business.type + '/' + this.props.business.id, data)
                        .then(response => {
                            toast.success("Preferenze salvate");
                            setSubmitting(false);
                        })
                        .catch(errors => {
                            toast.error("Errore salvataggio preferenze");
                            if (errors.response) {
                                if (errors.response.status === 422) {
                                    setErrors(parsingApiError(errors.response.data.source));
                                }
                            }
                            setSubmitting(false);
                        });
                }, 400);
            }}
            render={({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
            }) => (
                    <Form onSubmit={handleSubmit}>
                        <Form.Group>
                            <Form.Input
                                name='email'
                                label='Email inoltro documenti'
                                placeholder='Email'
                                type="email"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                invalid={(errors.email && touched.email) ? "invalid" : null}
                                feedback={(errors.email && touched.email) ? errors.email : null}
                            />
                        </Form.Group>
                        <Form.Footer>
                            <Button type='submit' color="primary" disabled={isSubmitting}>Salva</Button>
                        </Form.Footer>
                    </Form>
                )}
        />;
        return content;
    }
}

export default PreferenceAccountant;