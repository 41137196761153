import React from 'react';

import { Formik } from "formik";
import {
    Button,
    Grid,
    Card,
    Form
} from 'tabler-react';

import api from '../../api';
import { isEmpty } from '../../shared/utility';

class UserFilter extends React.Component {
    state = {
        initialValues: {
            name: "",
            email: "",
            roles: {
                name: "",
            }
        },
        roles: []
    }

    componentDidMount() {
        api.get('users/roles').then(response => {
            this.setState({
                ...this.state,
                roles: response.data.data
            });
        }).catch(error => {

        });
    }

    handleSubmit = (values, {
        props = this.props,
        setSubmitting
    }) => {
        setSubmitting(false);
        props.onSubmit(values);
        return;
    }

    handleReset = (e) => {
        this.initialValues = this.state.initialValues;
    }

    initialValues = isEmpty(this.props.current) ? this.state.initialValues : this.props.current;

    render() {
        let rolesOptions = null;
        rolesOptions = this.state.roles.map(role => {
            return <option key={role.id} value={role.attributes.name}>{role.attributes.title}</option>
        })
        return (
            <Formik
                enableReinitialize
                initialValues={this.initialValues}
                validate={values => {
                    let errors = {};
                    return errors;
                }}
                onSubmit={this.handleSubmit}
                onReset={this.handleReset}
                render={({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    handleReset,
                }) => (
                        <Card title="Filtri" isCollapsible isCollapsed={isEmpty(this.props.current) ? true : false}>
                            <Card.Body>
                                <Form onSubmit={handleSubmit}>
                                    <Grid.Row>
                                        <Grid.Col>
                                            <Form.Group label="Nome">
                                                <Form.Input
                                                    name="name"
                                                    placeholder="Nome"
                                                    value={values.name}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </Form.Group>
                                        </Grid.Col>
                                        <Grid.Col>
                                            <Form.Group label="Email">
                                                <Form.Input
                                                    name="email"
                                                    placeholder="Email"
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </Form.Group>
                                        </Grid.Col>
                                        <Grid.Col>
                                            <Form.Group label="Ruolo">
                                                <Form.Select
                                                    name="roles.name"
                                                    value={values.roles.name}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                >
                                                    <option></option>
                                                    {rolesOptions}
                                                </Form.Select>
                                            </Form.Group>
                                        </Grid.Col>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Col>
                                            <Button.List>
                                                <Button color="cyan" type='submit' value='Submit' disabled={isSubmitting}>Cerca</Button>
                                                <Button
                                                    color="indigo"
                                                    type="reset"
                                                    value="Reset"
                                                    disabled={!touched || isSubmitting}
                                                    onClick={handleReset}>Reset</Button>
                                            </Button.List>
                                        </Grid.Col>
                                    </Grid.Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    )}
            />
        );
    }
}

export default UserFilter;