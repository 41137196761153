import React from 'react';

import { Form } from 'tabler-react';

const pageLength = (props) => {
    return (
        <div style={style.container}>
            Elementi visualizzati:
            <Form.Select onChange={(e) => props.onChange(e.target.value)} value={props.current ? props.current : 15}>
                <option value={15}>
                    15
                </option>
                <option value={30}>
                    30
                </option>
                <option value={50}>
                    50
                </option>
                <option value={100}>
                    100
                </option>
            </Form.Select>
        </div>
    );
}

const style = {
    container: {
        marginLeft: "auto"
    }
}

export default pageLength;