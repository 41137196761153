import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    subjects: null,
    meta: null,
    links: null,
    error: false,
    loading: true,
    empty: true,
    perPage: null,
    page: null,
    filters: null,
    include: null,
    order: null
};

const loadingStart = (state) => {
    return updateObject(state, { error: false, loading: true });
};

const setSubjects = (state, action) => {
    return updateObject(state, {
        subjects: action.subjects.data,
        meta: action.subjects.meta,
        links: action.subjects.links,
        error: false,
        loading: false,
        empty: false,
        vatNumber: null
    });
};

const fetchSubjectsFailed = (state) => {
    return updateObject(state, { error: true, loading: false });
};

const fetchSubjectsEmpty = (state) => {
    return updateObject(state, { error: false, loading: false, empty: true, subjects: null });
};

const setSubjectVatNumber = (state, action) => {
    return updateObject(state, { vatNumber: action.vatNumber });
}
const setSubjectTableStatus = (state, action) => {
    return updateObject(state, {
        page: action.page,
        perPage: action.perPage,
        filters: action.filters,
        include: action.include,
        order: action.order
     });
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOADING_START: return loadingStart(state);
        case actionTypes.SET_SUBJECTS: return setSubjects(state, action);
        case actionTypes.FETCH_SUBJECTS_FAILED: return fetchSubjectsFailed(state);
        case actionTypes.FETCH_SUBJECTS_EMPTY: return fetchSubjectsEmpty(state);
        case actionTypes.SET_SUBJECT_VAT_NUMBER: return setSubjectVatNumber(state, action);
        case actionTypes.SET_SUBJECT_TABLE_STATUS: return setSubjectTableStatus(state, action);
        default: return state;
    }
};

export default reducer;