// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
    Page,
    Grid,
    Card,
    Alert,
    StampCard,
    StatsCard,
    Table,
} from "tabler-react";

import C3Chart from "react-c3js";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { Redirect, Link } from 'react-router-dom';

import SiteWrapper from "../hoc/SiteWrapper/SiteWrapper";
import ResourceRemaining from '../components/UI/ResourceRemainingBox';
import PieChart from '../components/UI/PieChart';
import api from "../api";
import * as actions from '../store/actions/index';
import SelectSubject from '../components/Form/SelectSubject';
import Role from "../shared/enums/Role";

const moment = extendMoment(Moment);
class Home extends Component {
    constructor() {
        super();
        this.state = {
            statistics: {
                subjects_count: 0,
                subjects_count_no_credit: 0,
                active_count: 0,
                active_pa_count: 0,
                passive_count: 0,
                passive_unassigned_count: 0,
                accountants_count: 0,
                accountants_subject_count: 0,
                active_last_week_daily_count: [],
                passive_last_week_daily_count: [],
                active_today_count: 0,
                passive_today_count: 0,
                resource_remainig: 0,
                resource_last_purchase: null,
            },
            toSubject: null,
        }
    }

    componentDidMount() {
        api.get('statistics').then(response => {
            this.setState({
                ...this.state,
                statistics: response.data.data.attributes
            });
        }).catch(error => {

        });
        if (this.props.me.roles[0] === Role.COMMERCIALISTA) {
            this.props.onLoadSubjects();
        }
    }
    createKeys() {
        let end = moment({ hour: 23, minute: 59, seconds: 59 }).subtract(1, 'days');
        let start = moment({ hour: 0, minute: 0, seconds: 0 }).subtract(7, 'days');
        const range = moment.range(start, end);
        let keys = [];
        for (let day of range.by('days')) {
            keys.push(day.format("DD/MM/YYYY"));
        }
        return keys;
    }

    handlesubjectSelect(subjectId) {
        this.setState({
            ...this.state,
            toSubject: subjectId
        });
    }

    render() {
        if (this.state.toSubject) {
            const url = "/subjects/" + this.state.toSubject;
            return <Redirect to={url} />
        }
        let alert = null;
        let subjectHeader = (
            <div>
                0 <small>Soggetti</small>
            </div>
        );
        let subjectFooter = (
            <div>
                0 senza credito
                    </div>
        );
        let activeHeader = (
            <div>
                0 <small>Ciclo attivo</small>
            </div>
        );
        let activeFooter = (
            <div>
                0 a pubblica amministrazione
                </div>
        );
        let passiveHeader = (
            <div>
                0 <small>Ciclo passivo</small>
            </div>
        );
        let passiveFooter = (
            <div>
                0 non assegnate
            </div>
        );
        let accountantHeader = (
            <div>
                0 <small>Commercialisti</small>
            </div>
        );
        let accountantFooter = (
            <div>
                0 soggetti
            </div>
        );
        let activeDiff = 0;
        let passiveDiff = 0;
        let activeColumns = [];
        let passiveColumns = [];
        let row1 = null;
        let row2 = null;
        let row3 = null;
        let statusActiveData = [];
        let statusActiveName = {};
        let statusPassiveData = [];
        let statusPassiveName = {};
        const dailyKeys = this.createKeys();

        if (this.state.statistics) {
            if (this.state.statistics.subjects_count) {
                subjectHeader = (
                    <div>
                        <Link to="/subjects">{this.state.statistics.subjects_count} <small>Soggetti</small></Link>
                    </div>
                );
                subjectFooter = (
                    <div>
                        { this.state.statistics.subjects_count_no_credit } senza credito
                    </div>
                );
            }
            if (this.state.statistics.active_count) {
                activeHeader = (
                    <div>
                        <Link to="/documents/active">{this.state.statistics.active_count} <small>Ciclo attivo</small></Link>
                    </div>
                );
                activeFooter = (
                    <div>
                        {this.state.statistics.active_pa_count} a pubblica amministrazione
                    </div>
                );
            }
            if (this.state.statistics.passive_count) {
                passiveHeader = (
                    <div>
                        <Link to="/documents/passive">{this.state.statistics.passive_count} <small>Ciclo passivo</small></Link>
                    </div>
                );
                passiveFooter = (
                    <div>
                        {this.state.statistics.passive_unassigned_count} non assegnate
                    </div>
                );
            }
            if (this.state.statistics.accountants_count) {
                accountantHeader = (
                    <div>
                        <Link to="/accountants">{this.state.statistics.accountants_count} <small>Commercialisti</small></Link>
                    </div>
                );
                accountantFooter = (
                    <div>
                        {this.state.statistics.accountants_subject_count} soggetti
                    </div>
                );
            }
            if (this.state.statistics.active_last_week_daily_count) {
                let activeDailyCount = this.state.statistics.active_last_week_daily_count;
                activeColumns = Object.keys(activeDailyCount).map(function (key) {
                    return activeDailyCount[key];
                });
                if (this.state.statistics.active_today_count > 0) {
                    const activeAvg = activeColumns.reduce((a, b) => (a + b), 0) / activeColumns.length;
                    activeDiff = Math.trunc(((this.state.statistics.active_today_count - activeAvg) / activeAvg) * 100);
                }
                activeColumns.unshift("data1");
            }
            if (this.state.statistics.passive_last_week_daily_count) {
                let passiveDailyCount = this.state.statistics.passive_last_week_daily_count;
                passiveColumns = Object.keys(passiveDailyCount).map(function (key) {
                    return passiveDailyCount[key];
                });
                if (this.state.statistics.passive_today_count > 0) {
                    const passiveAvg = passiveColumns.reduce((a, b) => (a + b), 0) / passiveColumns.length;
                    passiveDiff = Math.trunc(((this.state.statistics.passive_today_count - passiveAvg) / passiveAvg) * 100);
                }
                passiveColumns.unshift("data1");
            }
            if (this.state.statistics.active_status_distribution) {
                this.state.statistics.active_status_distribution.forEach((status, index) => {
                    const dataIndex = "data" + (index + 1);
                    statusActiveData.push([dataIndex, status.count]);
                    statusActiveName[dataIndex] = status.status;
                });
            }
            if (this.state.statistics.passive_status_distribution) {
                this.state.statistics.passive_status_distribution.forEach((status, index) => {
                    const dataIndex = "data" + (index + 1);
                    statusPassiveData.push([dataIndex, status.count]);
                    statusPassiveName[dataIndex] = status.status;
                });
            }

            if (this.props.me.roles[0] === 'superadmin') {
                row1 = (
                    <Grid.Row>
                        <Grid.Col sm={6} lg={3}>
                            <StampCard
                                color="blue"
                                icon="upload"
                                header={activeHeader}
                                footer={activeFooter}
                            />
                        </Grid.Col>
                        <Grid.Col sm={6} lg={3}>
                            <StampCard
                                color="green"
                                icon="download"
                                header={passiveHeader}
                                footer={passiveFooter}
                            />
                        </Grid.Col>
                        <Grid.Col sm={6} lg={3}>
                            <StampCard
                                color="yellow"
                                icon="users"
                                header={subjectHeader}
                                footer={subjectFooter}
                            />
                        </Grid.Col>
                        <Grid.Col sm={6} lg={3}>
                            <StampCard
                                color="purple"
                                icon="dollar-sign"
                                header={accountantHeader}
                                footer={accountantFooter}
                            />
                        </Grid.Col>
                    </Grid.Row>
                );
                row2 = (
                    <Grid.Row>
                        <Grid.Col lg={6}>
                            <Card>
                                <Card.Header>
                                    <Card.Title>Numero documenti emessi 7gg</Card.Title>
                                </Card.Header>
                                <StatsCard
                                    layout={2}
                                    movement={activeDiff}
                                    total={this.state.statistics.active_today_count}
                                    label="Emessi oggi"
                                    chart={
                                        <C3Chart
                                            style={{ height: "100%" }}
                                            padding={{
                                                bottom: -10,
                                                left: -1,
                                                right: -1,
                                            }}
                                            data={{
                                                names: {
                                                    data1: "Emessi",
                                                },
                                                columns: [
                                                    activeColumns
                                                ],
                                                type: "area",
                                            }}
                                            legend={{
                                                show: false,
                                            }}
                                            transition={{
                                                duration: 0,
                                            }}
                                            point={{
                                                show: false,
                                            }}
                                            tooltip={{
                                                format: {
                                                    title: function (x) {
                                                        return dailyKeys[x];
                                                    }
                                                },
                                            }}
                                            axis={{
                                                y: {
                                                    padding: {
                                                        bottom: 0,
                                                    },
                                                    show: true,
                                                    tick: {
                                                        outer: false,
                                                    },
                                                },
                                                x: {
                                                    padding: {
                                                        left: 0,
                                                        right: 0,
                                                    },
                                                    show: false,
                                                },
                                            }}
                                            color={{
                                                pattern: ["#e74c3c"],
                                            }}
                                        />
                                    }
                                />
                            </Card>
                        </Grid.Col>
                        <Grid.Col lg={6}>
                            <Card>
                                <Card.Header>
                                    <Card.Title>Numero documenti ricevuti 7gg</Card.Title>
                                </Card.Header>
                                <StatsCard
                                    layout={2}
                                    movement={passiveDiff}
                                    total={this.state.statistics.passive_today_count}
                                    label="Ricevuti oggi"
                                    chart={
                                        <C3Chart
                                            style={{ height: "100%" }}
                                            padding={{
                                                bottom: -10,
                                                left: -1,
                                                right: -1,
                                            }}
                                            data={{
                                                names: {
                                                    data1: "Ricevuti",
                                                },
                                                columns: [passiveColumns],
                                                type: "area",
                                            }}
                                            legend={{
                                                show: false,
                                            }}
                                            transition={{
                                                duration: 0,
                                            }}
                                            point={{
                                                show: false,
                                            }}
                                            tooltip={{
                                                format: {
                                                    title: function (x) {
                                                        return dailyKeys[x];
                                                    },
                                                },
                                            }}
                                            axis={{
                                                y: {
                                                    padding: {
                                                        bottom: 0,
                                                    },
                                                    show: false,
                                                    tick: {
                                                        outer: false,
                                                    },
                                                },
                                                x: {
                                                    padding: {
                                                        left: 0,
                                                        right: 0,
                                                    },
                                                    show: false,
                                                },
                                            }}
                                            color={{
                                                pattern: ["#5eba00"],
                                            }}
                                        />
                                    }
                                />
                            </Card>
                        </Grid.Col>
                    </Grid.Row>
                );
                row3 = (
                    <Grid.Row>
                        <Grid.Col sm={6}>
                            <PieChart title="Stato attive" datas={statusActiveData} names={statusActiveName}/>
                        </Grid.Col>
                        <Grid.Col sm={6}>
                            <PieChart title="Stato passive" datas={statusPassiveData} names={statusPassiveName} />
                        </Grid.Col>
                    </Grid.Row>
                );
            } else if (this.props.me.roles[0] === 'admin') {
                row1 = (
                    <Grid.Row>
                        <Grid.Col sm={6} lg={3}>
                            <StampCard
                                color="blue"
                                icon="upload"
                                header={activeHeader}
                                footer={activeFooter}
                            />
                        </Grid.Col>
                        <Grid.Col sm={6} lg={3}>
                            <StampCard
                                color="green"
                                icon="download"
                                header={passiveHeader}
                                footer={passiveFooter}
                            />
                        </Grid.Col>
                        <Grid.Col sm={6} lg={3}>
                            <StampCard
                                color="yellow"
                                icon="users"
                                header={subjectHeader}
                                footer={subjectFooter}
                            />
                        </Grid.Col>
                        <Grid.Col sm={6} lg={3}>
                            <StampCard
                                color="purple"
                                icon="dollar-sign"
                                header={accountantHeader}
                                footer={accountantFooter}
                            />
                        </Grid.Col>
                    </Grid.Row>
                );
                row2 = (
                    <Grid.Row>
                        <Grid.Col lg={6}>
                            <Card>
                                <Card.Header>
                                    <Card.Title>Numero documenti emessi 7gg</Card.Title>
                                </Card.Header>
                                <StatsCard
                                    layout={2}
                                    movement={activeDiff}
                                    total={this.state.statistics.active_today_count}
                                    label="Emessi oggi"
                                    chart={
                                        <C3Chart
                                            style={{ height: "100%" }}
                                            padding={{
                                                bottom: -10,
                                                left: -1,
                                                right: -1,
                                            }}
                                            data={{
                                                names: {
                                                    data1: "Emessi",
                                                },
                                                columns: [
                                                    activeColumns
                                                ],
                                                type: "area",
                                            }}
                                            legend={{
                                                show: false,
                                            }}
                                            transition={{
                                                duration: 0,
                                            }}
                                            point={{
                                                show: false,
                                            }}
                                            tooltip={{
                                                format: {
                                                    title: function (x) {
                                                        return dailyKeys[x];
                                                    }
                                                },
                                            }}
                                            axis={{
                                                y: {
                                                    padding: {
                                                        bottom: 0,
                                                    },
                                                    show: true,
                                                    tick: {
                                                        outer: false,
                                                    },
                                                },
                                                x: {
                                                    padding: {
                                                        left: 0,
                                                        right: 0,
                                                    },
                                                    show: false,
                                                },
                                            }}
                                            color={{
                                                pattern: ["#e74c3c"],
                                            }}
                                        />
                                    }
                                />
                            </Card>
                        </Grid.Col>
                        <Grid.Col lg={6}>
                            <Card>
                                <Card.Header>
                                    <Card.Title>Numero documenti ricevuti 7gg</Card.Title>
                                </Card.Header>
                                <StatsCard
                                    layout={2}
                                    movement={passiveDiff}
                                    total={this.state.statistics.passive_today_count}
                                    label="Ricevuti oggi"
                                    chart={
                                        <C3Chart
                                            style={{ height: "100%" }}
                                            padding={{
                                                bottom: -10,
                                                left: -1,
                                                right: -1,
                                            }}
                                            data={{
                                                names: {
                                                    data1: "Ricevuti",
                                                },
                                                columns: [passiveColumns],
                                                type: "area",
                                            }}
                                            legend={{
                                                show: false,
                                            }}
                                            transition={{
                                                duration: 0,
                                            }}
                                            point={{
                                                show: false,
                                            }}
                                            tooltip={{
                                                format: {
                                                    title: function (x) {
                                                        return dailyKeys[x];
                                                    },
                                                },
                                            }}
                                            axis={{
                                                y: {
                                                    padding: {
                                                        bottom: 0,
                                                    },
                                                    show: false,
                                                    tick: {
                                                        outer: false,
                                                    },
                                                },
                                                x: {
                                                    padding: {
                                                        left: 0,
                                                        right: 0,
                                                    },
                                                    show: false,
                                                },
                                            }}
                                            color={{
                                                pattern: ["#5eba00"],
                                            }}
                                        />
                                    }
                                />
                            </Card>
                        </Grid.Col>
                    </Grid.Row>
                );
                row3 = (
                    <Grid.Row>
                        <Grid.Col sm={6}>
                            <PieChart title="Stato attive" datas={statusActiveData} names={statusActiveName} />
                        </Grid.Col>
                        <Grid.Col sm={6}>
                            <PieChart title="Stato passive" datas={statusPassiveData} names={statusPassiveName} />
                        </Grid.Col>
                    </Grid.Row>
                );
            } else if (this.props.me.roles[0] === 'user') {
                row1 = (
                    <Grid.Row>
                        <Grid.Col sm={6} lg={4}>
                            <ResourceRemaining
                                amount={this.state.statistics.resource_remainig}
                                from={this.state.statistics.resource_last_purchase}>
                            ></ResourceRemaining>
                        </Grid.Col>
                        <Grid.Col sm={6} lg={4}>
                            <StampCard
                                color="blue"
                                icon="upload"
                                header={activeHeader}
                                footer={activeFooter}
                            />
                        </Grid.Col>
                        <Grid.Col sm={6} lg={4}>
                            <StampCard
                                color="green"
                                icon="download"
                                header={passiveHeader}
                            />
                        </Grid.Col>
                    </Grid.Row>
                );
                row2 = (
                    <Grid.Row>
                        <Grid.Col lg={6}>
                            <Card>
                                <Card.Header>
                                    <Card.Title>Numero documenti emessi 7gg</Card.Title>
                                </Card.Header>
                                <StatsCard
                                    layout={2}
                                    movement={activeDiff}
                                    total={this.state.statistics.active_today_count}
                                    label="Emessi oggi"
                                    chart={
                                        <C3Chart
                                            style={{ height: "100%" }}
                                            padding={{
                                                bottom: -10,
                                                left: -1,
                                                right: -1,
                                            }}
                                            data={{
                                                names: {
                                                    data1: "Emessi",
                                                },
                                                columns: [
                                                    activeColumns
                                                ],
                                                type: "area",
                                            }}
                                            legend={{
                                                show: false,
                                            }}
                                            transition={{
                                                duration: 0,
                                            }}
                                            point={{
                                                show: false,
                                            }}
                                            tooltip={{
                                                format: {
                                                    title: function (x) {
                                                        return dailyKeys[x];
                                                    }
                                                },
                                            }}
                                            axis={{
                                                y: {
                                                    padding: {
                                                        bottom: 0,
                                                    },
                                                    show: true,
                                                    tick: {
                                                        outer: false,
                                                    },
                                                },
                                                x: {
                                                    padding: {
                                                        left: 0,
                                                        right: 0,
                                                    },
                                                    show: false,
                                                },
                                            }}
                                            color={{
                                                pattern: ["#e74c3c"],
                                            }}
                                        />
                                    }
                                />
                            </Card>
                        </Grid.Col>
                        <Grid.Col lg={6}>
                            <Card>
                                <Card.Header>
                                    <Card.Title>Numero documenti ricevuti 7gg</Card.Title>
                                </Card.Header>
                                <StatsCard
                                    layout={2}
                                    movement={passiveDiff}
                                    total={this.state.statistics.passive_today_count}
                                    label="Ricevuti oggi"
                                    chart={
                                        <C3Chart
                                            style={{ height: "100%" }}
                                            padding={{
                                                bottom: -10,
                                                left: -1,
                                                right: -1,
                                            }}
                                            data={{
                                                names: {
                                                    data1: "Ricevuti",
                                                },
                                                columns: [passiveColumns],
                                                type: "area",
                                            }}
                                            legend={{
                                                show: false,
                                            }}
                                            transition={{
                                                duration: 0,
                                            }}
                                            point={{
                                                show: false,
                                            }}
                                            tooltip={{
                                                format: {
                                                    title: function (x) {
                                                        return dailyKeys[x];
                                                    },
                                                },
                                            }}
                                            axis={{
                                                y: {
                                                    padding: {
                                                        bottom: 0,
                                                    },
                                                    show: false,
                                                    tick: {
                                                        outer: false,
                                                    },
                                                },
                                                x: {
                                                    padding: {
                                                        left: 0,
                                                        right: 0,
                                                    },
                                                    show: false,
                                                },
                                            }}
                                            color={{
                                                pattern: ["#5eba00"],
                                            }}
                                        />
                                    }
                                />
                            </Card>
                        </Grid.Col>
                    </Grid.Row>
                );

            } else if (this.props.me.roles[0] === Role.COMMERCIALISTA) {
                row1 = (
                    <Grid.Row alignItems="right">
                        <Grid.Col sm={6} lg={3}>
                            <ResourceRemaining
                                amount={this.state.statistics.resource_remainig}
                                from={this.state.statistics.resource_last_purchase}>
                                ></ResourceRemaining>
                        </Grid.Col>
                        <Grid.Col sm={6} lg={3}>
                            <StampCard
                                color="yellow"
                                icon="users"
                                header={subjectHeader}
                            />
                        </Grid.Col>
                        <Grid.Col sm={6} offsetLg={2} lg={4}>
                            <SelectSubject subjects={this.props.subjects} onChange={this.handlesubjectSelect.bind(this)} />
                        </Grid.Col>
                    </Grid.Row>
                );

                let rows = (<Table.Row>
                    <Table.Col colSpan={5}>Nessun dato presente</Table.Col>
                </Table.Row>);
                if (this.state.statistics.downloads) {
                    rows = this.state.statistics.downloads.data.map((download) => {
                        return <Table.Row key={download.id}>
                            <Table.Col>{download.relationships.subject.data.attributes.business_name}</Table.Col>
                            <Table.Col>{download.attributes.from}</Table.Col>
                            <Table.Col>{download.attributes.to}</Table.Col>
                            <Table.Col>{download.attributes.type}</Table.Col>
                            <Table.Col>{download.attributes.created_at}</Table.Col>
                        </Table.Row>;
                    });
                }

                row2 = (<Card title="Ultimi download">
                    <Card.Body>
                        <Table>
                            <Table.Header>
                                <Table.Row>
                                    <Table.ColHeader>Soggetto</Table.ColHeader>
                                    <Table.ColHeader>Da</Table.ColHeader>
                                    <Table.ColHeader>A</Table.ColHeader>
                                    <Table.ColHeader>Tipo</Table.ColHeader>
                                    <Table.ColHeader>Data</Table.ColHeader>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {rows}
                            </Table.Body>
                        </Table>
                    </Card.Body>
                </Card>);
            }
            if (this.state.statistics.resource_remainig === 0) {
                alert = (<Alert type="danger" icon="alert-triangle">
                    Non hai più documenti disponibili è necessario acquistare una ricarica. <a href="https://fatturafatta.it" target="_blank" rel="noopener noreferrer">Clicca qui</a> per procedere all'acquisto
                    </Alert>);
            } else if (this.state.statistics.resource_remainig < 10 && this.state.statistics.resource_remainig > 0) {
                alert = (<Alert type="warning" icon="alert-triangle">
                    Stai per esaurire i documenti disponibili. <a href="https://fatturafatta.it" target="_blank" rel="noopener noreferrer">Clicca qui</a> per procedere all'acquisto
                    </Alert>);
            }
        }
        return (
            <SiteWrapper me={this.props.me} business={this.props.business}>
                <Page.Content title="Dashboard">
                    {alert}
                    {row1}
                    {row2}
                    {row3}
                </Page.Content>
            </SiteWrapper>
        );
    }
}

const mapStateToProps = state => {
    return {
        me: state.auth.me,
        business: state.auth.business,
        subjects: state.subjects.subjects,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onLoadSubjects: () => dispatch(actions.loadSubjects(null, null, [], [], {}, "all=true")),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
