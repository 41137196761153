import React, { Component } from 'react';
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import CookieBanner from 'react-cookie-banner';

import LoginPage from "./pages/LoginPage";
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ChangePasswordPage from './pages/ChangePasswordPage';
import AccountantRegistrationPage from './pages/AccountantRegistrationPage';
import AcceptAccountantPage from './pages/AcceptAccountantPage';
import * as actions from './store/actions/index';
import ResolveRoute from './components/ResolveRoute';

import {
    Dimmer
} from 'tabler-react';

import "tabler-react/dist/Tabler.css";
import ErrorBoundary from './components/ErrorBoundary';

class App extends Component {
    constructor() {
        super();
        this.state = {
            autoSignupStarted: false,
        }
    }

    componentDidMount () {
        this.props.onTryAutoSignup();
        this.setState({autoSignupStarted: true});
    }

    render() {
        let routes = null;
        if (this.props.loading === false && this.state.autoSignupStarted === true) {
            if (this.props.isAuthenticated) {
                routes = <ResolveRoute role={this.props.me.roles[0]} />;
            } else {
                routes = (
                    <Switch>
                        <Route exact path="/login" component={LoginPage} />
                        <Route exact path="/change-password" component={ChangePasswordPage} />
                        <Route exact path="/password-dimenticata" component={ForgotPasswordPage} />
                        <Route exact path="/accountant-registration" component={AccountantRegistrationPage} />
                        <Route exact path="/confirm-accountant" component={AcceptAccountantPage} />
                        <Redirect to="/login" />
                    </Switch>
                );
            }
        }
        return (
            <ErrorBoundary>
                <CookieBanner
                    message="Questo sito utilizza i cookie per personalizzare la tua esperienza."
                    onAccept={() => { }}
                    cookie="user-has-accepted-cookies" />
                <Dimmer active={this.props.loading ? true : false} loader>{routes}</Dimmer>
            </ErrorBoundary>
        );
    }
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null,
        loading: state.auth.loading,
        me: state.auth.me
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onTryAutoSignup: () => dispatch(actions.authCheckState())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
