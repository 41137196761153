import React from 'react';

import { Formik } from "formik";
import {
    Button,
    Grid,
    Card,
    Form
} from 'tabler-react';
import * as moment from 'moment';

import Status from '../../shared/enums/DocumentStatus';
import api from '../../api';
import { isEmpty } from '../../shared/utility';
import Role from "../../shared/enums/Role";
import SelectWrapperComponent from '../SelectWrapperComponent';

class ActiveDocumentFilter extends React.Component {
    state = {
        initialValues: {
            uuid: "",
            status: "",
            number: "",
            year: "",
            date: "",
            recipient: "",
            subject: {
                id: ""
            },
            format: "",
        },
        subjects: [],
        currentValue: null,
        subjectOptions: []
    }

    componentDidMount() {
        let filter = "&order_by_asc=business_name";
        if (this.props.role === Role.COMMERCIALISTA) {
            filter += "&filter[accountant.uuid]=" + this.props.userableId;
        }
        api.get('subjects?all' + filter).then(response => {
            let subjectsOptions = [];
            let defaultOption = {};
            if (response.data.data.length > 0) {
                response.data.data.forEach(subject => {
                    let option = {
                        value: subject.id,
                        label: subject.attributes.vat_number + " - " + subject.attributes.business_name
                    };
                    if (!isEmpty(this.props.current)) {
                        if (this.props.current.subject.id === subject.id) {
                            defaultOption = option;
                        }
                    }
                    subjectsOptions.push(option);
                });
            }
            this.setState({
                ...this.state,
                subjects: response.data.data,
                currentValue: defaultOption,
                subjectOptions: subjectsOptions
            });
        }).catch(error => {
            console.log(error);
        });
    }

    handleSubmit = (values, {
        props = this.props,
        setSubmitting
    }) => {
        setSubmitting(false);
        if (values.date !== "") {
            values.date = moment(values.date, ["DD/MM/YYYY", "YYYY-MM-DD"]).format("YYYY-MM-DD");
        }
        values.subject.id = this.state.currentValue !== null && !isEmpty(this.state.currentValue) ? this.state.currentValue.value : "";
        props.onSubmit(values);
        return;
    }

    handleReset = (e) => {
        this.initialValues = this.state.initialValues;
    }

    initialValues = isEmpty(this.props.current) ? this.state.initialValues : this.props.current;

    render() {
        let statusOptions = null;
        statusOptions = Object.keys(Status).map(status => {
            return <option key={status}>{status}</option>
        })
        return (
            <Formik
                enableReinitialize
                initialValues={this.initialValues}
                onSubmit={this.handleSubmit}
                onReset={this.handleReset}
                render={({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    handleReset,
                }) => (
                        <Card title="Filtri" isCollapsible isCollapsed={isEmpty(this.props.current) ? true : false}>
                        <Card.Body>
                            <Form onSubmit={handleSubmit}>
                                <Grid.Row>
                                    <Grid.Col>
                                        <Form.Group label="Identificativo">
                                            <Form.Input
                                                name="uuid"
                                                placeholder="ID"
                                                value={values.uuid}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                        </Form.Group>
                                    </Grid.Col>
                                    <Grid.Col>
                                        <Form.Group label="Stato">
                                            <Form.Select
                                                name="status"
                                                value={values.status}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            >
                                                <option></option>
                                                {statusOptions}
                                            </Form.Select>
                                        </Form.Group>
                                    </Grid.Col>
                                    <Grid.Col>
                                        <Form.Group label="Soggetto">
                                            <SelectWrapperComponent
                                                options={this.state.subjectOptions}
                                                value={values.subject.id}
                                                onChange={(name, value) => {
                                                    if (value) {
                                                        values.subject.id = value.value;
                                                    } else {
                                                        values.subject.id = "";
                                                    }
                                                    this.setState({
                                                        ...this.state,
                                                        currentValue: value
                                                    });
                                                }}
                                                onBlur={handleBlur}
                                                selectName="subject.id"
                                                isMulti={false}
                                                closeMenuOnSelect={true}
                                                isClearable={true}
                                                selectedValue={this.state.currentValue}
                                            />
                                        </Form.Group>
                                    </Grid.Col>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Col>
                                        <Form.Group label="Numero">
                                            <Form.Input
                                                name="number"
                                                placeholder="Numero"
                                                value={values.number}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                        </Form.Group>
                                    </Grid.Col>
                                    <Grid.Col>
                                        <Form.Group label="Anno">
                                            <Form.Input
                                                name="year"
                                                placeholder="Anno"
                                                value={values.year}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                        </Form.Group>
                                    </Grid.Col>
                                    <Grid.Col>
                                        <Form.Group label="Data">
                                            <Form.MaskedInput
                                                name="date"
                                                placeholder="gg/mm/aaaa"
                                                value={moment(values.date, 'YYYY-MM-DD', true).isValid() ? moment(values.date, "YYYY-MM-DD").format("DD/MM/YYYY") : values.date}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                mask={[
                                                    /\d/,
                                                    /\d/,
                                                    "/",
                                                    /\d/,
                                                    /\d/,
                                                    "/",
                                                    /\d/,
                                                    /\d/,
                                                    /\d/,
                                                    /\d/,
                                                ]}
                                            />
                                        </Form.Group>
                                    </Grid.Col>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Col>
                                        <Form.Group label="Destinatario">
                                            <Form.Input
                                                name="recipient"
                                                placeholder="Destinatario"
                                                value={values.recipient}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                        </Form.Group>
                                    </Grid.Col>
                                        <Grid.Col>
                                            <Form.Group label="Tipo Destinatario">
                                                <Form.Select
                                                    name="format"
                                                    value={values.format}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                >
                                                    <option></option>
                                                    <option value="FPA12">Pubblica amministrazione</option>
                                                    <option value="FPR12">Privato</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Grid.Col>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Col>
                                        <Button.List>
                                            <Button color="cyan" type='submit' value='Submit' disabled={isSubmitting}>Cerca</Button>
                                            <Button
                                                color="indigo"
                                                type="reset"
                                                value="Reset"
                                                disabled={!touched || isSubmitting}
                                                onClick={(e) => {
                                                    this.setState({
                                                        ...this.state,
                                                        currentValue: null
                                                    });
                                                    handleReset(e)
                                                }}>Reset</Button>
                                        </Button.List>
                                    </Grid.Col>
                                </Grid.Row>
                            </Form>
                        </Card.Body>
                    </Card>
                )}
            />
        );
    }
}

export default ActiveDocumentFilter;