import React, { Component } from 'react';

import {
    Form,
    Button
} from 'tabler-react';
import { toast } from 'react-toastify';
import { Formik } from "formik";

import { parsingApiError } from '../../shared/utility';
import api from '../../api'

class PreferenceSubject extends Component {
    render() {
        let content = <Formik
            enableReinitialize
            initialValues={this.props.initialValues}
            onSubmit={(
                values,
                { setSubmitting, setErrors /* setValues and other goodies */ }
            ) => {
                setTimeout(() => {
                    let data = { "data": {} };
                    data.data.type = 'preferences';
                    data.data.attributes = {};
                    delete values.created_at;
                    delete values.updated_at;
                    data.data.attributes = values;
                    api.post("preferences/" + this.props.business.type + '/' + this.props.business.id, data)
                        .then(response => {
                            toast.success("Preferenze salvate");
                            setSubmitting(false);
                        })
                        .catch(errors => {
                            toast.error("Errore salvataggio preferenze");
                            if (errors.response) {
                                if (errors.response.status === 422) {
                                    setErrors(parsingApiError(errors.response.data.source));
                                }
                            }
                            setSubmitting(false);
                        });
                }, 400);
            }}
            render={({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
            }) => (
                    <Form onSubmit={handleSubmit}>
                        <Form.Group label="Notifiche mail">
                            <Form.Checkbox
                                isInline
                                label="Mail ricezione documenti"
                                name="emailPassive"
                                value="emailPassive"
                                onChange={handleChange}
                                checked={values.emailPassive}
                            />
                            <Form.Checkbox
                                isInline
                                label="Mail ricezione notifiche"
                                name="emailNotification"
                                value="emailNotification"
                                onChange={handleChange}
                                checked={values.emailNotification}
                            />
                        </Form.Group>
                        <Form.Group label="Modifica documento inviato">
                            <Form.Checkbox
                                isInline
                                label="Allega pdf"
                                name="attachPdf"
                                value="attachPdf"
                                onChange={handleChange}
                                checked={values.attachPdf}
                            />
                        </Form.Group>
                        <Form.Footer>
                            <Button type='submit' color="primary" disabled={isSubmitting}>Salva</Button>
                        </Form.Footer>
                    </Form>
                )}
        />;
        return content;
    }
}

export default PreferenceSubject;