import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Formik } from "formik";
import {
    Alert,
    Grid,
    ForgotPasswordPage as TablerForgotPasswordPage } from "tabler-react";

import * as actions from '../store/actions/index';

var strings = {
    title: "Password dimenticata",
    buttonText: "Richiedi cambio password",
    emailLabel: "Indirizzo email",
    emailPlaceholder: "Inserisci email",
    instructions: "Inserisci la tua email e la tua password sarà resettata e inviata alla tua casella di posta elettronica."
};

class ForgotPasswordPage extends Component {
    render() {
        let content = (<Formik
            initialValues={{
                email: ""
            }}
            validate={values => {
                // same as above, but feel free to move this into a class method now.
                let errors = {};
                if (!values.email) {
                    errors.email = "Campo obbligatorio";
                } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
                ) {
                    errors.email = "Email non valida";
                }
                return errors;
            }}
            onSubmit={(
                values,
                { setSubmitting, setErrors /* setValues and other goodies */ }
            ) => {
                setSubmitting(true);
                this.props.onResetPassword(values.email);
                setSubmitting(false);
            }}

            render={({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
            }) => (
                <div>
                    <Grid.Row>
                        <Grid.Col offset={4} md={4} className="text-center">
                            <img src="/assets/images/fattura-fatta.png" alt="Fattura Fatta" style={styles.logo} />
                        </Grid.Col>
                    </Grid.Row>
                    <TablerForgotPasswordPage
                        onSubmit={handleSubmit}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        values={values}
                        errors={errors}
                        touched={touched}
                        strings={strings}
                    />
                </div>
            )}
        />);

        var alert = "";
        if (this.props.error) {
            alert = (
                <Grid.Row>
                    <Grid.Col offset={4} md={4}>
                        <Alert type="danger" isDismissible>
                            Impossibile resettare la password. Controllare i dati inseriti.
                        </Alert>
                    </Grid.Col>
                </Grid.Row>
            );
        }
        if (this.props.success) {
            alert = (
                <Grid.Row>
                    <Grid.Col offset={4} md={4}>
                        <Alert type="success" isDismissible>
                            Password resettata. Controlla la tua casella email.
                        </Alert>
                    </Grid.Col>
                </Grid.Row>
            );
        }

        return (<div>
                {content}
                {alert}
        </div>);
    }
}

const mapStateToProps = state => {
    return {
        loading: state.resetPassword.loading,
        error: state.resetPassword.error,
        success: state.resetPassword.success,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onResetPassword: (email) => dispatch(actions.resetPassword(email)),
    };
};

const styles = {
    logo: {
        paddingTop: '4em'
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage);