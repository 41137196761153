import * as actionTypes from './actionTypes';
import api from '../../api';

export const loadingStart = () => {
    return {
        type: actionTypes.LOADING_START_PREFERENCES
    };
};

export const setPreferences = (requests) => {
    return {
        type: actionTypes.SET_PREFERENCES,
        requests: requests
    };
};

export const fetchPreferencesFailed = () => {
    return {
        type: actionTypes.FETCH_PREFERENCES_FAILED
    };
};

export const setPreferenceEmpty = () => {
    return {
        type: actionTypes.FETCH_PREFERENCES_EMPTY
    };
};

export const tokenExpired = () => {
    return {
        type: actionTypes.TOKEN_EXPIRED
    }
}

export const loadPreferences = (type, uuid) => {
    return dispatch => {
        dispatch(loadingStart());
        api.get("preferences/" + type + "/" + uuid)
            .then(response => {
                switch (response.status) {
                    case 204:
                        dispatch(setPreferenceEmpty());
                        break;
                    default:
                        dispatch(setPreferences(response.data));
                        break;
                }
            })
            .catch(error => {
                if (process.env.NODE_ENV === 'development') {
                    console.log(error);
                }
                if (error.response) {
                    if (error.response.status === 401) {
                        if (error.response.data.code === "107") {
                            dispatch(tokenExpired());
                        }
                    }
                }
                dispatch(fetchPreferencesFailed());
            });
    };
};