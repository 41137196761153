import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { Formik } from "formik";
import {
    LoginPage as TablerLoginPage,
    Alert,
    Grid
} from "tabler-react";

import * as actions from '../store/actions/index';

var strings = {
    title: "Accedi al tuo account",
    buttonText: "Accedi",
    emailLabel: "Indirizzo email",
    emailPlaceholder: "Inserisci email",
    passwordLabel: "Password",
    passwordPlaceholder: "Password"
};
class LoginPage extends Component {
    render() {
        let content = (<Formik
            initialValues={{
                email: "",
                password: "",
            }}
            validate={values => {
                // same as above, but feel free to move this into a class method now.
                let errors = {};
                if (!values.email) {
                    errors.email = "Required";
                } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
                ) {
                    errors.email = "Invalid email address";
                }
                if (!values.password) {
                    errors.password = "Required";
                }
                return errors;
            }}
            onSubmit={(
                values,
                { setSubmitting, setErrors /* setValues and other goodies */ }
            ) => {
                setSubmitting(true);
                this.props.onAuth(values.email, values.password);
                setSubmitting(false);
            }}

            render={({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
            }) => (
                    <div>
                        <Grid.Row>
                            <Grid.Col className="text-center">
                                <img src="/assets/images/fattura-fatta.png" alt="Fattura Fatta" style={styles.logo}/>
                            </Grid.Col>
                        </Grid.Row>
                        <TablerLoginPage
                            onSubmit={handleSubmit}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            values={values}
                            errors={errors}
                            touched={touched}
                            strings={strings}
                        />
                        <Grid.Row>
                            <Grid.Col className="text-center">
                                <Link to="/accountant-registration">Registrazione commercialisti</Link><br />
                                <Link to="/password-dimenticata">Password dimenticata?</Link>
                            </Grid.Col>
                        </Grid.Row>
                    </div>
                )}
        />);

        var error = "";
        if (this.props.error) {
            error = (
                <Grid.Row>
                    <Grid.Col offset={4} md={4}>
                        <Alert type="danger" isDismissible>
                            {this.props.error}
                        </Alert>
                    </Grid.Col>
                </Grid.Row>
            );
        }

        return (
            <div>
                {content}
                {error}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        isAuthenticated: state.auth.token !== null,
        authRedirectPath: state.auth.authRedirectPath
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onAuth: (email, password) => dispatch(actions.auth(email, password)),
        onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath('/'))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);


const styles = {
    logo: {
        paddingTop: '4em'
    }
}