import * as actionTypes from './actionTypes';
import api from '../../api';
import { createQueryString } from '../../shared/utility';

export const loadingStart = () => {
    return {
        type: actionTypes.LOADING_START
    };
};

export const setAccountants = (accountants) => {
    return {
        type: actionTypes.SET_ACCOUNTANTS,
        accountants: accountants
    };
};

export const fetchAccountantsFailed = () => {
    return {
        type: actionTypes.FETCH_ACCOUNTANTS_FAILED
    };
};

export const setAccountantsEmpty = () => {
    return {
        type: actionTypes.FETCH_ACCOUNTANTS_EMPTY
    };
};

export const tokenExpired = () => {
    return {
        type: actionTypes.TOKEN_EXPIRED
    }
}

export const loadAccountants = (page, perPage, filters, include, order = {}) => {
    return dispatch => {
        dispatch(loadingStart());
        const queryString = createQueryString(page, perPage, filters, include, order);
        api.get('accountants' + queryString)
            .then(response => {
                switch (response.status) {
                    case 204:
                        dispatch(setAccountantsEmpty());
                        break;
                    default:
                        dispatch(setAccountants(response.data));
                        dispatch(setTableStatus(page, perPage, filters, include, order));
                        break;
                }
            })
            .catch(error => {
                if (process.env.NODE_ENV === 'development') {
                    console.log(error.response);
                }
                if (error.response) {
                    if (error.response.status === 401) {
                        if (error.response.data.code === "107") {
                            dispatch(tokenExpired());
                        }
                    }
                }
                dispatch(fetchAccountantsFailed());
            });
    };
};

const setTableStatus = (page, perPage, filters, include, order) => {
    return {
        type: actionTypes.SET_ACCOUNTANTS_TABLE_STATUS,
        page: page,
        perPage: perPage,
        filters: filters,
        include: include,
        order: order
    };
}