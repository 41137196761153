import React from 'react';


const meta = (props) => {
    return (
        <div style={style.container}>
            Da {props.from} a {props.to} di {props.total}
        </div>
    );
}

const style = {
    container: {
        display: "inline"
    }
}

export default meta;