const Ability = Object.freeze({
    ALL: "*",
    SEND_DOC: "send-doc",
    RECEIVE_DOC: "receive-doc",
    VIEW_SUBJECT: "view-subject",
    EDIT_SUBJECT: "edit-subject",
    CREATE_SUBJECT: "create-subject",
    DELETE_SUBJECT: "delete-subject",
    DISABLE_SUBJECT: "disable-subject",
    VIEW_USER: "view-user",
    EDIT_USER: "edit-user",
    DELETE_USER: "delete-user",
    CREATE_USER: "create-user",
    UPLOAD_SIGNED: "upload-signed",
    VIEW_ACCOUNTANT: "view-accountant",
    EDIT_ACCOUNTANT: "edit-accountant",
    DELETE_ACCOUNTANT: "delete-accountant",
    CREATE_ACCOUNTANT: "create-accountant",
    FOLLOWING_REQUEST: "follow-subject-request",
});

export default Ability;