import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Page,
    Table,
    Grid,
    Card,
    Button,
    Dimmer,
    Icon,
    Tag
} from 'tabler-react';
import { Link } from 'react-router-dom';

import SiteWrapper from "../hoc/SiteWrapper/SiteWrapper";
import * as actions from '../store/actions/index';
import DocumentStatus from '../components/UI/DocumentStatus';
import Pagination from '../components/UI/Table/Pagination';
import TableMeta from '../components/UI/Table/TableMeta';
import PageLength from '../components/UI/Table/PageLength';
import ColHeader from '../components/UI/Table/ColHeader';
import Filter from '../components/Filter/PassiveDocument';
import FilterUser from '../components/Filter/PassiveDocumentUser';
import { createOrderObject } from '../shared/utility';
import Role from "../shared/enums/Role";
import Type from "../shared/enums/DocumentType";

class PassiveDocumentsPage extends Component {
    state = {
        perPage: 15,
        page: 1,
        filters: {},
        include: ["subject"],
        order: {
            by: 'created_at',
            direction: 'DESC',
        }
    }

    componentDidMount() {
        if (this.props.page !== null) {
            this.props.onLoadDocuments(this.props.page, this.props.perPage, this.props.filters, this.props.include, this.props.order);
        } else {
            this.props.onLoadDocuments(this.state.page, this.state.perPage, this.state.filters, this.state.include, this.state.order);
        }
        this.props.setDocumentType('passive');
    }

    handlePageChange(page) {
        this.setState({
            ...this.state,
            page: page
        })
        this.props.onLoadDocuments(page, this.state.perPage, this.state.filters, this.state.include, this.state.order);
    }

    handlePerPageChange(perPage) {
        this.setState({
            ...this.state,
            perPage: perPage
        })
        this.props.onLoadDocuments(this.state.page, perPage, this.state.filters, this.state.include, this.state.order);
    }

    handleFilterSubmit(values) {
        this.setState({
            ...this.state,
            filters: values
        })
        this.props.onLoadDocuments(1, this.state.perPage, values, this.state.include, this.state.order);
    }

    handleOrder(column) {
        const order = createOrderObject(this.state.order, column);
        this.setState({
            ...this.state,
            order: order
        });
        this.props.onLoadDocuments(this.state.page, this.state.perPage, this.state.filters, this.state.include, order);
    }

    render() {
        let footer = null;
        let tableHeader = null;
        let rows = (
            <Table.Row>
                <Table.Col colSpan={10}>Nessun dato presente</Table.Col>
            </Table.Row>
        );
        if (this.props.loading === false && this.props.error === false && this.props.empty === false) {
            rows = this.props.documents.map(document => {
                let firstCol = (<Table.Col>{document.relationships.subject.data.attributes.business_name}</Table.Col>);
                let secondCol = (<Table.Col>
                    <DocumentStatus description={this.props.status[document.attributes.status] ? this.props.status[document.attributes.status].short_description : ""}>
                        {document.attributes.status}
                    </DocumentStatus>
                </Table.Col>);
                let thirdCol = (<Table.Col>{document.attributes.sender}</Table.Col>);
                let lastEditCol = (<Table.Col>{document.attributes.updated_at}</Table.Col>);
                let downloadedCol = (<Table.Col></Table.Col>);
                if (document.attributes.downloaded) {
                    downloadedCol = (<Table.Col><Tag color="lime"><Icon prefix="fa" name="check" /></Tag></Table.Col>);
                }
                if (this.props.me.roles[0] === Role.USER) {
                    firstCol = (<Table.Col>{document.attributes.sender}</Table.Col>);
                    secondCol = (<Table.Col>
                        <DocumentStatus description={this.props.status[document.attributes.status] ? this.props.status[document.attributes.status].short_description : ""}>
                            {document.attributes.status}
                        </DocumentStatus>
                    </Table.Col>);
                    thirdCol = null;
                    lastEditCol = null;
                    downloadedCol = null;
                }
                return <Table.Row key={document.id}>
                    {firstCol}
                    {secondCol}
                    {thirdCol}
                    <Table.Col>{document.attributes.file_name}</Table.Col>
                    <Table.Col>{document.attributes.number}</Table.Col>
                    <Table.Col>{document.attributes.date}</Table.Col>
                    <Table.Col>{Type[document.attributes.type]}</Table.Col>
                    <Table.Col>€ {document.attributes.amount_with_tax.toFixed(2)}</Table.Col>
                    <Table.Col>{document.attributes.created_at}</Table.Col>
                    {lastEditCol}
                    {downloadedCol}
                    <Table.Col>
                        <Button.List>
                            <Button color="primary" icon="eye" outline onClick={() => this.props.onViewClick(document.id, document.attributes.file_name)} />&nbsp;
                            <Link to={"/documents/passive/" + document.id}>
                                <Button color="primary" icon="info" outline />
                            </Link>&nbsp;
                            <Button color="primary" icon="download" outline onClick={() => this.props.onDownloadClick(document.id, document.attributes.file_name)} />
                        </Button.List>
                    </Table.Col>
                </Table.Row>;
            });
            footer = (
                <div>
                    <TableMeta
                        from={this.props.meta.from}
                        to={this.props.meta.to}
                        total={this.props.meta.total}
                    />
                    <Pagination links={this.props.links} onPageChange={this.handlePageChange.bind(this)} />
                </div>);
        }
        let filters = <Filter onSubmit={this.handleFilterSubmit.bind(this)} role={this.props.me.roles[0]} userableId={this.props.userableId} current={this.props.filters}/>;
        if (this.props.me.roles[0] === 'user') {
            filters = <FilterUser onSubmit={this.handleFilterSubmit.bind(this)} current={this.props.filters}/>;
        }
        let firstColHeaderHeader = (<Table.ColHeader>Soggetto</Table.ColHeader>);
        let secondColHeaderHeader = (<Table.ColHeader>Stato</Table.ColHeader>);
        let thirdColHeaderHeader = (<Table.ColHeader>Mittente</Table.ColHeader>);
        let createColHeader = (<Table.ColHeader>Data creazione</Table.ColHeader>);
        let lastEditColHeader = (<Table.ColHeader>Data modifica</Table.ColHeader>);
        let downloadedColHeader = (<Table.ColHeader>Scaricato</Table.ColHeader>);
        if (this.props.me.roles[0] === Role.USER) {
            firstColHeaderHeader = (<Table.ColHeader>Mittente</Table.ColHeader>);
            secondColHeaderHeader = (<Table.ColHeader>Stato</Table.ColHeader>);
            thirdColHeaderHeader = null;
            lastEditColHeader = null;
            downloadedColHeader = null;
            createColHeader = (<Table.ColHeader>Data ricezione</Table.ColHeader>);
        }
        tableHeader = (
            <Table.Row>
                {firstColHeaderHeader}
                {secondColHeaderHeader}
                {thirdColHeaderHeader}
                <Table.ColHeader>Nome file</Table.ColHeader>
                <Table.ColHeader>Numero</Table.ColHeader>
                <Table.ColHeader>Data</Table.ColHeader>
                <Table.ColHeader>Tipo</Table.ColHeader>
                <Table.ColHeader>Importo</Table.ColHeader>
                {downloadedColHeader}
                {createColHeader}
                {lastEditColHeader}
                <Table.ColHeader>Azioni</Table.ColHeader>
            </Table.Row>
        );
        if (this.props.order) {
            firstColHeaderHeader = (<ColHeader name="subjects.business_name" onOrder={this.handleOrder.bind(this)} current={this.props.order}>Soggetto</ColHeader>);
            secondColHeaderHeader = (<ColHeader name="status" onOrder={this.handleOrder.bind(this)} current={this.props.order}>Stato</ColHeader>);
            thirdColHeaderHeader = (<ColHeader name="sender" onOrder={this.handleOrder.bind(this)} current={this.props.order}>Mittente</ColHeader>);
            lastEditColHeader = (<ColHeader name="updated_at" onOrder={this.handleOrder.bind(this)} current={this.props.order}>Data modifica</ColHeader>);
            downloadedColHeader = (<ColHeader name="downloaded" onOrder={this.handleOrder.bind(this)} current={this.props.order}>Scaricato</ColHeader>);
            createColHeader = (<ColHeader name="created_at" onOrder={this.handleOrder.bind(this)} current={this.props.order}>Data creazione</ColHeader>);
            if (this.props.me.roles[0] === Role.USER) {
                firstColHeaderHeader = (<ColHeader name="sender" onOrder={this.handleOrder.bind(this)} current={this.props.order}>Mittente</ColHeader>);
                secondColHeaderHeader = (<ColHeader name="status" onOrder={this.handleOrder.bind(this)} current={this.props.order}>Stato</ColHeader>);
                thirdColHeaderHeader = null;
                downloadedColHeader = null;
                lastEditColHeader = null;
                createColHeader = (<ColHeader name="created_at" onOrder={this.handleOrder.bind(this)} current={this.props.order}>Data ricezione</ColHeader>);
            }
            tableHeader = (
                <Table.Row>
                    {firstColHeaderHeader}
                    {secondColHeaderHeader}
                    {thirdColHeaderHeader}
                    <ColHeader name="file_name" onOrder={this.handleOrder.bind(this)} current={this.props.order}>Nome file</ColHeader>
                    <ColHeader name="number" onOrder={this.handleOrder.bind(this)} current={this.props.order}>Numero</ColHeader>
                    <ColHeader name="date" onOrder={this.handleOrder.bind(this)} current={this.props.order}>Data</ColHeader>
                    <ColHeader name="type" onOrder={this.handleOrder.bind(this)} current={this.props.order}>Tipo</ColHeader>
                    <ColHeader name="amount_with_tax" onOrder={this.handleOrder.bind(this)} current={this.props.order}>Importo</ColHeader>
                    {downloadedColHeader}
                    {createColHeader}
                    {lastEditColHeader}
                    <Table.ColHeader>Azioni</Table.ColHeader>
                </Table.Row>
            );
        }
        return (
            <SiteWrapper me={this.props.me} business={this.props.business}>
                <Page.Content title="Document ricevuti">
                    <Grid.Row>
                        <Grid.Col md={12} xl={12}>
                            {filters}
                            <Card>
                                <Card.Header>
                                    <PageLength onChange={this.handlePerPageChange.bind(this)} current={this.props.perPage}/>
                                </Card.Header>
                                <Card.Body>
                                    <Dimmer active={this.props.loading ? true : false} loader>
                                        <Table>
                                            <Table.Header>
                                                {tableHeader}
                                            </Table.Header>
                                            <Table.Body>
                                                {rows}
                                            </Table.Body>
                                        </Table>
                                    </Dimmer>
                                </Card.Body>
                                <Card.Footer>
                                    {footer}
                                </Card.Footer>
                            </Card>
                        </Grid.Col>
                    </Grid.Row>
                </Page.Content>
            </SiteWrapper>
        );
    };
}

const mapStateToProps = state => {
    return {
        documents: state.passiveDocuments.documents,
        meta: state.passiveDocuments.meta,
        links: state.passiveDocuments.links,
        error: state.passiveDocuments.error,
        loading: state.passiveDocuments.loading,
        empty: state.passiveDocuments.empty,
        me: state.auth.me,
        business: state.auth.business,
        status: state.documentStatus.status,
        userableId: state.auth.userableId,
        page: state.passiveDocuments.page,
        perPage: state.passiveDocuments.perPage,
        filters: state.passiveDocuments.filters,
        include: state.passiveDocuments.include,
        order: state.passiveDocuments.order,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onLoadDocuments: (page, perPage, filters, include, order) => dispatch(actions.loadPassiveDocuments(page, perPage, filters, include, order)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PassiveDocumentsPage);