import React from 'react';
import { toast } from 'react-toastify';

import api from "../../api";

function documentComponent(WrappedComponent) {
    class DocumentComponent extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                type: null
            };
            this.onViewClick = this.onViewClick.bind(this);
            this.onDownloadClick = this.onDownloadClick.bind(this);
            this.setDocumentType = this.setDocumentType.bind(this);
        }

        setDocumentType(type) {
            this.setState({
                ...this.state,
                type: type
            });
        }

        onViewClick(id, filename) {
            let toastId = toast.info("Download in corso");
            api.get('documents/' + this.state.type + '/' + id + '/download', {
                responseType: 'blob',
                headers: {
                    'Accept': process.env.REACT_APP_API_ACCEPT + '+pdf'
                }
            }).then(response => {
                //Create a Blob from the PDF Stream
                const file = new Blob(
                    [response.data],
                    { type: 'application/pdf' });
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    const fileName = filename + ".pdf";
                    window.navigator.msSaveOrOpenBlob(file, fileName);
                }
                else {
                    //Build a URL from the file
                    const fileURL = URL.createObjectURL(file);
                    //Open the URL on new Window
                    window.open(fileURL);
                }
                toast.dismiss(toastId);
            }).catch(error => {
                toast.error("Errore nello scaricamento del file");
                toast.dismiss(toastId);
            });
        }

        onDownloadClick(id, fileName) {
            let toastId = toast.info("Download in corso");
            let timestamp = new Date().getTime();
            api.get('documents/' + this.state.type + '/' + id + '/download?t=' + timestamp, {
                responseType: 'blob',
                headers: {
                    'Accept': process.env.REACT_APP_API_ACCEPT + '+xml'
                }
            }).then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                toast.dismiss(toastId);
            }).catch(error => {
                toast.error("Errore nello scaricamento del file");
                toast.dismiss(toastId);
            });
        }

        render() {
            const { onViewClick, onDownloadClick, setDocumentType, ...otherProps } = this.props;
            return (<WrappedComponent
                onViewClick={this.onViewClick}
                onDownloadClick={this.onDownloadClick}
                setDocumentType={this.setDocumentType}
                {...otherProps}
            />);
        }
    }
    return DocumentComponent;
}

export default documentComponent;