import React from 'react';
import { NavLink } from 'react-router-dom';
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';

// define some custom breadcrumbs for certain routes (optional)
const routes = [
    { path: '/', breadcrumb: 'Home' },
    { path: '/documents', breadcrumb: null },
    { path: '/documents/active', breadcrumb: "Ciclo Attivo" },
    { path: '/documents/passive', breadcrumb: "Ciclo Passivo" },
    { path: '/subjects', breadcrumb: "Soggetti" },
    { path: '/subjects/create', breadcrumb: "Nuovo" },
    { path: '/subjects/:id/edit', breadcrumb: "Modifica" },
    { path: '/subjects/following-request', breadcrumb: "Richiesta di accesso" },
    { path: '/accountants', breadcrumb: "Commercialisti" },
    { path: '/accountants/create', breadcrumb: "Nuovo" },
    { path: '/accountants/:id/edit', breadcrumb: "Modifica" },
    { path: '/users', breadcrumb: "Utenti" },
    { path: '/users/create', breadcrumb: "Nuovo" },
    { path: '/users/:id/edit', breadcrumb: "Modifica" },
    { path: '/settings', breadcrumb: "Preferenze" },
    { path: '/profile', breadcrumb: "Profilo" },
    { path: '/change-password', breadcrumb: "Cambio password" },
    { path: '/help', breadcrumb: "Aiuto" },
];

// map & render your breadcrumb components however you want.
// each `breadcrumb` has the props `key`, `location`, and `match` included!
const Breadcrumbs = ({ breadcrumbs }) => (
  <div>
    {breadcrumbs.map((breadcrumb, index) => (
      <span key={breadcrumb.key}>
        <NavLink to={breadcrumb.props.match.url}>
          {breadcrumb}
        </NavLink>
        {(index < breadcrumbs.length - 1) && <i> / </i>}
      </span>
    ))}
  </div>
);

export default withBreadcrumbs(routes)(Breadcrumbs)